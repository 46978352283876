//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

import {
    SELECT_Single,
    ORBIT_Pan,
    SETTING_Default,
    ALIGN_Light,
    EDIT_Scene,
    EDIT_Geometry,
    IsElectronContext,
    SELECT_Group_Outside, 
    TOOL_MODE,
    TRANSFORM_MODE,
    TOOL_MODE_INSERT,
    IsDarkTheme,
    RESOURCE_TYPE,
    SELECTION_MODE,
    ForceOneWindowRouter,
    AppDisplaymodeToString,
    AppLanguageToString,
    AppThemeToString,
    radToDeg} from './defines';
import { addNotification } from '../component/NotificationDisplay/NotificationDisplay';
import { FetchGlobalSettings } from '../redux/actions/fetch_actions';
import LocalizedStringsNotification from "../localization/Notification";
import EventEmitter3 from "eventemitter3"
import { globalCallbackInterface, CoreCallbackEventType, uuidStr } from './callbackTypes';
declare const window: any;

let ipcRenderer;
// Check if runs in browser or electron
if(IsElectronContext() || window.ElectronWebsocketMode) 
{
    ipcRenderer = window.ipcRenderer;
}
else if (window.IsIOS || window.Android)
{
    ipcRenderer = require("../iosApp/ipcRenderer").ipcRenderer
}
else if(window.IsVectorworksContext)
{
    ipcRenderer = require('../vectorworksApp/ipcRenderer').ipcRenderer;
}
else
{
    ipcRenderer = require('../webApp/ipcRenderer').ipcRenderer;
}

export let globalCallbacks: globalCallbackInterface = {
    registerdCallbacks: new EventEmitter3(),

    addEventListener: (changeCallback, callbackFn) => {
        globalCallbacks.registerdCallbacks.on(changeCallback, callbackFn)
    },

    addMultipleEventListeners: (changeCallbacks, callbackFn)=>{
        for(let i of changeCallbacks){
            globalCallbacks.registerdCallbacks.on(i, callbackFn)
        }
    },

    runCallbacks: (changeCallback, args) => {
        globalCallbacks.registerdCallbacks.emit(changeCallback, args)
    },

    setSelected: (firstUUID: uuidStr, selected: boolean, selectedList: uuidStr[], shift = false, meta = false, alt = false, useDrawingOrder = false) => {
        
        if(selectedList === undefined)
        {
            selectedList = [firstUUID];
        }
        let request = 
        {
            Selected      : selected,
            SelectionMode : globalCallbacks.selectionMode,
            SelectionGroupMode : globalCallbacks.selectionGroupMode,
            PropertyName  : globalCallbacks.CurrentProperty,
            ArrayName     : globalCallbacks.CurrentArrayName,
            ShiftKey      : shift,
            MetaKey       : meta,
            AltKey        : alt,
            UseDrawingOrder: useDrawingOrder,
            SelectedList: selectedList
        }
        window.LR_Select(request);
    },

    toolMode            : TOOL_MODE.Select,
    ResourceType        : RESOURCE_TYPE.AssemblyGroup,
    selectionMode       : SELECTION_MODE.Single,
    selectionGroupMode  : SELECT_Group_Outside,
    orbitMode           : ORBIT_Pan,
    alignMode           : ALIGN_Light,
    transformMode       : TRANSFORM_MODE.Global,
    propertySettingMode : SETTING_Default,
    editMode            : EDIT_Scene,
    InsertToolInsertionMode: TOOL_MODE_INSERT.Single
};


export function OnLightRightCoreCallback(object : CoreCallbackEventType) {
    if (globalCallbacks.onBackgroundWorkerStatus) {
        globalCallbacks.onBackgroundWorkerStatus(object.Command, object.Argument.Name);
    }
    if(object.Command === "bws" && globalCallbacks.waitForPresetChange){
        globalCallbacks.waitForPresetChange([]);
    }

    if(object.Command === "set-shortcut-enabled" && globalCallbacks.globalShortcutsEnabled){
        globalCallbacks.globalShortcutsEnabled(object.Argument)
    }
    if (object.Command === "changed-object-complete") 
    {
        if(Object.keys(object.Argument.Properties).length === 1 && object.Argument.Properties.VisibleInSceneTree){
            // In SceneTreeHierarchyComponent.js
            if (globalCallbacks.refreshSceneTree) {
                globalCallbacks.refreshSceneTree(object.Argument.Objects, object.Argument.Properties);
            }
        }else{
            // In Renderer.js
            if(globalCallbacks.waitForPresetChange){
                globalCallbacks.waitForPresetChange(object.Argument.Objects, object.Argument.Properties);
            }else if (globalCallbacks.setSelectedProperties) {
                globalCallbacks.setSelectedProperties(object.Argument.Objects, object.Argument.Properties);
            }


            // In ObjectPropertiesComponent.js
            if (globalCallbacks.updateElecticalItem) {
                globalCallbacks.updateElecticalItem(object.Argument.Objects, object.Argument.Properties);
            }

            // In ObjectPropertiesComponent.js
            if (globalCallbacks.getSelectedProperties) {
                globalCallbacks.getSelectedProperties();
            }

            // In SceneTreeHierarchyComponent.js
            if (globalCallbacks.refreshSceneTree) {
                globalCallbacks.refreshSceneTree(object.Argument.Objects, object.Argument.Properties);
            }

            // In TableViewComponent.js
            if (globalCallbacks.updateProperties) {
                globalCallbacks.updateProperties(object.Argument.Objects, object.Argument.Properties);
            }

            // In SummeryList.js
            if (globalCallbacks.refreshSummeryList) { globalCallbacks.refreshSummeryList() }

            // In GraphList.js
            if (globalCallbacks.updateGraphList) { globalCallbacks.updateGraphList() }

            //Need to update worksheets, because it does not recognize the change of "users" or "Time Offset"
            if(globalCallbacks.getWorksheets) {
                globalCallbacks.getWorksheets();
            }

            //Int worksheet timeline
            if (globalCallbacks.updateWorksheets) {
                globalCallbacks.updateWorksheets();
            }

            // In TruckList.js
            if (globalCallbacks.updateInventoryContainerList) {
                globalCallbacks.updateInventoryContainerList();
            }

            // In CaseList.js
            if (globalCallbacks.updateCaseList) {
                globalCallbacks.updateCaseList();
            }

            //In ResourceManager
            if (globalCallbacks.getResourceManagerSymbolDefs) {
                globalCallbacks.getResourceManagerSymbolDefs();
            }
            if (globalCallbacks.getResourceManagerFixtureTypes) {
                globalCallbacks.getResourceManagerFixtureTypes();
            }

            // ToolSelect
            if(globalCallbacks.refetchSymbols_TOOL)    { globalCallbacks.refetchSymbols_TOOL(); }

            //In TimeLine
            if (globalCallbacks.updateTimeline) {
                globalCallbacks.updateTimeline();
            }

            // In TrackingList.js
            if (globalCallbacks.getAllActiveFixtures) {
                globalCallbacks.getAllActiveFixtures();
            }

            // In PresetList.js
            if (globalCallbacks.getAllPresetsContent) {
                globalCallbacks.getAllPresetsContent();
            }

            // In FocusList.js
            if (globalCallbacks.getFixturesByAlignment) {
                globalCallbacks.getFixturesByAlignment();
            }

            // In RackList.js
            if (globalCallbacks.updateRackList) {
                globalCallbacks.updateRackList();
            }

            // In ElectricalList.js
            if (globalCallbacks.UpdateGenerators) {
                globalCallbacks.UpdateGenerators()
            }

            // In SupportView.js
            if (globalCallbacks.updateSupportList) {
                globalCallbacks.updateSupportList()
            }

            // In GelList.js
            if (globalCallbacks.updateGelList) {
                globalCallbacks.updateGelList()
            }

            if (globalCallbacks.updateMacTouchbar)
            {
                globalCallbacks.updateMacTouchbar()
            }

            if (globalCallbacks.getObjectPropertiesGetOrigins) 
        {
            globalCallbacks.getObjectPropertiesGetOrigins();
        }
        }
    }

    if (object.Command === "changed-highlighted-fixture") {

        // In Renderer.js
        if (globalCallbacks.highlightFixture) {
            globalCallbacks.highlightFixture(object.Argument);
        }
    }

    if (object.Command === "update-result-display") {

        if (globalCallbacks.refreshStructuralCalculationList) {
            globalCallbacks.refreshStructuralCalculationList();
        }
        if (globalCallbacks.refreshStructuralCalculationListNavigation) {
            globalCallbacks.refreshStructuralCalculationListNavigation();
        }
    }

    if (object.Command === "auto-save-timer") {

        if (globalCallbacks.ShowAutoSaveDialog) {
            globalCallbacks.ShowAutoSaveDialog();
        }
    }

    if (object.Command === "reload-online-resources") 
    {
        if (globalCallbacks.getObjectPropertiesTrussCrossSection)  globalCallbacks.getObjectPropertiesTrussCrossSection()
        if (globalCallbacks.updateActiveProject)  globalCallbacks.updateActiveProject()
        if(globalCallbacks.updateActiveProjectIPhone)       { globalCallbacks.updateActiveProjectIPhone(); }
    }
    if (object.Command === "add-new-wind-load") 
    {
        if (globalCallbacks.ShowAddWindLoadDialog)  globalCallbacks.ShowAddWindLoadDialog()
    }
    if (object.Command === "add-new-line-load") 
    {
        if (globalCallbacks.ShowAddLineLoadDialog)  globalCallbacks.ShowAddLineLoadDialog()
    }
    if (object.Command === "add-new-point-load") 
    {
        if (globalCallbacks.ShowAddPointLoadDialog)  globalCallbacks.ShowAddPointLoadDialog()
    }

    

    // /!\/!\/!\ No 'Handle' attribute for this Command
    if (object.Command === "changed-visibility-complete" || object.Command === "changed-preset") {
        
        // In Renderer.js
        if(object.Command === "changed-preset"){
            if (globalCallbacks.onChangedPreset) {
                globalCallbacks.onChangedPreset(object.Argument);
            }
        }else{
            if(globalCallbacks.waitForPresetChange){
                globalCallbacks.waitForPresetChange([])
            } else if (globalCallbacks.refreshObjectPosition) {
                globalCallbacks.refreshObjectPosition();
            }
        }

        // In ObjectPropertiesComponent.js
        if (globalCallbacks.getSelectedProperties) {
            globalCallbacks.getSelectedProperties();
        }

        // In PresetContainers.js
        if (globalCallbacks.refreshPresets) {
            globalCallbacks.refreshPresets();
        }

        // In TableViewComponent.js
        if (globalCallbacks.getAllProperties) {
            globalCallbacks.getAllProperties();
        }

        // In SceneTimeLineComponent.js
        if (globalCallbacks.getTimePhases) {
            globalCallbacks.getTimePhases();
        }

        if (globalCallbacks.getTimePhaseChanges)  {
            globalCallbacks.getTimePhaseChanges();
        }

        if (globalCallbacks.getTimeLines) {
            globalCallbacks.getTimeLines();
        }

        if (globalCallbacks.getPresetOptions) {
            globalCallbacks.getPresetOptions();
        }

        // In CueList.js
        if (globalCallbacks.getTimePhasesCueList) {
            globalCallbacks.getTimePhasesCueList();
        }

        // In TrackingList.js
        if (globalCallbacks.getAllActiveFixtures) {
            globalCallbacks.getAllActiveFixtures();
        }

        // In PresetList.js
        if (globalCallbacks.getAllPresetsContent) {
            globalCallbacks.getAllPresetsContent();
        }

        

        // In CreateWorksheetModal.js
        if(globalCallbacks.getTimelinePhasesToLink){
            globalCallbacks.getTimelinePhasesToLink();
        }
    }
    if (object.Command === "show-app-feedback") 
    {
        if(globalCallbacks.ShowInAppFeedback)
        {
            globalCallbacks.ShowInAppFeedback(object.Argument)
        }
    }
    if (object.Command === "show-custom-url-dialog") 
    {
        if(globalCallbacks.ShowCustomURLDialog)
        {
            globalCallbacks.ShowCustomURLDialog(object.Argument)
        }
    }
    if (object.Command === "new-object-added") 
    {
        // In SceneTreeHierarchyComponent.js
        if (globalCallbacks.getSceneTree) {
            globalCallbacks.getSceneTree();
        }

        // In TableViewComponent.js
        if (globalCallbacks.getAllProperties) {
            globalCallbacks.getAllProperties();
        }

        // In Renderer.js
        // In Renderer.js
        if (globalCallbacks.addNewObject) 
        {
            globalCallbacks.addNewObject(object.Argument);
        }

        if (globalCallbacks.getObjectPropertiesGetOrigins) 
        {
            globalCallbacks.getObjectPropertiesGetOrigins();
        }
    }

    if (object.Command === "new-geometry-added") 
    {
        // In SceneTreeHierarchyComponent.js
        if (globalCallbacks.getSceneTree) {
            globalCallbacks.getSceneTree();
        }

        // In TableViewComponent.js
        if (globalCallbacks.getAllProperties) {
            globalCallbacks.getAllProperties();
        }

        // In Renderer.js
        if (globalCallbacks.addNewGeometry) {

            if(object.Argument.Resolved && object.Argument.Resolved.UUID)
            {
                globalCallbacks.addNewGeometry(object.Argument.Resolved);
            }
            else
            {
                console.error("new-geometry-added with empty object",object )
            }

           
        }
    }

    if (object.Command === "changed-hierarchy-complete") {
        // In SceneTreeHierarchyComponent.js
        if (globalCallbacks.getSceneTree) {
            globalCallbacks.getSceneTree();
        }

        // In TableViewComponent.js
        if (globalCallbacks.getAllProperties) {
            globalCallbacks.getAllProperties();
        }

        // In ObjectPropertiesComponent.js
        if (globalCallbacks.getSelectedProperties) {
            globalCallbacks.getSelectedProperties();
        }

        // In Renderer.js
        if (globalCallbacks.updateObjectHierarchy) { globalCallbacks.updateObjectHierarchy(object.Argument) }
    }

    if(object.Command === "changed-worksheet")
    {
        // In TableViewComponent.js
        if(globalCallbacks.updateWorksheetSelector)     { globalCallbacks.updateWorksheetSelector(); }
        if(globalCallbacks.getWorksheets)               { globalCallbacks.getWorksheets(); }
        if(globalCallbacks.updateTimeline)              { globalCallbacks.updateTimeline(); }
        if(globalCallbacks.getWorksheetsForNavigation)  { globalCallbacks.getWorksheetsForNavigation()}
        if(globalCallbacks.getWorksheetForResourceSchedule){globalCallbacks.getWorksheetForResourceSchedule()}
    }

    if(object.Command === "changed-layer")
    {
        // In LayerTable.js
        if(globalCallbacks.getNavigationLayers) { globalCallbacks.getNavigationLayers(); }

        //In ObjectPropertiesComponent.js
        if(globalCallbacks.getObjectPropertiesLayers) { globalCallbacks.getObjectPropertiesLayers(); }

        // In Renderer.js
        if(object.Argument !== undefined && globalCallbacks.applyLayerVisibility){ globalCallbacks.applyLayerVisibility(object.Argument); }

        // Table View
        if (globalCallbacks.getAllProperties) { globalCallbacks.getAllProperties(); }
    }

    if(object.Command === "NewLayerAdded")
    {
        if(globalCallbacks.getNavigationLayers) { globalCallbacks.getNavigationLayers(); }
        if(globalCallbacks.getObjectPropertiesLayers) { globalCallbacks.getObjectPropertiesLayers(); }

    }

    if(object.Command === "DeleteLayer")
    {
        if(globalCallbacks.getNavigationLayers) { globalCallbacks.getNavigationLayers(); }
        if(globalCallbacks.getObjectPropertiesLayers) { globalCallbacks.getObjectPropertiesLayers(); }

    }

    if(object.Command === "run-async-in-frontend")
    {
        if(typeof(globalCallbacks[object.Argument.CallbackName]) === "function"){
            // function could be a promise, so try to await it using Promise.resolve
            Promise.resolve(globalCallbacks[object.Argument.CallbackName](object.Argument["Payload"])).then(val => {
                window.LR_ReturnAwaitValueFromFrontend({
                    "AwaitID": object.Argument.AwaitID,
                    "Payload": val
                })
            })
        }else{
            console.error(`tried to call frontend function "${object.Argument.CallbackName}", but this does not exist. Returning {} to core`)
        }
    }

    

    if(object.Command === "project-changed")
    {
        let title = 'Project ' + object.Argument.Project + ' changed'
        let content = 'Project ' + object.Argument.Project + ' was changed on the server by ' + object.Argument.By
        return addNotification(title, content)
    }

    if(object.Command === "Show-Message-Overlay")
    {
        return addNotification(object.Argument.Header, object.Argument.Message, object.Argument.Positive, object.Argument.Error, object.Argument.Warning, object.Argument.OnClickLink)
    }

    if(object.Command === "changed-class")
    {
        if(globalCallbacks.getNavigationClasses)        {globalCallbacks.getNavigationClasses();}
        if(globalCallbacks.getObjectPropertiesClasses)  { globalCallbacks.getObjectPropertiesClasses(); }
        if(object.Argument !== undefined && globalCallbacks.applyClassVisibility) { globalCallbacks.applyClassVisibility(object.Argument); }
        if(globalCallbacks.getAllProperties)           { globalCallbacks.getAllProperties(); }
    }

    if(object.Command === "NewClassAdded")
    {
        if(globalCallbacks.getNavigationClasses)        {globalCallbacks.getNavigationClasses();}
        if(globalCallbacks.getObjectPropertiesClasses)  { globalCallbacks.getObjectPropertiesClasses(); }
    }

    if(object.Command === "DeleteClass")
    {
        if(globalCallbacks.getNavigationClasses)        {globalCallbacks.getNavigationClasses();}
        if(globalCallbacks.getObjectPropertiesClasses)  { globalCallbacks.getObjectPropertiesClasses(); }
    }
    if(object.Command === "changed-time-phase-complete")
    {
        // In SceneTimeLineComponent.js
        if(globalCallbacks.getTimePhases)
        {
            globalCallbacks.getTimePhases();
        }

        if (globalCallbacks.getTimePhaseChanges) 
        {
            globalCallbacks.getTimePhaseChanges();
        }
        
        if (globalCallbacks.getTimeLines) {
            globalCallbacks.getTimeLines();
        }
        if (globalCallbacks.getWorksheets) {
            globalCallbacks.getWorksheets();
        }
        if (globalCallbacks.updateWorksheetSelector) {
            globalCallbacks.updateWorksheetSelector();
        }
        if (globalCallbacks.updateTimeline) {
            globalCallbacks.updateTimeline();
        }

        // In CueList.js
        if (globalCallbacks.getTimePhasesCueList) {
            globalCallbacks.getTimePhasesCueList();
        }

        // In TrackingList.js
        if (globalCallbacks.getAllActiveFixtures) {
            globalCallbacks.getAllActiveFixtures();
        }

        // In FocusList.js
        if (globalCallbacks.getFixturesByAlignment) {
            globalCallbacks.getFixturesByAlignment();
        }

        // In CreateWorksheetModal.js
        if(globalCallbacks.getTimelinePhasesToLink)
        {
            globalCallbacks.getTimelinePhasesToLink();
        }
    }

    if(object.Command === "ShowAlignValueDialog")
    {
        // In SetProperty.js
        if (globalCallbacks.showModalDialog) {
            globalCallbacks.showModalDialog(object.Argument);
        }
    }

    if(object.Command === "show-insert-drop")
    {
        // In SetProperty.js
        if (globalCallbacks.showInsertDropModal) {
            globalCallbacks.showInsertDropModal();
        }
    }

    if(object.Command === "imported-new-meshes")
    {
        if(globalCallbacks.getResourceManagerMeshes)        { globalCallbacks.getResourceManagerMeshes(); }
        if(globalCallbacks.loadNewMesh)                     { globalCallbacks.loadNewMesh(object.Argument); }
    }

    if(object.Command === "ShowCalculationSettings")
    {
        if(globalCallbacks.ShowCalculationSettings)             { globalCallbacks.ShowCalculationSettings(object.Argument); }
    }

    if(object.Command === "ShowProjectLink")
    {
        if(globalCallbacks.LinkWithHostDialog)             { globalCallbacks.LinkWithHostDialog(object.Argument); }
    }

    if(object.Command === "imported-new-symbol-def")
    {
        if(globalCallbacks.loadNewMesh)                     { globalCallbacks.LoadNewSymbolDef(object.Argument); }
    }

    if(object.Command === "imported-new-fixture-type")
    {
        if(globalCallbacks.loadNewMesh)                     { globalCallbacks.LoadNewFixtureType(object.Argument); }
    }

    if(object.Command === "imported-new-textures")
    {
        // In ResourceManager.js
        if(globalCallbacks.getRessourceManagerTextures)     { globalCallbacks.getRessourceManagerTextures(); }

        // In ObjectPropertiesComponent.js
        if (globalCallbacks.getMeshesAndTextures) { globalCallbacks.getMeshesAndTextures(); }
    }

    if (object.Command === "read-file") {
        // In SceneTreeHierarchyComponent.js
        if(globalCallbacks.getSceneTree)                    { globalCallbacks.getSceneTree(); }
        if(globalCallbacks.displayDrawingMenu)              { globalCallbacks.displayDrawingMenu(); }
        if(globalCallbacks.ShowProveTable)                  { globalCallbacks.ShowProveTable(); }

        // In Renderer.js
        if(globalCallbacks.loadObjects)                     { globalCallbacks.loadObjects(true); }
        if(globalCallbacks.updateLoadCombination)                     { globalCallbacks.updateLoadCombination(); }

        // In PresetContainers.js
        if(globalCallbacks.refreshPresets)                  { globalCallbacks.refreshPresets(); }

        // In TableViewComponent.js
        if(globalCallbacks.getWorksheets)                   { globalCallbacks.getWorksheets(); }
        if(globalCallbacks.getAllProperties)                { globalCallbacks.getAllProperties(); }
        if(globalCallbacks.getTimeLines)                    { globalCallbacks.getTimeLines(); }
        if(globalCallbacks.drawingChanged)                  { globalCallbacks.drawingChanged(); }

        // In ObjectView.tsx
        if(globalCallbacks.drawingChangedObjectView)        { globalCallbacks.drawingChangedObjectView(); }

        //In worksheet timeline
        if (globalCallbacks.updateWorksheets)               { globalCallbacks.updateWorksheets(); }

        // In ObjectView.js
        if(globalCallbacks.drawingChangedObjectView)        { globalCallbacks.drawingChangedObjectView(); }

        // In LayerTable.js
        if(globalCallbacks.getNavigationLayers)             { globalCallbacks.getNavigationLayers(); }

        // In ClassTable.js
        if(globalCallbacks.getNavigationClasses)            { globalCallbacks.getNavigationClasses(); }

        //In ObjectPropertiesComponent.js
        if(globalCallbacks.getSelectedProperties)                   { globalCallbacks.getSelectedProperties(); }
        if(globalCallbacks.getObjectPropertiesLayers)               { globalCallbacks.getObjectPropertiesLayers(); }
        if(globalCallbacks.getObjectPropertiesClasses)              { globalCallbacks.getObjectPropertiesClasses(); }
        if(globalCallbacks.getObjectPropertiesInventoryContainers)  { globalCallbacks.getObjectPropertiesInventoryContainers(); }
        if(globalCallbacks.getObjectPropertiesExternalDocuments)    { globalCallbacks.getObjectPropertiesExternalDocuments(); }
        if(globalCallbacks.getObjectPropertiesColorCodes)           { globalCallbacks.getObjectPropertiesColorCodes(); }
        if (globalCallbacks.getMeshesAndTextures)                   { globalCallbacks.getMeshesAndTextures(); }
        if (globalCallbacks.updatePrintLabelsOPC)                   { globalCallbacks.updatePrintLabelsOPC(); }
        if (globalCallbacks.updatePrintLabels)                      { globalCallbacks.updatePrintLabels(); }

        //In ResourceManager.js
        if(globalCallbacks.getResourceManagerMeshes)        { globalCallbacks.getResourceManagerMeshes(); }
        if(globalCallbacks.getResourceManagerFixtureTypes)  { globalCallbacks.getResourceManagerFixtureTypes(); }
        if(globalCallbacks.getResourceManagerSymbolDefs)    { globalCallbacks.getResourceManagerSymbolDefs(); }
        
        // ToolSelect
        if(globalCallbacks.refetchSymbols_TOOL)    { globalCallbacks.refetchSymbols_TOOL(); }

        //In DocumentDisplay.js
        if(globalCallbacks.getExternalDocuments)            { globalCallbacks.getExternalDocuments(); }
        
        //User Task Page
        if(globalCallbacks.RefetchUsersForTasks)            { globalCallbacks.RefetchUsersForTasks(); }
        if(globalCallbacks.updateTasks)                     { globalCallbacks.updateTasks(); }

        // In SceneTimeLineComponent.js
        if(globalCallbacks.getTimePhases)                   { globalCallbacks.getTimePhases(); }
        if (globalCallbacks.getTimePhaseChanges)            { globalCallbacks.getTimePhaseChanges(); }

        // In CueList.js
        if (globalCallbacks.getTimePhasesCueList)           { globalCallbacks.getTimePhasesCueList(); }

        // In TrackingList.js
        if (globalCallbacks.getAllActiveFixtures)           { globalCallbacks.getAllActiveFixtures(); }

        // In PresetList.js
        if (globalCallbacks.getAllPresetsContent)           { globalCallbacks.getAllPresetsContent(); }

        // In FocusList.js
        if (globalCallbacks.getFixturesByAlignment)         { globalCallbacks.getFixturesByAlignment(); }

        // In CreateWorksheetModal.js
        if(globalCallbacks.getTimelinePhasesToLink)         { globalCallbacks.getTimelinePhasesToLink(); }

        // in ColorCodeTable.tsx
        if (globalCallbacks.updateColorCodeObjects) { globalCallbacks.updateColorCodeObjects(); }

        // In Footer.js
        if(globalCallbacks.updateActiveProject)             { globalCallbacks.updateActiveProject(); }
        if(globalCallbacks.updateActiveProjectIPhone)       { globalCallbacks.updateActiveProjectIPhone(); }

        // In ObjectPropertiesComponent.js
        if(globalCallbacks.getObjectPropertiesUsers)        { globalCallbacks.getObjectPropertiesUsers(); }

        if (globalCallbacks.updateRecentFiles)              { globalCallbacks.updateRecentFiles(); }
        if (globalCallbacks.onReloadElectricalTree)              { globalCallbacks.onReloadElectricalTree(); }

        // In SelectRessourceModal.js
        if (globalCallbacks.refreshResources)               { globalCallbacks.refreshResources(); }

        // In SelectProjectView.js
        if (globalCallbacks.closeProjectCheckoutModal)      { globalCallbacks.closeProjectCheckoutModal(); }

        if (globalCallbacks.getObjectPropertiesGetOrigins) 
        {
            globalCallbacks.getObjectPropertiesGetOrigins();
        }

        if (window.LR_E_SetMainWindowTitle) 
        {
            console.log("Setting main window title \"read-file\"", object.Argument);
            window.LR_E_SetMainWindowTitle({Title: object.Argument.Name + (object.Argument.DrawingDirty ? "*" : "")});
        }

        // Reset the edit mode
        globalCallbacks.editMode = EDIT_Scene;

        if (globalCallbacks.resourceManagerGeometryEditMode) 
        {
            globalCallbacks.resourceManagerGeometryEditMode(0) // Not in edit mode
        }

        // In ToolSelectComponent.js
        if (globalCallbacks.updateToolbarEditMode) {
            globalCallbacks.updateToolbarEditMode()
        }

        if (globalCallbacks.RendererRefetchEditMode) {
            globalCallbacks.RendererRefetchEditMode()
        }

        // In DmxOverview.js
        if (globalCallbacks.updateDmxTree){
            globalCallbacks.updateDmxTree();
        }
    
    }

    if (object.Command === "ShowDiffDialog") {
        // In DiffView.js
        if (globalCallbacks.ShowDiffDialog) { globalCallbacks.ShowDiffDialog(object.Argument); }
    }

    if (object.Command === "show-rename-property") {
        // In DiffView.js
        if (globalCallbacks.ShowRenamePropertyDialog) { globalCallbacks.ShowRenamePropertyDialog(object.Argument); }
    }

    if (object.Command === "renamed-property") {
        // In DiffView.js
        if (globalCallbacks.GetPropertyLocalizedOverwrite) { globalCallbacks.GetPropertyLocalizedOverwrite(); }
    }

    if (object.Command === "deleted-object-complete") {
        // In Renderer.js
        if (globalCallbacks.deleteObjectInRenderer) {
            globalCallbacks.deleteObjectInRenderer(object.Argument);
        }

        // In SceneTreeHierarchyComponent.js
        if (globalCallbacks.getSceneTree) {
            globalCallbacks.getSceneTree();
        }

        // In TableViewComponent.js
        if (globalCallbacks.getWorksheets) {
            globalCallbacks.getWorksheets();
        }

        if (globalCallbacks.getAllProperties) {
            globalCallbacks.getAllProperties();
        }

        // In TrackingList.js
        if (globalCallbacks.getAllActiveFixtures) {
            globalCallbacks.getAllActiveFixtures();
        }

        // In PresetList.js
        if (globalCallbacks.getAllPresetsContent) {
            globalCallbacks.getAllPresetsContent();
        }

        // In FocusList.js
        if (globalCallbacks.getFixturesByAlignment) {
            globalCallbacks.getFixturesByAlignment();
        }


        if (globalCallbacks.deleteElecticalItem) {
            globalCallbacks.deleteElecticalItem(object.Argument);
        }

        if (globalCallbacks.updateTasks) {
            globalCallbacks.updateTasks();
        }

        if (globalCallbacks.getObjectPropertiesGetOrigins) 
        {
            globalCallbacks.getObjectPropertiesGetOrigins();
        }

    }

    if (object.Command === "reload-resources")
    {
        if (globalCallbacks.getResourceManagerMeshes) {
            globalCallbacks.getResourceManagerMeshes();
        }
        if (globalCallbacks.getResourceManagerSymbolDefs) {
            globalCallbacks.getResourceManagerSymbolDefs();
        }
        if (globalCallbacks.getResourceManagerFixtureTypes) {
            globalCallbacks.getResourceManagerFixtureTypes();
        }
        if (globalCallbacks.getRessourceManagerTextures) {
            globalCallbacks.getRessourceManagerTextures();
        }
        if (globalCallbacks.refetchPreviewTextures) {
            globalCallbacks.refetchPreviewTextures();
        }
        if (globalCallbacks.refreshResources) {
            globalCallbacks.refreshResources();
        }

        if (globalCallbacks.getExternalDocuments) {
            globalCallbacks.getExternalDocuments();
        }
        if (globalCallbacks.getObjectPropertiesExternalDocuments) {
            globalCallbacks.getObjectPropertiesExternalDocuments();
        }

        if (globalCallbacks.refetchSymbols_TOOL) {
            globalCallbacks.refetchSymbols_TOOL();
        }

        if (globalCallbacks.getMeshesAndTextures) {
            globalCallbacks.getMeshesAndTextures();
        }
    }

    if (object.Command === "show-short-cut-modal")
    {
        if(globalCallbacks.ShowShortCutModal) {
            globalCallbacks.ShowShortCutModal();
        }
    }

    if (object.Command === "ShowRequestStructuralCalculation")
    {
        if(globalCallbacks.ShowRequestStructuralCheck) {
            globalCallbacks.ShowRequestStructuralCheck();
        }
    }

    if (object.Command === "Open-Edit-Mode")
    {
        globalCallbacks.editMode = EDIT_Geometry;

        // In Renderer.js
        if(globalCallbacks.OpenGeometryEditMode) {
            globalCallbacks.OpenGeometryEditMode(object.Argument);
        }

        // In TableViewComponent.js
        if (globalCallbacks.worksheetEditMode) {
            globalCallbacks.worksheetEditMode(object.Argument);
        }

        // In ToolSelectComponent.js
        if (globalCallbacks.updateToolBar) {
            globalCallbacks.updateToolBar();
        }

        // In ObjectPropertiesComponent.js
        if (globalCallbacks.getSelectedProperties) {
            globalCallbacks.getSelectedProperties();
        }

        // In SceneTreeHierarchyComponent.js
        if (globalCallbacks.getSceneTree) {
            globalCallbacks.getSceneTree();
        }

        // In ResourceManager.js
        if (globalCallbacks.resourceManagerGeometryEditMode) {
            globalCallbacks.resourceManagerGeometryEditMode(object.Argument.UUID) // In edit mode
        }

        // In ToolSelectComponent.js
        if (globalCallbacks.updateToolbarEditMode) {
            globalCallbacks.updateToolbarEditMode()
        }

    }

    if (object.Command === "Close-Edit-Mode")
    {
        globalCallbacks.editMode = EDIT_Scene;

        if(globalCallbacks.OpenGeometryEditMode) {
            globalCallbacks.OpenGeometryEditMode({UUID: 0});
        }

        if (globalCallbacks.activeResourceChanged) {
            globalCallbacks.activeResourceChanged();
        }
        
        if (globalCallbacks.updateToolBar) {
            globalCallbacks.updateToolBar();
        }


        // In TableViewComponent.js
        if (globalCallbacks.worksheetEditMode) {
            globalCallbacks.worksheetEditMode({UUID: 0});
        }

        //In ObjectPropertiesComponent.js
        if (globalCallbacks.getSelectedProperties) {
            globalCallbacks.getSelectedProperties();
        }

        // In SceneTreeHierarchyComponent.js
        if (globalCallbacks.getSceneTree) {
            globalCallbacks.getSceneTree();
        }

        // In TableViewComponent.js
        if (globalCallbacks.getAllProperties) {
            globalCallbacks.getAllProperties();
        }

        // In ResourceManager.js
        if (globalCallbacks.resourceManagerGeometryEditMode) {
            globalCallbacks.resourceManagerGeometryEditMode(0) // Not in edit mode
        }


        // In ToolSelectComponent.js
        if (globalCallbacks.updateToolbarEditMode) {
            globalCallbacks.updateToolbarEditMode()
        }

        // In SelectRessourceModal.js
        if (globalCallbacks.refreshResources) { 
            globalCallbacks.refreshResources()
        }

    }

    if (object.Command === "Update-Electrical-Tree")
    {
        // In SummeryList.js
        if (globalCallbacks.refreshSummeryList) { globalCallbacks.refreshSummeryList() }

        
        if (globalCallbacks.UpdateHotpatchDialog) { globalCallbacks.UpdateHotpatchDialog() }

        if (globalCallbacks.updateInternWiring) {
            globalCallbacks.updateInternWiring()
        }

        // In ElectricalList.js
        if (globalCallbacks.UpdateGenerators) {
            globalCallbacks.UpdateGenerators()
        }

        if(globalCallbacks.ElectricalTreePositionReset) {globalCallbacks.ElectricalTreePositionReset()}
    }

    if (object.Command === "Update-Dmx-Tree")
    {
        // In SceneTreeHierarchyComponent.js
        if (globalCallbacks.updateDuplicatedFixtureIds) { globalCallbacks.updateDuplicatedFixtureIds(); }

        // In DmxOverview.js
        if (globalCallbacks.updateDmxTree)
            globalCallbacks.updateDmxTree();

            // In SummeryList.js
        if (globalCallbacks.refreshSummeryList) { globalCallbacks.refreshSummeryList() }
    }

    if (object.Command === "Log-Out-From-App")
    {
        // Sign In Component
        if (globalCallbacks.logoutFromServer)
            globalCallbacks.logoutFromServer();
        
    }

    if (object.Command === 'show-select-active-project')
    {
        if (globalCallbacks.openSelectActiveProject) { globalCallbacks.openSelectActiveProject(object.Argument); }
    }
    if (object.Command === 'show-calendar')
    {
        if (globalCallbacks.OpenCalenderView) { globalCallbacks.OpenCalenderView(); }
    }
    if (object.Command === 'show-create-branch')
    {
        if (globalCallbacks.openNewBranch) { globalCallbacks.openNewBranch(object.Argument); }
    }
    if (object.Command === 'show-import-map')
    {
        if (globalCallbacks.openimportmap) { globalCallbacks.openimportmap(object.Argument); }
    }
    if (object.Command === 'connect-to-remote')
    {
        if (globalCallbacks.ShowConnectToRemmote) { globalCallbacks.ShowConnectToRemmote(); }
    }

    if (object.Command === 'show-about-dialog')
    {
        if (globalCallbacks.ShowAboutDialog) { globalCallbacks.ShowAboutDialog(); }
    }

    if (object.Command === 'LRNET-connected')
    {
        if (globalCallbacks.onLRNET_Connect) { globalCallbacks.onLRNET_Connect(); }
    }

    if (object.Command === 'LRNET-disconnected')
    {
        if (globalCallbacks.onLRNET_Disconnect) { globalCallbacks.onLRNET_Disconnect(); }
    }

    if (object.Command === 'LRNET-message-incomming')
    {
        if (globalCallbacks.onLRNET_Message) { globalCallbacks.onLRNET_Message(); }
    }

    if (object.Command === 'zoom-to-selection')
    {
        if (globalCallbacks.zoomToSelection) { globalCallbacks.zoomToSelection(); }
    }

    if (object.Command === 'position-objects-shape')
    {
        if (globalCallbacks.ShowArrayModifierDialog) { globalCallbacks.ShowArrayModifierDialog({ Create: false, });}    
    }

    if (object.Command === 'show-progress-start')
    {
        if (globalCallbacks.ShowProgressBar) { globalCallbacks.ShowProgressBar(object.Argument.Message);}    
    }

    if (object.Command === 'show-progress-end')
    {
        if (globalCallbacks.HideProgressBar) { globalCallbacks.HideProgressBar();}    
    }

    if (object.Command === "drawing-changed")
    {
        // In Footer.js
        if(globalCallbacks.updateActiveProject)             { globalCallbacks.updateActiveProject(); }
        if(globalCallbacks.updateActiveProjectIPhone)       { globalCallbacks.updateActiveProjectIPhone(); }

        // In ObjectPropertiesComponent.js
        if(globalCallbacks.getObjectPropertiesUsers)        { globalCallbacks.getObjectPropertiesUsers(); }

        // In sceneTree.js
        if (globalCallbacks.displayDrawingMenu)             { globalCallbacks.displayDrawingMenu(); }

        // In TableViewComponent.js
        if (globalCallbacks.drawingChanged)                 { globalCallbacks.drawingChanged(); }

        // In ObjectView.tsx
        if(globalCallbacks.drawingChangedObjectView)        { globalCallbacks.drawingChangedObjectView(); }

        // In LayerTable.js
        if (globalCallbacks.updateActiveClass) { globalCallbacks.updateActiveClass(); }

        // In ClassTable.js
        if (globalCallbacks.updateActiveLayer) { globalCallbacks.updateActiveLayer() }

        // In Load Comp
        if (globalCallbacks.updateActiveLoadCombination) { globalCallbacks.updateActiveLoadCombination() }

        if (globalCallbacks.updateMVRXchangeModal) { globalCallbacks.updateMVRXchangeModal() }

        if (window.LR_E_SetMainWindowTitle) 
        {
            window.LR_E_SetMainWindowTitle({Title: object.Argument.Name + (object.Argument.DrawingDirty ? "*" : "")});
        }

    }

    if (object.Command === "drawing-dirty-changed")
    {
        if (window.LR_E_SetMainWindowTitle) 
        {
            window.LR_E_SetMainWindowTitle({Title: object.Argument.DrawingName + (object.Argument.Dirty ? "*" : "")});
        }
    }

    if (object.Command === "structures-changed")
    {
        //In Graph List
        if (globalCallbacks.updateGraphList) { globalCallbacks.updateGraphList(); }

        //In Renderer.js
        if (globalCallbacks.colorStructures) { globalCallbacks.colorStructures(); }
    }

    if (object.Command === 'set-renderer-view')
    {
        if (globalCallbacks.setRendererView) { globalCallbacks.setRendererView(object.Argument); }
    }

    if (object.Command === 'selection-group-changed')
    {
        if (globalCallbacks.refreshSelectionGroups) { globalCallbacks.refreshSelectionGroups(); }
    }

    if (object.Command === 'inventory-container-changed')
    {
        if (globalCallbacks.refreshInventoryContainers)             { globalCallbacks.refreshInventoryContainers(); }

        //In ObjectPropertiesComponent.js
        if(globalCallbacks.getObjectPropertiesInventoryContainers)  { globalCallbacks.getObjectPropertiesInventoryContainers(); }

        // In TruckList.js
        if (globalCallbacks.updateInventoryContainerList)           { globalCallbacks.updateInventoryContainerList(); }

        // In CaseList.js
        if (globalCallbacks.updateCaseList)                         { globalCallbacks.updateCaseList(); }
    }

    if (object.Command === 'settings-changed')
    {
        if (globalCallbacks.rendererSettingsChanged) { globalCallbacks.rendererSettingsChanged(object.Argument); }
        if (globalCallbacks.footerSettingsChanged) { globalCallbacks.footerSettingsChanged(object.Argument); }
        if (globalCallbacks.TableViewUpdateGlobalSettings) { globalCallbacks.TableViewUpdateGlobalSettings(object.Argument); }
        
        // Update Lang
        let lang = window.localStorage.getItem("language")
        if(lang !== AppLanguageToString(object.Argument.Language.Value))
        {
            window.localStorage.setItem("language", AppLanguageToString(object.Argument.Language.Value))
            window.location.reload(false)
        }
        
        // Update Theme

        let theme = IsDarkTheme() ? "dark" : "light"
        if(theme !== AppThemeToString(object.Argument.Theme.Value))
        {
            window.localStorage.setItem("theme", AppThemeToString(object.Argument.Theme.Value))
            window.location.reload(false)
        }

        let view = ForceOneWindowRouter() ? "singleview" : "default"
        if(view !== AppDisplaymodeToString(object.Argument.DisplayMode.Value))
        {
            window.localStorage.setItem("router", AppDisplaymodeToString(object.Argument.DisplayMode.Value))
            window.location.reload(false)
        }
        
        if (globalCallbacks.validateLogin) { globalCallbacks.validateLogin(); }

        // Fetch the global settings to redux
        FetchGlobalSettings(true)
    }

    if (object.Command === 'place-to-structure')
    {
        if (globalCallbacks.ShowPlaceToStructure) { globalCallbacks.ShowPlaceToStructure(object.Argument);}    
    }

    if (object.Command === 'open-settings')
    {
        if (globalCallbacks.openSettings) { globalCallbacks.openSettings(); }
    }

    if (object.Command === 'support-list-changed')
    {
        if (globalCallbacks.updateSupportList) { globalCallbacks.updateSupportList(); }

        if (globalCallbacks.updateAvailableOrigins) { globalCallbacks.updateAvailableOrigins(); }

        // In SummeryList.js
        if (globalCallbacks.refreshSummeryList) { globalCallbacks.refreshSummeryList(); }
        // In Renderer.js
        if (globalCallbacks.updateUsedHoistRender) { globalCallbacks.updateUsedHoistRender(); }
    }

    if (object.Command === 'drawing-bounding-changed')
    {
        if (globalCallbacks.updateDrawingBoundingBox) { globalCallbacks.updateDrawingBoundingBox(object.Argument); }
    }

    if (object.Command === 'print-label-changed')
    {
        // In ObjectPropertiesComponent.js
        if (globalCallbacks.getSelectedProperties) { globalCallbacks.getSelectedProperties(); }
        if (globalCallbacks.updatePrintLabelsOPC) { globalCallbacks.updatePrintLabelsOPC();}
        if (globalCallbacks.updatePrintLabels) { globalCallbacks.updatePrintLabels();}

        // In Renderer2D.js
        if (globalCallbacks.UpdatePrintLabelBackground) { globalCallbacks.UpdatePrintLabelBackground(); }


        if (globalCallbacks.getResourceManagerPrintLabels) { globalCallbacks.getResourceManagerPrintLabels(); }
        if (globalCallbacks.updateLabelFields) { globalCallbacks.updateLabelFields(); }
    }

    if (object.Command === 'open-label-edit')
    {
        if (globalCallbacks.showLabelEdit) { globalCallbacks.showLabelEdit(object.Argument); }

        if (globalCallbacks.resourceManagerChangeEditMode) { globalCallbacks.resourceManagerChangeEditMode(object.Argument); }
    }

    if (object.Command === 'close-label-edit')
    {
        if (globalCallbacks.closeLabelEdit) { globalCallbacks.closeLabelEdit(); }
        if (globalCallbacks.resourceManagerChangeEditMode) { globalCallbacks.resourceManagerChangeEditMode(0); }
    }

    if (object.Command === 'open-label-print')
    {
        if (globalCallbacks.showPrintDialog) { globalCallbacks.showPrintDialog(); }
    }

    if (object.Command === 'ressource-map-updated')
    {
        // In SymbolMapDisplay.js
        if (globalCallbacks.ressourceMapUpdaded) { globalCallbacks.ressourceMapUpdaded(); }

        // In TruckTemplateDisplay.js
        if (globalCallbacks.updateTruckTemplates) { globalCallbacks.updateTruckTemplates(); }
        
        // In PaperTemplateDisplay.js
        if (globalCallbacks.updatePaperFormatTemplates) { globalCallbacks.updatePaperFormatTemplates(); }
        
        // In ObjectPropertiesComponent.js
        if (globalCallbacks.updatePaperFormatTemplatesOPC) { globalCallbacks.updatePaperFormatTemplatesOPC(); }

        // In CaseTemplateDisplay.js
        if (globalCallbacks.updateCaseTemplates) { globalCallbacks.updateCaseTemplates(); }

        // In RackTemplateDisplay.js
        if (globalCallbacks.updateRackTemplate) { globalCallbacks.updateRackTemplate(); }

        // In ObjectPropertiesColumnsDisplay.js
        if (globalCallbacks.updatePropertyPresets) {globalCallbacks.updatePropertyPresets() }

        // In VectorworksFieldMapping.js
        if (globalCallbacks.updateFieldMap) {globalCallbacks.updateFieldMap() }

        // In ObjectPropertiesColumnsDisplay.js
        if (globalCallbacks.updateObjectPropertiesTemplateMap) { globalCallbacks.updateObjectPropertiesTemplateMap(); }

        // In InventoryColumnsDisplay.js
        if (globalCallbacks.updateInventoryPropertiesTemplateMap) { globalCallbacks.updateInventoryPropertiesTemplateMap(); }

        // In CrossSectionTemplateDisplay.js
        if (globalCallbacks.updateCrossSectionTemplates) { globalCallbacks.updateCrossSectionTemplates(); }

        // In ConnectorTemplates.js
        if (globalCallbacks.updateConnectorTemplates) { globalCallbacks.updateConnectorTemplates(); }

         // In MaterialTemplateDisplay.js
         if (globalCallbacks.updateMaterialTemplates) { globalCallbacks.updateMaterialTemplates(); }

        // In ExportStructuralCalculation.js
        if (globalCallbacks.GetCalculationReportPresets) { globalCallbacks.GetCalculationReportPresets(); }

        // In ExportPaperworkReport.js
        if (globalCallbacks.GetPaperworkReportPresets) {globalCallbacks.GetPaperworkReportPresets(); }

        // In ObjectPropertiesColumnsDisplay.js
        if (globalCallbacks.updateObjectPropertiesTemplateMap)  { globalCallbacks.updateObjectPropertiesTemplateMap(); }
    }

    if (object.Command === 'color-code-changed')
    {
        if (globalCallbacks.updateColorCodeObjects) { globalCallbacks.updateColorCodeObjects(); }

        //In ObjectPropertiesComponent.js
        if (globalCallbacks.getObjectPropertiesColorCodes) { globalCallbacks.getObjectPropertiesColorCodes(); }
    }

    if (object.Command === 'show-change-origin')
    {
        // In ChangeOriginModal.js
        if (globalCallbacks.openChangeOriginModal) { globalCallbacks.openChangeOriginModal(object.Argument); }
    }

    if (object.Command === 'case-changed')
    {
        if (globalCallbacks.updateCaseObjects) { globalCallbacks.updateCaseObjects(); }
        if (globalCallbacks.updateInventoryContainerList) {globalCallbacks.updateInventoryContainerList();}
        if (globalCallbacks.updateCaseList) { globalCallbacks.updateCaseList(); }
        if(globalCallbacks.getObjectPropertiesInventoryContainers)  { globalCallbacks.getObjectPropertiesInventoryContainers(); }

    }

    if (object.Command === 'rack-changed')
    {
        if (globalCallbacks.updateRackList)                             { globalCallbacks.updateRackList(); }
        if (globalCallbacks.updateRackObjects)                          { globalCallbacks.updateRackObjects(); }
        if (globalCallbacks.updateInventoryContainerList)               { globalCallbacks.updateInventoryContainerList();}
        if (globalCallbacks.getObjectPropertiesInventoryContainers)     { globalCallbacks.getObjectPropertiesInventoryContainers(); }
    }

    if (object.Command === 'truck-changed')
    {
        if (globalCallbacks.refreshInventoryContainers) 
        { 
            globalCallbacks.refreshInventoryContainers() 
        }   
        if (globalCallbacks.getObjectPropertiesInventoryContainers)
        {
            globalCallbacks.getObjectPropertiesInventoryContainers()
        }
    }

    if (object.Command === 'open-move-to-assembly-group')
    {
        if (globalCallbacks.showMoveToAssemblyGroup) { globalCallbacks.showMoveToAssemblyGroup(); }
    }

    if (object.Command === 'show-fixture-numbering')
    {
        if (globalCallbacks.showFixtureNumbering) { globalCallbacks.showFixtureNumbering(object.Argument) }
    }

    if (object.Command === 'show-ask-for-group-name')
    {
        if (globalCallbacks.showAskForGroupName) { globalCallbacks.showAskForGroupName(); }
    }

    if (object.Command === 'show-pack-objects')
    {
        if (globalCallbacks.showPackObjects) { globalCallbacks.showPackObjects(); }
    }

    if (object.Command === 'visibility-objects-changed')
    {
        if (globalCallbacks.updateWorksheetVisibliltyObjects)
        {
            globalCallbacks.updateWorksheetVisibliltyObjects(object.Argument);
        }
    }

    if (object.Command === 'active-class-changed')
    {
    }
    
    if (object.Command === 'active-layer-changed')
    {
        if (globalCallbacks.updateActiveClass) { globalCallbacks.updateActiveClass(); }
        if (globalCallbacks.updateActiveLayer) { globalCallbacks.updateActiveLayer() }
    }

    if (object.Command === 'show-merge-view') 
    {   
        if (globalCallbacks.showMergeView) {globalCallbacks.showMergeView(object.Argument) }
    }

    if (object.Command === 'load-combination-changed') 
    {   
        if (globalCallbacks.updateLoadCombination) {globalCallbacks.updateLoadCombination() }
    }

    if (object.Command === 'load-group-changed') 
    {   
        if (globalCallbacks.uploadeLoadGroups) {globalCallbacks.uploadeLoadGroups() }
        if (globalCallbacks.getObjectPropertiesLoadGroups) {globalCallbacks.getObjectPropertiesLoadGroups() }
    }
    if (object.Command === 'saved-view-changed') 
    {   
        if (globalCallbacks.updateSavedViews)       {globalCallbacks.updateSavedViews() }
        if (globalCallbacks.getSavedViewsWorksheet) {globalCallbacks.getSavedViewsWorksheet() }
    }
    if (object.Command === 'show-saved-view') 
    {   
        if (globalCallbacks.showSavedView) {globalCallbacks.showSavedView(object.Argument) }
    }
    if (object.Command === 'department-changed')
    {
        if (globalCallbacks.getUserOverview) { globalCallbacks.getUserOverview()}
        if (globalCallbacks.updateDepartmentObjects) { globalCallbacks.updateDepartmentObjects()}
        if (globalCallbacks.getObjectPropertiesDepartments) { globalCallbacks.getObjectPropertiesDepartments() }
    }
    if (object.Command === 'update-recent-files')
    {
        if (globalCallbacks.updateRecentFiles) { globalCallbacks.updateRecentFiles(); }
    }
    if (object.Command === 'export-calculation-report')
    {
        if (globalCallbacks.ShowExportStructuralCalculation) { globalCallbacks.ShowExportStructuralCalculation(); }
    }
    if (object.Command === 'changed-preview-texture')
    {
        // In ResourceManager.js
        if (globalCallbacks.refetchPreviewTextures) { globalCallbacks.refetchPreviewTextures(); }
    }
    if (object.Command === 'export-paperworks-report')
    {
        if (globalCallbacks.ShowExportPaperworkPdf) { globalCallbacks.ShowExportPaperworkPdf(); }
    }
    if (object.Command === 'ask_for_LRNET_master')
    {
        if (globalCallbacks.AskForLRNetMaster) { globalCallbacks.AskForLRNetMaster(); }
    }
    if (object.Command === 'update-error-context')
    {
        if (globalCallbacks.UpdateErrorContext) { globalCallbacks.UpdateErrorContext(object.Argument); }
        if (globalCallbacks.UpdateErrorDisplayNavigation) { globalCallbacks.UpdateErrorDisplayNavigation(object.Argument); }
    }

    if (object.Command === 'show-benchmark-dialog')
    {
        if (globalCallbacks.OpenBenchmarkDialog) { globalCallbacks.OpenBenchmarkDialog(object.Argument); }
    }

    if (object.Command === 'show-change-fixture-type')
    {
        if (globalCallbacks.openFixtureTypes) { globalCallbacks.openFixtureTypes(object.Argument.ResourceType); }
    }

    if (object.Command === 'show-resolve-double-output-usage')
    {
        if (globalCallbacks.ShowResolveDoubleConnections) { globalCallbacks.ShowResolveDoubleConnections(object.Argument); }
    }

    if (object.Command === 'show-array-modifier')
    {
        if (globalCallbacks.ShowArrayModifierDialog) { globalCallbacks.ShowArrayModifierDialog({Create: true, ...object.Argument}); }
    }

    if (object.Command === 'show-structural-result-dialog')
    {
        if (globalCallbacks.ShowViewStructuralResults) { globalCallbacks.ShowViewStructuralResults(object.Argument); }
    }

    if (object.Command === 'show-create-worksheet')
    {
        if (globalCallbacks.createWorksheet) { globalCallbacks.createWorksheet(object.Argument); }
    }

    if (object.Command === 'show-create-note')
    {
        if (globalCallbacks.ShowMakeNote) { globalCallbacks.ShowMakeNote(object.Argument); }
    }

    if (object.Command === 'notes-changed')
    {
        if (globalCallbacks.getNotesForProperties)  { globalCallbacks.getNotesForProperties(); }
        if (globalCallbacks.RefetchNotesView)       { globalCallbacks.RefetchNotesView(); }

    }

    if (object.Command === 'show-worksheet')
    {
        if (globalCallbacks.EnableWorksheetView) { globalCallbacks.EnableWorksheetView(); }
        if (globalCallbacks.ShowWorksheet)       { globalCallbacks.ShowWorksheet(object.Argument); }
    }
    
    if (object.Command === 'show-add-user')
    {
        if (globalCallbacks.ShowAddNewUserToProject) { globalCallbacks.ShowAddNewUserToProject(); }

    }

    if (object.Command === 'resource-container-changed')
    {
        if (globalCallbacks.getSelectedProperties)  { globalCallbacks.getSelectedProperties(); }
        if (globalCallbacks.refreshSummeryList) { globalCallbacks.refreshSummeryList(); }   
    }

    if (object.Command === 'active-resource-changed')
    {
        if (globalCallbacks.activeResourceChanged) { globalCallbacks.activeResourceChanged(); }
    }

    if (object.Command === 'commit-to-server-finshed')
    {
        if (globalCallbacks.CloseDiffDialog) { globalCallbacks.CloseDiffDialog(); }
    }

    if (object.Command === 'removed-property')
    {
        if (globalCallbacks.TableViewRemoveProperty) { globalCallbacks.TableViewRemoveProperty(object.Argument); }
    }

    if (object.Command === 'import-dwg-scale')
    {
        if (globalCallbacks.openDwgScale) { globalCallbacks.openDwgScale(object.Argument); }
    }

    if (object.Command === 'show-share-modal')
    {
        if (globalCallbacks.ShowCreateShowLink) { globalCallbacks.ShowCreateShowLink(object.Argument); }
    }
    if (object.Command === 'show-export-view-modal')
    {
        if (globalCallbacks.ShowExportViewModal) { globalCallbacks.ShowExportViewModal(object.Argument); }
    }

    if (object.Command === 'changed-log-100-connection')
    {
        if (globalCallbacks.RefreshLoadCellData) { globalCallbacks.RefreshLoadCellData(object.Argument); }
    }

    if (object.Command === 'show-load-cell-monitor')
    {
        if (globalCallbacks.ShowConnectLoadCells) { globalCallbacks.ShowConnectLoadCells(object.Argument); }
    }
    if (object.Command === 'ShowTrussDataGenerator')
    {
        if (globalCallbacks.ShowConnectLoadCells) { globalCallbacks.ShowCalculateTrussData(object.Argument); }
    }

    if (object.Command === 'ShowHotpatchForSelectedObjects')
    {
        if (globalCallbacks.ShowHotpatchDialog) { globalCallbacks.ShowHotpatchDialog(object.Argument); }
    }
    
    if (object.Command === 'ShowRandomChainShortenValue')
    {
        if (globalCallbacks.ShowRandomizeChainShortenValues) { globalCallbacks.ShowRandomizeChainShortenValues(object.Argument); }
    }

    if (object.Command === 'ShowCalculateChainShortenEffect')
    {
        if (globalCallbacks.ShowCalculateChainShortenEffect) { globalCallbacks.ShowCalculateChainShortenEffect(object.Argument); }
    }

    if (object.Command === 'RequestMVRfromMVRxchange')
    {
        if (globalCallbacks.openMVRxchangeSettingsModal) { globalCallbacks.openMVRxchangeSettingsModal(); }
    }

    if (object.Command === 'OpenMVRxchangeSettingsModal')
    {
        if (globalCallbacks.openMVRxchangeSettingsModal) { globalCallbacks.openMVRxchangeSettingsModal(); }
    }
    
    if (object.Command === 'SimulateWindDegree')
    {
        window.LR_SimulateWindLoad({Angle: object.Argument.Angle})
        addNotification("Wind angle " +  Math.round(radToDeg(object.Argument.Angle)) , "Wind angle " + Math.round(radToDeg(object.Argument.Angle)))
    }

    if (object.Command === 'OpenEditRendererView')
    {
        if (globalCallbacks.OnOpenEditRendererView) { globalCallbacks.OnOpenEditRendererView(object.Argument); }
    }

    if (object.Command === 'CloseEditRendererView')
    {
        if (globalCallbacks.OnCloseEditRendererView) { globalCallbacks.OnCloseEditRendererView(); }
    }

    if (object.Command === 'ShowScaleByDistance')
    {
        if (globalCallbacks.ShowScaleByDistance) { globalCallbacks.ShowScaleByDistance(object.Argument); }
    }

    if (object.Command === 'show-export-symbols-modal')
    {
        if (globalCallbacks.showExportSymbolsModal) { globalCallbacks.showExportSymbolsModal(object.Argument); }
    }

    if (object.Command === 'ShowCalcFinishedDialog')
    {
        if (globalCallbacks.ShowCalculationOverview) { globalCallbacks.ShowCalculationOverview(); }
    }

    if (object.Command === 'activate-input-field')
    {
        let element = document.getElementById(object.Argument.HTMLInputId);
        if(element)
        {
            element.focus()
        }
        else
        {
            addNotification(LocalizedStringsNotification.NoElementFound, LocalizedStringsNotification.NoElementFoundText, false, true)
        }
    }
    if (object.Command === 'user-changed')
    {
        if (globalCallbacks.getUserOverview) { globalCallbacks.getUserOverview(object.Argument); }
        if (globalCallbacks.getObjectPropertiesUsers) { globalCallbacks.getObjectPropertiesUsers(object.Argument); }
        if  (globalCallbacks.getResourceScheduleUsers) { globalCallbacks.getResourceScheduleUsers()}
    }

    if (object.Command === 'show-install-plugin')
    {
        if (globalCallbacks.ShowInstallPluginModal) { globalCallbacks.ShowInstallPluginModal(object.Argument); }
    }
    
    if (object.FocusWorksheet)
    {
        if (globalCallbacks.showWorksheetView) {globalCallbacks.showWorksheetView("renderTableView")}
    }

    if (object.FocusMain &&
        (
        object.Command === "Open-Edit-Mode" ||
        object.Command === "zoom-to-selection" && (! window.IsVectorworksContext && !window.ElectronWebsocketMode)
        ))
    {   
        if (globalCallbacks.Enable3DVView) {globalCallbacks.Enable3DVView()}
    }

    globalCallbacks.runCallbacks(object.Command, object.Argument)
    
}

window.OnLightRightCoreCallback = OnLightRightCoreCallback

ipcRenderer.on('OnLightRightCoreCallback', (event, arg) => {
    OnLightRightCoreCallback(arg);
});

//------------------------------------------------------------------------------
// RCC panels

ipcRenderer.on('toggleContainer', (event, arg) => {
    if (globalCallbacks.toggleContainer) { globalCallbacks.toggleContainer(arg); }
});

globalCallbacks.getProject= (name) =>
{
    return ipcRenderer.sendSync('get-project', name);
}

globalCallbacks.removeUserFromProject = (link) =>
{
    return ipcRenderer.sendSync('remove-user-from-project', link);
}

globalCallbacks.addUserToProject = (user) =>
{
    return ipcRenderer.sendSync('add-user-to-project', user);
}

globalCallbacks.sendUpdatePassword = (password, secretToken) =>
{
    return ipcRenderer.sendSync('reset-password', password, secretToken);
}


globalCallbacks.deleteProject = () => 
{
    return ipcRenderer.sendSync('delete-project');
}

globalCallbacks.changePassword = (oldpw, newpw) =>
{
    return ipcRenderer.sendSync('change-password', oldpw, newpw);
}

globalCallbacks.addCollaborator = (collaborator) =>
{
    return ipcRenderer.sendSync('add-collaborator', collaborator)
}

globalCallbacks.removeCollaborator = (collaborator) =>
{
    return ipcRenderer.sendSync('remove-collaborator', collaborator)
}


globalCallbacks.setAvatar = (file) =>
{
    return ipcRenderer.sendSync('set-avatar', file)
}

globalCallbacks.setSlackChannel = (channel) => 
{
    return ipcRenderer.sendSync('set-slack-channel', channel);
}

globalCallbacks.createProject = (data) =>
{
    return ipcRenderer.sendSync('create-project', data)
}

globalCallbacks.updateRecentFiles = () =>
{
    window.LR_GetRecentFiles().then(files => {
        if (globalCallbacks.updateMenuBar) {
            globalCallbacks.updateMenuBar(files.RecentFiles)
        }
    })
}

globalCallbacks.updateMacTouchbar = () =>
{
    return window.LR_E_UpdateMacToolbar ? window.LR_E_UpdateMacToolbar() : undefined
}