// let red     = { fx : 0.6400, fy : 0.3299, f_Y : 21.26}
// let green   = { fx : 0.3, fy : 0.6, f_Y : 71.52}
// let blue    = { fx : 0.1500, fy : 0.0600, f_Y : 7.22}
// let white   = { fx : 0.31273, fy : 0.32902, f_Y : 100}


export const defaultCie = {
    fx : 0.3127,
    fy : 0.3290,
    f_Y: 100.0
}
export const mainTabColors = {
    changed : "#76FF03",
    default : "#ECECEC"
}

export const cie2RGB = (cie) =>
{
    if ( !cie ) return [ 0, 0, 0 ]
    // http://www.easyrgb.com/en/math.php
    let fx = cie.fx;
    let fy = cie.fy;
    let Y  = cie.f_Y;

    if(fx === undefined)
    {
        fx = cie.X;
        fy = cie.Y;
        Y  = cie.Z
    }

    if ( !fx || !fy || !Y ) return [ 0, 0, 0 ]

    let X = fx*(Y/fy);
    let Z = (1-fx-fy)*(Y/fy);

    let var_X = X / 100
    let var_Y = Y / 100
    let var_Z = Z / 100

    let var_R = var_X *  3.2406 + var_Y * -1.5372 + var_Z * -0.4986;
    let var_G = var_X * -0.9689 + var_Y *  1.8758 + var_Z *  0.0415;
    let var_B = var_X *  0.0557 + var_Y * -0.2040 + var_Z *  1.0570;

    if ( var_R > 0.0031308 ) var_R = 1.055 * ( Math.pow(var_R , ( 1 / 2.4 )) ) - 0.055;
    else                     var_R = 12.92 * var_R;

    if ( var_G > 0.0031308 ) var_G = 1.055 * ( Math.pow(var_G , ( 1 / 2.4 )) ) - 0.055;
    else                     var_G = 12.92 * var_G;

    if ( var_B > 0.0031308 ) var_B = 1.055 * ( Math.pow(var_B , ( 1 / 2.4 )) ) - 0.055;
    else                     var_B = 12.92 * var_B;

    let r = var_R * 255
    let g = var_G * 255
    let b = var_B * 255

    let rgb = [];
    rgb.push(Math.round(r));
    rgb.push(Math.round(g));
    rgb.push(Math.round(b));

    return rgb;
}

export const RGB2cie = (rgb) => {
    let var_R = ( rgb[0] / 255 )
    let var_G = ( rgb[1] / 255 )
    let var_B = ( rgb[2] / 255 )

    if ( var_R > 0.04045 ) var_R = ( Math.pow(( var_R + 0.055 ) / 1.055  , 2.4))
    else                   var_R = var_R / 12.92
    if ( var_G > 0.04045 ) var_G = ( Math.pow(( var_G + 0.055 ) / 1.055  , 2.4))
    else                   var_G = var_G / 12.92
    if ( var_B > 0.04045 ) var_B = ( Math.pow(( var_B + 0.055 ) / 1.055  , 2.4))
    else                   var_B = var_B / 12.92

    var_R = var_R * 100
    var_G = var_G * 100
    var_B = var_B * 100

    let X = var_R * 0.4124 + var_G * 0.3576 + var_B * 0.1805
    let Y = var_R * 0.2126 + var_G * 0.7152 + var_B * 0.0722
    let Z = var_R * 0.0193 + var_G * 0.1192 + var_B * 0.9505

    let f_Y = Y
    let sum = ( X + Y + Z )
    let fx = sum === 0 ? 0 : X / sum
    let fy = sum === 0 ? 0 : Y / sum

    return {
        f_Y: f_Y,
        fx: fx,
        fy: fy,
        }
}

export const XYZ2RGB = (xyz) => {
    let var_X = xyz[0]
    let var_Y = xyz[1]
    let var_Z = xyz[2]

    let var_R = var_X *  3.2406 + var_Y * -1.5372 + var_Z * -0.4986;
    let var_G = var_X * -0.9689 + var_Y *  1.8758 + var_Z *  0.0415;
    let var_B = var_X *  0.0557 + var_Y * -0.2040 + var_Z *  1.0570;

    if ( var_R > 0.0031308 ) var_R = 1.055 * ( Math.pow(var_R , ( 1 / 2.4 )) ) - 0.055;
    else                     var_R = 12.92 * var_R;

    if ( var_G > 0.0031308 ) var_G = 1.055 * ( Math.pow(var_G , ( 1 / 2.4 )) ) - 0.055;
    else                     var_G = 12.92 * var_G;

    if ( var_B > 0.0031308 ) var_B = 1.055 * ( Math.pow(var_B , ( 1 / 2.4 )) ) - 0.055;
    else                     var_B = 12.92 * var_B;

    let r = var_R * 255
    let g = var_G * 255
    let b = var_B * 255

    let rgb = [];
    rgb.push(Math.round(r));
    rgb.push(Math.round(g));
    rgb.push(Math.round(b));

    return rgb;
}

export const leftOfLine = (p1,p2,tp) =>
{
    let a = p1[1]-p2[1];
    let b = p2[0]-p1[0];
    let c = -a*p1[0] - b*p1[1];
    return a*tp[0]+b*tp[1] + c > 0; 
}

export const isInside = (pl,tp) => {
    for (let i = 1; i < pl.length; i++) {
        if(!leftOfLine(pl[i-1],pl[i],tp))
        {
            return false;
        }
    }
    if (leftOfLine(pl[pl.length-1],pl[0],tp))
    {
        return true;
    }
    else
    {
        return false;
    }
}

export const map2spec = (cie,pl) =>
{
    const xW = 0.3127159072215825;
    const yW = 0.3290014805066623;
    let step = 0.5;
    let xOff = 0;
    let yOff = 0;
    let xDir = cie.fx - xW; 
    let yDir = cie.fy - yW;
    for (let i = 0; i < 10; i++) {
        if(isInside(pl,[xW + xOff + xDir*step,yW + yOff + yDir*step]))
        {
            xOff += xDir*step;
            yOff += yDir*step;
        }
        step  = step/2
    }
    cie.fx = xW + xOff;
    cie.fy = yW + yOff;
    return cie
}

export const cie2hex = (cie) =>
{ 
    let rgb = cie2RGB(cie);
    if (rgb[0] < 0) { rgb[0] = 0} if (rgb[0] > 255) { rgb[0] = 255}
    if (rgb[1] < 0) { rgb[1] = 0} if (rgb[1] > 255) { rgb[1] = 255}
    if (rgb[2] < 0) { rgb[2] = 0} if (rgb[2] > 255) { rgb[2] = 255}

    return rgb2hex(rgb);
}

export const rgb2hex = (rgb) =>
{
    if(!Array.isArray(rgb)){
        rgb = [rgb.R, rgb.G, rgb.B]
    }
    
    let hex = rgb.map( x => 
    {
        x = parseInt(x, 10).toString(16);  
        return (x.length === 1) ? "0" + x : x;
    })
    
    return "#" + hex.join("");
}

export const  hex2rgb = (hex) =>
{
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
} : null;
}

export const colorCapRgb = (rgb) => {
    for (let i = 0; i < 3; i++) {
      if(rgb[i] < 0)
      {
        rgb[i] = 0;
      }
      else if (rgb[i] > 255)
      {
        rgb[i] = 255
      }
    }
  }