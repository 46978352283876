import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import { GLOBAL_SETTINGS } from "../../../redux/redux_defines";
import { globalWindowInterface, lrObjectInterface, lrWorksheetStateInterface, uuidStr } from "../../../util/callbackTypes";
import { BASE_UNIT, kWireType } from "../../../util/defines";
import UnitInput from '../../Basics/BasicUnitInput';
import { IPdfFormat } from "../../GlobalSettings/PrintSettings";
import PropertyList from "../PropertyList/PropertyList";
import type { PropertyListItemInterface } from "./FieldSearch";
import { GroupingPropertyInterface } from "./GroupByComponent";

declare const window: globalWindowInterface;
export interface TableGroupingResultInterface<T> {
    Grouping: GroupingPropertyInterface,
    GroupChildren: TableGroupingResultInterface<T>[],
    ItemChildren: T[],
    Level: number,
    maxLevel?: number
}

const LEVEL_WIDTH_INCREASE = 7.5 //mm

function SingleHeaderCell(this: unknown, {style, text}: {style: CSSProperties, text: string})
{
    const toShow = useMemo(()=>{
        if(/(%%__COLOR\(((([+-]?[0-9]*[.])?[0-9]+,){2}([+-]?[0-9]*[.])?[0-9]+;?)+\)__%%)/.test(text))
        {
            let cStr = text.slice(10, -5);
            let split = cStr.split(";")
            let subSplit = split.map(i => i.split(",").map(j => Number(j))).map(i => ({X: i[0], Y: i[1], Z: i[2]}))
            return <UnitInput
                    baseUnit={BASE_UNIT.COLOR}
                    value={{
                        Color: subSplit,
                        Name: ""
                    }}
                    readOnly
                    transparent
                    label={false}
                />
        }

        return text
    }, [text])


    return <Table.HeaderCell style={style} data-is_header_cell="true">
        {toShow}
    </Table.HeaderCell>
}

function SingleTable(this: unknown, { currLevel, tableProps, maxLevels, indent, headerWidth }: {
    currLevel: TableGroupingResultInterface<lrObjectInterface>,
    maxLevels: number,
    tableProps: any,
    headerWidth: number,
    indent?: boolean
}) {
    let lv = currLevel.Level - 1

    let incValue = indent ? LEVEL_WIDTH_INCREASE : 0
    let nHeaderW = headerWidth + incValue * (maxLevels - lv)
    let nmargin = tableProps.printMargin.Left + (incValue * (lv + 1))
    
    return <div style={{ width: "auto" }}>

        {
        (maxLevels > 0) ?
            <Table style={{ marginLeft: `${incValue * lv}mm`, width: "auto" }} celled>
                <Table.Header>
                    <Table.Row data-is_header_row="true">
                        {
                            currLevel.Grouping.PropertiesCellList.map(i =>
                                <SingleHeaderCell 
                                    style={{ width: `${nHeaderW / currLevel.Grouping.PropertiesCellList.length}mm`, paddingLeft: 5, paddingRight: 5, paddingBottom: 5, paddingTop: 5 }}
                                    text={i}
                                />
                            )
                        }
                    </Table.Row>
                </Table.Header>
            </Table>
            :
            null
        }

        {currLevel.GroupChildren.length ?
            currLevel.GroupChildren.map(i => <SingleTable
                indent={indent}
                maxLevels={maxLevels}
                currLevel={i}
                tableProps={tableProps}
                headerWidth={headerWidth}
                key={i.Grouping.GroupByProperty} />)
            :
            <div style={{ marginLeft: `${incValue * (lv + 1)}mm` }}>
                <PropertyList
                    {...{ ...tableProps, printMargin: { ...tableProps.printMargin, Left: nmargin } }}
                    objects={currLevel.ItemChildren}
                />
            </div>
        }
    </div>
}

// Doing this here because doing it in the core would require an unreasonable amount of work
function FilterPowerPorts(obj: { Resolved: lrObjectInterface, UUID: uuidStr }, allowInputs: boolean, allowOutputs: boolean) {
    let d = obj.Resolved
    if (d.ElectricalConnections && d.ElectricalConnections.length && (!allowInputs || !allowOutputs)) {
        let nArr = d.ElectricalConnections.filter(item => {
            if (item.OwnWireType === kWireType.Input) {
                return allowInputs
            } else if (item.OwnWireType === kWireType.Output) {
                return allowOutputs
            }
            return true
        })
        if (nArr.length !== d.ElectricalConnections.length) {
            return { Resolved: { ...obj.Resolved, ElectricalConnections: nArr }, UUID: d.UUID }
        }
    }

    return obj
}

export default function DefaultTable(this: unknown, props: {
    worksheetState: lrWorksheetStateInterface,
    worksheetUUID: uuidStr
    propertyList: PropertyListItemInterface[],
    localizedOverwrite: boolean,
    headerData: any,
    objects: { Resolved: lrObjectInterface, UUID: uuidStr }[]
    onAddDefaultColumn: () => void
    onSelectionPropertyChanged: (...arg: any) => void,
    onUpdateWorksheetStateSortBy: (...arg: any) => void
    worksheetScale: number,
    showSummaryTable: boolean,
    pagesProps: {
        showPages: boolean,
        printMargin: {
            Bottom: number,
            Left: number,
            Right: number,
            Top: number
        },
        PdfFormat: IPdfFormat,
        pages: any
    },
    UsePageBreak: boolean
}) {
    let currentWorksheetState = props.worksheetState
    let searchFilter = currentWorksheetState.SearchFilter

    const sortedProperties = (currentWorksheetState.SortedByProperties ?? []).map(prop => ({ ident: prop.PropertyName, dir: prop.Ascending ? "ascending" : "descending" }))
    const globalSettings = useSelector<any>((state) => state[GLOBAL_SETTINGS].data.GlobalSettings)

    let objectMap = useMemo(() => {
        let ret: { [key: uuidStr]: { Resolved: lrObjectInterface, UUID: uuidStr } } = {}
        for (let it of props.objects) {
            ret[it.UUID] = FilterPowerPorts(it, props.worksheetState.ShowFilterInputs, props.worksheetState.ShowFilterOutputs)
        }
        return ret
    }, [props.objects, props.worksheetState])

    let [objectTree, setObjectTree] = useState<TableGroupingResultInterface<lrObjectInterface>[]>([])
    let [maxLevels, setMaxLevels] = useState(0)

    const recReplace = (item: TableGroupingResultInterface<uuidStr>): TableGroupingResultInterface<lrObjectInterface> => {
        return {
            ...item,                                                                //Grouping header
            ItemChildren: item.ItemChildren.map(i => objectMap[i]).filter(i => i),  // Objects on that level
            GroupChildren: item.GroupChildren.map(i => recReplace(i))               // Subgroups on that level
        }
    }

    useEffect(() => {
        window.LR_GetTableViewGroupingData({
            WorksheetState: currentWorksheetState,
            UseGrouping: !props.UsePageBreak,
            Async: true
        }).then(({ result, maxLevels: mLV }) => {
            let a = result.map(j => recReplace(j))
            setMaxLevels(mLV)
            setObjectTree(a)
        })

    }, [props.worksheetState, objectMap, props.UsePageBreak])


    let headerWidth = useMemo(()=>{
        let arrNames = props.propertyList.map(i => i.ArrayName ? `${i.PropertyName};${i.ArrayName}` : i.PropertyName)
        let list = Object.entries(props.headerData?.PropertyList ?? {}) as [string, {Width: number}][];
        let size = 0
        for(let i of list)
        {
            if(arrNames.includes(i[0])){
                size += i[1].Width
            }
        }

        let keys = list.map(i => i[0])
        for(let i of arrNames){
            if(!keys.includes(i))
            {
                size += 50
            }
        }
        return size
    }, [props.propertyList, props.headerData])

    return <>{
        objectTree.map((t, i) => {
            return <React.Fragment key={i}>
                <SingleTable
                    headerWidth={headerWidth}
                    currLevel={t}
                    maxLevels={maxLevels}
                    indent={currentWorksheetState.UseGroupingIndentation}
                    tableProps={{
                        properties: props.propertyList,
                        selectedPreset: currentWorksheetState.ActivePropertyPreset,
                        AddDefaultColum: props.onAddDefaultColumn,
                        worksheet: props.worksheetUUID,
                        LocalizedOverwrite: props.localizedOverwrite,
                        headerData: props.headerData,
                        searchFilter: searchFilter,
                        onSelectionChanged: props.onSelectionPropertyChanged,
                        onSort: props.onUpdateWorksheetStateSortBy,
                        sortedProperties: sortedProperties,
                        worksheetScale: props.worksheetScale,
                        globalSettings: globalSettings,
                        ShowSummeryTable: props.showSummaryTable,
                        printMargin: props.pagesProps.printMargin,
                        pdfFormat: props.pagesProps.PdfFormat,
                        pages: props.pagesProps.pages,
                        showPages: props.pagesProps.showPages
                    }} />
            </React.Fragment>
        })

    }</>
}