//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Tab, Dropdown, Input, Segment, Grid, Menu, Icon, Form, Header, Label, Button, Sidebar, Popup, Divider, Table} from 'semantic-ui-react';
import LocalizedStrings from "../../localization/TableViewComponent";
import LocalizedStrings_ObjectProperties from "../../localization/LightRightObjectsFields";
import { STRUCTURAL_CALCULATION, CONNECTION_VIEW, EMPTY_UUID, BASE_UNIT_LENGTH, STRUCTURE_VIEW, SUPPORT_VIEW, TRUCK_VIEW, VECTOR_MAGNITUDE_FIELD, SUMMERY_VIEW, CASE_VIEW, GEL_VIEW, Electrical_VIEW, RACK_VIEW, INVENTORY_CONTAINER_TYPE_RACK, INVENTORY_CONTAINER_TYPE_CASE, BASE_UNIT_WEIGHT, BASE_UNIT_DATE, BASE_UNIT_ONE_BASED, BASE_UNIT_VOLUME, BASE_UNIT_VOLTAGE, OBJECT_FIELD, RACK_FIELD, COLOR_FIELD, BASE_UNIT_ANGLE, BASE_UNIT_AREA, BASE_UNIT_POWER, CASE_FIELD, BASE_UNIT_FORCE, BASE_UNIT_COLOR, BASE_UNIT_TIME_CODE, IsRunningAsBrowser, BASE_UNIT_STRING, GOBO_FIELD, NOTES_VIEW, RESPONSIVE_PHONE, BASE_UNIT_AMPERE, IsElectronContext, IsVectorworksContext, CheckWorkspace, Feet_Inch_Regex, UNIT_FeetInches} from '../../util/defines'
import { ToTimeCode } from '../../util/time';
import GraphList from './GraphList/GraphListComponent';
import ColorInputField from '../ColorPicker/ColorInputField';
import SupportView from './SupportViewComponent/SupportView';
import TruckList from './TruckList/TruckList';
import CaseList from './CaseList/CaseList';
import SummeryList from './Summery/SummeryList';
import GelList from './GelList/GelList';
import ObjectPropertiesColumnsDisplay from './ObjectProperties/ObjectPropertiesColumnsDisplay';
import UnitInput, { getCoreValueFromUnit, getUnit, getUnitFromString, getUnitToString, getUnitValueFromCore } from '../Basics/BasicUnitInput';
import TruckRenderer from './TruckList/TruckRenderer';
import SupportFilter from './SupportViewComponent/SupportFilter';
import ObjectPropertiesFilter from './ObjectProperties/ObjectPropertiesFilter';
import InventoryColumnsDisplay from './InventoryList/InventoryColumnsDisplay';
import InventoryTable from './InventoryList/InventoryTable.tsx';
import ElectricalList from './Electrical/ElectricalList';
import RackList from './RackList/RackList';
import StructuralList from './Structural/StructuralList';
import { DEFAULT_VIEW, PRESETS_VIEW, INVENTORY_VIEW,HOIST_REPORT_ANTWAYS,HOIST_REPORT_RECWAYS, HOIST_REPORT_YSORTING } from'../../util/defines';
import { connect } from 'react-redux';
import { addNotification } from '../NotificationDisplay/NotificationDisplay';
import StructureFilter from './GraphList/StructureFilter';
import NavigationContainer from '../Navigation/NavigationContainer';
import { Link } from 'react-router-dom';
import { GetTexture, HasOrLoadingTexture, LoadTextures } from '../ResourceLoadContainer/TextureLoader';
import NotesView from './NotesView/NotesView';
import UserAvatar from '../WebComponents/UserAvatar';
import MediaQuery from 'react-responsive';
import ElectricalFilter from './Electrical/ElectricalFilter';
import ConnectionView from './ConnectionView/ConnectionView';
import ConnectionViewFilter from './ConnectionView/ConnectionViewFilter';
import LRModal from '../Basics/BasicModal';
import SceneTreeHierarchy from '../SceneTreeHierarchy/SceneTreeHierarchyComponent';
import ObjectPropertiesComponent from '../ObjectProperties/ObjectPropertiesComponent';
import RenamePropertyDialog from '../RenamePropertyDialog/RenamePropertyDialog';
import LRFilterInput from '../Basics/FilterField';
import gfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'


import ReactMde from 'react-mde';
import TableToPdfModal from './TableToPdfModal';
import { QRCodeCanvas } from 'qrcode.react';

import { globalCallbacks as MainGlobal } from '../../util/callback';
import { globalCallbacks as MockGlobal } from '../../util/mock_callback';
const MainRenderer = React.lazy(()=> import('../Renderer/RendererWrapper'))
import MockRenderer from '../Renderer/RendererMock';
import { GLOBAL_SETTINGS as MainGS } from "../../redux/redux_defines";
import DefaultTable from './ObjectProperties/DefaultTable';


let globalCallbacks
let Renderer
let GLOBAL_SETTINGS

if(!process.env.JEST_WORKER_ID) 
{ 
  globalCallbacks  = MainGlobal; 
  Renderer = MainRenderer;
  GLOBAL_SETTINGS  = MainGS;
}
else                                         
{ 
  globalCallbacks  = MockGlobal; 
  Renderer = MockRenderer;
} 

/*
  {"A0", {841, 1189}},
  {"A1", {594, 841}},
  {"A2", {420, 594}},
  {"A3", {297, 420}},
  {"A4", {210, 297}},
  {"A5", {148, 210}},
  {"A6", {105, 148}},
  {"A7", {74, 105}},
  {"A8", {52, 74}},
  {"A9", {37, 52}},
  {"A10", {26, 37}},
  */


  const panes = [
    {
      menuItem: LocalizedStrings.SceneTree,
      render: () => <Tab.Pane attached={false}><SceneTreeHierarchy/></Tab.Pane>,
    },
    {
      menuItem: LocalizedStrings.ObjectProperties,
      render: () => <Tab.Pane attached={false}><ObjectPropertiesComponent/></Tab.Pane>,
    },
    {
      menuItem: LocalizedStrings.Navigation,
      render: () => <Tab.Pane attached={false}><NavigationContainer/></Tab.Pane>,
    },
  ]

let Paper = [
    {
      key: 0,
      value: 0,
      text: "A4 Vertical",
      pages: {
        width: 210, // in mm
        height: 297 // in mm
      }
    },
    {
      key: 1,
      value: 1,
      text: "A4 Horizontal",
      pages:{
        width: 297, // in mm
        height: 210 // in mm
      }
    },
    {
      key: 2,
      value: 2,
      text: "Letter Vertical",
      verticalSize:"215.9mm",
      pages:["179.9mm", "360mm"]
    },
    {
      key: 3,
      value: 3,
      text: "Letter Horizontal",
      verticalSize:"279.4mm",
      pages:["252mm", "504mm"]
    },
  ]

let k_KEY_ENTER = 13
let k_KEY_ESCAPE = 27

const hoistFilterOption = [
  {
    key: 'AntWaysSorting',
    text: LocalizedStrings.AntWaysSorting,
    value: HOIST_REPORT_ANTWAYS,
  },
  {
    key: 'RectangleWaysSorting',
    text: LocalizedStrings.RectangleWaysSorting,
    value: HOIST_REPORT_RECWAYS,
  },
  {
    key: 'YSorting',
    text: LocalizedStrings.YSorting,
    value: HOIST_REPORT_YSORTING,
  },

]

class TableView extends Component 
{
  constructor(props)
  {
    super(props);

    this.firstUuid = 0;
    this.sceneWorksheet = {Name: "Scene", UUID: this.firstUuid};
    this.callbackengine = {}

    let showRendererOnFirstLoad = true

    if(props.mobile)                                { showRendererOnFirstLoad = false; }
    if(window.IsIOS || window.IsVectorworksContext) { showRendererOnFirstLoad = false; }


    this.state = 
    {

      selectedPrintLabel:EMPTY_UUID,

      GroupByProperty: [],

      openExportPdf: false,
      openWorksheetProperties: false,
      objects     : [],
      PublicShareLink: "",
      PublicShareLinkOptions: [],
      WorksheetViewType    : DEFAULT_VIEW,

      hoistFilter: hoistFilterOption[0].value,

      selectPaperFormat: Paper[0].value,

      worksheets              : [],
      editableWorksheetIndex  : undefined,
      worksheetName           : "",

      selectWorksheetOptions      : [],
      currentWorksheetDependency  : [],
      WorksheetUsers              : [],
      propertyList_All            : [],
      propertyList_Geometry       : [],
      SavedViews:[],
      
      selectLinkedTimeLineOptions : [],
      currentLinkedTimeLine       : undefined,


      timeLineFilter: EMPTY_UUID,

      rendererHeight : document.body.clientHeight - 100,

      activePresets: [],
      inventoryTable: [],
      showAccessories: true,
    
      propertyList : [],

      selectedObjects     : [],
      firstUuid           : 0,
      ShowFirstObject     : true,

      showInventoryError: true,

      searchFilterWorksheet: "",

      worksheetCellData: {},

      sortedDirection     : undefined,
      sortedProperty      : undefined,
      inventorySortingDirection : undefined,
      inventorySortingProperty : undefined,

      structureGroupByObject : true,

      loading             : true,

      selectedTruckUuid: undefined,

      InputFieldFocus: false,
      InputFieldContent : "",

      showRenderer: showRendererOnFirstLoad,
      fullRenderer: false,
      showfilterBar: false,

      showAssemblySheet: false,
      ShowFilterConsumer: true,
      ShowFilterAssemblyGroup: true,
      ShowFilterDistributer: true,
      ShowFilterPlugBox: true,
      ShowFilterGenerator: true,
      ShowFilterFixture   : true,
      ShowFilterStructures: true,
      ShowFilterSupport   : true,
      ShowFilterGroundSupport   : true,
      ShowFilterHouseRiggingPoint   : true,
      ShowFilterAudio     : true,
      ShowFilterMeshes     : true,
      ShowFilterDockPoints     : true,
      ShowFilterSelected     : false,
      ShowFilterVisible: false,
      ShowFilterInputs: true,
      ShowFilterOutputs: true,
      ShowAccessories: false,
      ShowInventoryError: false,
      ShowSummeryTable: false,
      UseGroupingIndentation: false,

      widthMap: {},

      propertyPresets: [],
      inventoryPropertyPresets: [],

      PropertyBasedFilter: [],
      

      inventoryGroupBy: {
        PartNumber: false,
        Supplier: false
      },

      MarginBottom: 0,
      MarginTop: 0,
      MarginLeft: 0,
      MarginRight: 0,
      WorksheetScale: 1,


      editMode: 0,
      PrintPreview: true,
      UsePageBreak: false
    }

    this.objects            = [];
    this.allObjects         = [];
    this.selectedObjects    = [];
    this.selectedCells      = [];
    this.isSelecting        = false;     
    this.selectedProperty   = undefined;   //The selected Property (zb "OffsetX")
    this.selectedType       = undefined;   //Whether the selection is for the resolved, preset or default
    this.selectedPreset     = undefined;   //The selected preset index in the array of the objects
    this.selectMode         = true;        //Whether we are selecting the next columns or deselecting
    this.lastSelectedIndex  = 0;           //Kind of cursor on what place the cursor was last frame
    this.selectDirection    = 0;           //The direction the mouse moves. Needs to be saved, to enable the deselection when dragging the cursor back to the origin the drag started
    this.searchFilter       = "";

    this.inputRef           = React.createRef();
    this.tableRef           = React.createRef();

    //
    this.ShowFilterAssemblyGroup     = true;
    this.ShowFilterConsumer     = true;
    this.ShowFilterDistributer  = true;
    this.ShowFilterPlugBox      = true;
    this.ShowFilterGenerator    = true;
    this.ShowFilterFixture      = true;
    this.ShowFilterStructures   = true;
    this.ShowFilterSupport      = true;
    this.ShowFilterGroundSupport      = true;
    this.ShowFilterHouseRiggingPoint      = true;
    this.ShowFilterAudio        = true;
    this.ShowFilterMeshes       = true;
    this.ShowFilterDockPoints       = true;
    this.ShowFilterVisible      = false;
    this.ShowFilterInputs      = false;
    this.ShowFilterOutputs      = false;
    this.ShowFilterSelected      = false;
    this.ShowInventoryError      = false;
    this.ShowAccessories      = false;
    this.ShowSummeryTable = false;
    this.UseGroupingIndentation = false;

    this.ShowElectrical     = DEFAULT_VIEW;

    this.ExtraEnabledProperties = [];
    this.InventorySummeryProps = [];
    this.PropertyBasedFilter = []

    this.drawingWorksheetState = {
      0: {
        ExtraEnabledProperties: [{PropertyName: "Name"}]
      },
      1: {
        ExtraEnabledProperties: [{PropertyName: "Name"}]
      },
      2: {
        ExtraEnabledProperties: [{PropertyName: "Name"}]
      },
      3: {
        ExtraEnabledProperties: [{PropertyName: "Name"}]
      },
      4: {
        ExtraEnabledProperties: [{PropertyName: "Name"}]
      },
      5: {
        ExtraEnabledProperties: [{PropertyName: "Name"}]
      },
      6: {
        ExtraEnabledProperties: [{PropertyName: "Name"}]
      },
      7: {
        ExtraEnabledProperties: [{PropertyName: "Name"}]
      },
      8: {
        ExtraEnabledProperties: [{PropertyName: "Name"}]
      },
    }    


    window.addEventListener('keydown', this.onWindowKeyDown);
  }

  // The Callback Engine wraps up the different functions call, when you have this componentet mounted more than one time
  // So each component has is own state. But this does not listen to changes than
  getCallEngine = () =>
  {
    if(this.props.markdownRenderer) {return this.callbackengine; }
    return globalCallbacks
  }

  

  componentDidMount = async () => 
  {
    let func_CustomCellFunction = (entry) =>
    {
      switch(entry.CustomCellFunction)
      {
        //noinspection FallthroughInSwitchStatementJS
        case BASE_UNIT_LENGTH    :
        case BASE_UNIT_WEIGHT    :
        case BASE_UNIT_VOLUME    :
        case BASE_UNIT_AREA      :
        case BASE_UNIT_ONE_BASED :
        case BASE_UNIT_DATE      :
        case BASE_UNIT_ANGLE     :
        case BASE_UNIT_VOLTAGE   :
        case BASE_UNIT_AMPERE    :
        case BASE_UNIT_COLOR     :
        case BASE_UNIT_FORCE     :
        case BASE_UNIT_TIME_CODE :
        case BASE_UNIT_POWER     : return (propertyName, item) => this.renderUnitInput(entry.CustomCellFunction, propertyName, item);
        case OBJECT_FIELD        : return this.renderObjectField;
        case RACK_FIELD          : return this.renderRackField;
        case CASE_FIELD          : return this.renderCaseField;
        case COLOR_FIELD         : return this.renderColorField;
        case VECTOR_MAGNITUDE_FIELD: return this.renderVectorMagnitude;
        case GOBO_FIELD          : return this.renderGoboField;
        default                  : return undefined;
      }
    }

    let func_CustomUnitCellFunction = (entry) =>
    {
      if (entry.BaseUnit === BASE_UNIT_STRING) { return undefined }
      return (propertyName, item) => this.renderUnitInput(entry.BaseUnit, propertyName, item)
    }

    let func_CustomFilter = (entry) =>
    {
      switch(entry.CustomFilter)
      {
        
        case 1: return (obj) => { if(!obj.Resolved.LinkedFixtureTypeObject?.DmxFootPrint1) { return false; } return Object.entries(obj.Resolved.LinkedFixtureTypeObject.DmxFootPrint1).length !== 0 };
        case 2: return (obj) => { if(!obj.Resolved.LinkedFixtureTypeObject?.DmxFootPrint2) { return false; } return Object.entries(obj.Resolved.LinkedFixtureTypeObject.DmxFootPrint2).length !== 0 };
        case 3: return (obj) => { if(!obj.Resolved.LinkedFixtureTypeObject?.DmxFootPrint3) { return false; } return Object.entries(obj.Resolved.LinkedFixtureTypeObject.DmxFootPrint3).length !== 0 };
        case 4: return (obj) => { if(!obj.Resolved.LinkedFixtureTypeObject?.DmxFootPrint4) { return false; } return Object.entries(obj.Resolved.LinkedFixtureTypeObject.DmxFootPrint4).length !== 0 };
        
        default:return undefined;
      }
    }

    let possibleFields          = await window.LR_GetPossibleFields()
    let possibleFieldsGeometry  = await window.LR_GetPossibleFieldsGeometry()
    let LocalizedOverwrite  = await window.LR_GetPropertyLocalizedOverwrite()

    let getLocalizedNameForProp = (prop) =>
    {
      if (!prop.ArrayName) { return LocalizedStrings_ObjectProperties[prop.PropertyName]}
      
      if (LocalizedStrings_ObjectProperties[prop.ArrayName + "_" + prop.PropertyName])
      {
        return LocalizedStrings_ObjectProperties[prop.ArrayName + "_" + prop.PropertyName]
      }

      let loc = LocalizedStrings_ObjectProperties[prop.PropertyName]
      if(loc)
      {
        return loc
      }


      return prop.PropertyName
    }

    let propertyList_All = possibleFields.map( entry => 
      {
        return {
          LocalizedName: getLocalizedNameForProp(entry),
          PropertyList: entry.PropertyList,
          PropertyName: entry.PropertyName,
          IfInArrayWithName: entry.IfInArrayWithName,
          ArrayName: entry.ArrayName ? entry.ArrayName : undefined,
          LinkedPreset: entry.LinkedPreset ? entry.LinkedPreset : undefined,
          IsUnitBased: entry.IsUnitBased ? entry.IsUnitBased : undefined,
          CustomCellFunction: entry.IsUnitBased ? func_CustomUnitCellFunction(entry) : func_CustomCellFunction(entry),
          CustomCellFunctionType: entry.CustomCellFunction,
          BaseUnit: entry.BaseUnit,
          CustomFilter: func_CustomFilter(entry),
        }
      })

    let propertyList_Geometry = possibleFieldsGeometry.map( entry =>
      {
        return {
          LocalizedName: getLocalizedNameForProp(entry),
          PropertyList: entry.PropertyList,
          PropertyName: entry.PropertyName,
          IfInArrayWithName: entry.IfInArrayWithName,
          ArrayName: entry.ArrayName ? entry.ArrayName : undefined,
          LinkedPreset: entry.LinkedPreset ? entry.LinkedPreset : undefined,
          IsUnitBased: entry.IsUnitBased ? entry.IsUnitBased : undefined,
          CustomCellFunction: entry.IsUnitBased ? func_CustomUnitCellFunction(entry) : func_CustomCellFunction(entry),
          CustomCellFunctionType: entry.CustomCellFunction,
          BaseUnit: entry.BaseUnit,
          CustomFilter: func_CustomFilter(entry),
        }
      })

    this.setState({propertyList_All: propertyList_All, propertyList_Geometry: propertyList_Geometry, LocalizedOverwrite})
  
    //Populate start property list
    this.setUpCallbacks();
    await this.getCallEngine().getWorksheets();
    await this.getCallEngine().getUserOverviewTableView();
    await this.getCallEngine().getSavedViewsWorksheet();
    await this.getCallEngine().getTimeLines();
    await this.getCallEngine().updatePropertyPresets();
    await this.getCallEngine().drawingChanged();
    await this.updateRendererHeight();
    window.addEventListener("resize", this.updateRendererHeight);
    
    let forcedWorksheet = await window.LR_GetForcedWorksheet()
    if(forcedWorksheet.UUID === undefined)
    {
      await this.getCallEngine().getAllProperties();
    }
    

    let settings = await window.LR_GetGlobalSettings()
    let editMode = await window.LR_GetGeometryEditMode()
    if (this.getCallEngine().TableViewUpdateGlobalSettings) { await  this.getCallEngine().TableViewUpdateGlobalSettings(settings.GlobalSettings) }
    if (this.getCallEngine().worksheetEditMode)
    {
      if (editMode.EditMode)  { await this.getCallEngine().worksheetEditMode(editMode.EditMode) }
    }
    
    if(this.props.UUID && this.props.UUID !== this.state.firstUuid)
    {
      await this.onWorksheetSingleClick(this.props.UUID)
    }

  }

  componentWillUnmount = () => 
  {
    window.removeEventListener("resize", this.updateRendererHeight);
  }

  updateRendererHeight = () =>
  {
    // 100 is an arbitrary value to see the whole renderer and not have a useless scrollbar.
    this.setState({rendererHeight: document.body.clientHeight - 100});
  }

  updatePropertyList = (view) =>
  {
    let result = []
    if (!this.drawingWorksheetState) { return; }
    
    let container = this.getCurrentWorksheetState(view)

    if (container)
    {
      result = container.ExtraEnabledProperties.map(prop => this.getCompletePropertyInfo(prop))
    }
    else
    {
      result.push(this.getCompletePropertyInfo({PropertyName: "Name"}))
    }
    this.setState({
      propertyList: result,
      GroupByProperty: container.GroupByProperty
    }, () => {this.filterObjects(this.allObjects).then(filteredObjects => {this.setState({objects: filteredObjects})})});
  }

  getAllProperties = () =>
  {
    return this.state.editMode ? this.state.propertyList_Geometry : this.state.propertyList_All
  }

  getCurrentWorksheet = (uuid) =>
  {
    let result = undefined
    for (let i = 0; i < this.state.worksheets.length; i++)
    {
      let element = this.state.worksheets[i]
      if (element.UUID === uuid) 
      {
        result = element
        break
      }
    }
    return result
  }

  render() 
  {
    if(this.props.markdownRenderer)
    {
      return this.DefaultTable()
    }



    if(this.props.renderOnly)
    {
      return<div style={{height: window.Android ? "calc(100vh - 7em)" : "calc(100vh - 10em)"}}>{this.getCurrentRenderer()}</div> 
    }
    
    let h = ""
    if(this.props.electron){
      h = "calc(100vh)"
    }else if(this.props.webEdit){
      h = "100%"
    }else if(this.props.iPad){
      h = "calc(100vh - 9em)"
    }else if(window.Android){
      h = "calc(100vh - 7em)"
    }else{
      h = "calc(100vh - 10em)"
    }

    return (
      <div style={{height: h}}>
        {this.renderContent()}
        <RenamePropertyDialog/>
      </div>
    );
  }

  renderSearchBar = () => 
  {
    return( 
      <MediaQuery  minWidth={RESPONSIVE_PHONE}>
        {(isMoblie) => isMoblie ? this.renderSearchBar_Desktop() : this.renderSearchBar_Mobile() }
      </MediaQuery>
      )
  }

  renderSearchBar_Desktop = () => 
  { 
    return(
            <Grid>
              <Grid.Column width="12">
                {this.renderColumnsDisplay()}
              </Grid.Column>
              <Grid.Column width="4">
                {this.renderSearchField()}
              </Grid.Column>
            </Grid>
    )
  }
  renderSearchBar_Mobile = () => 
  {
    return(
    <>
    <Button fluid primary onClick={()=>this.setState({showfilterBar: !this.state.showfilterBar})}>{LocalizedStrings.CloseFilter}</Button>
    <Segment.Group>
      {this.renderColumnsDisplay()}
      {this.renderSearchField()}
      { this.firstUuid !== 0 ?  this.renderWorksheetData(): undefined }
      </Segment.Group>
    </>
    )
  }

  renderSearchField = () =>
  {
    let value = this.getCurrentWorksheetState().SearchFilter

    switch(this.state.WorksheetViewType)
    {
      case SUMMERY_VIEW: return null
      case GEL_VIEW: return null
      case NOTES_VIEW: return null
      case CONNECTION_VIEW: return <ConnectionViewFilter
                                                      value={value}
                                                      searchFieldUpdate={this.searchFieldUpdate}
                                                      toggleShowProperties  = {this.toogleFilterProperty}
                                                      ShowFilterConsumer    = {this.state.ShowFilterConsumer}
                                                      ShowFilterAssemblyGroup    = {this.state.ShowFilterAssemblyGroup}
                                                      ShowFilterDistributer = {this.state.ShowFilterDistributer}
                                                      ShowFilterPlugBox = {this.state.ShowFilterPlugBox}
                                                      ShowFilterGenerator   = {this.state.ShowFilterGenerator}
                                                      ShowFilterFixture     = {this.state.ShowFilterFixture}/>
      case Electrical_VIEW: return <ElectricalFilter  value={value}
                                                      searchFieldUpdate={this.searchFieldUpdate}
                                                      toggleShowProperties  = {this.toogleFilterProperty}
                                                      ShowFilterConsumer    = {this.state.ShowFilterConsumer}
                                                      ShowFilterAssemblyGroup    = {this.state.ShowFilterAssemblyGroup}
                                                      ShowFilterDistributer = {this.state.ShowFilterDistributer}
                                                      ShowFilterPlugBox = {this.state.ShowFilterPlugBox}
                                                      ShowFilterGenerator   = {this.state.ShowFilterGenerator}
                                                      ShowFilterFixture     = {this.state.ShowFilterFixture} />
      case STRUCTURE_VIEW: return <StructureFilter      structureGroupByObject={this.state.structureGroupByObject}
                                                        onGroupByChanged={(val) => {this.setState({structureGroupByObject: val})}}/>
      case SUPPORT_VIEW: return <SupportFilter          value={value} 
                                                        searchFieldUpdate={this.searchFieldUpdate} 
                                                        hoistFilterChange={(_ , {value}) => { this.updateHoistFilter(value); }} 
                                                        hoistFilter={this.state.hoistFilter} 
                                                        hoistFilterOption={hoistFilterOption} 
                                                        />
      
      default:           return <ObjectPropertiesFilter value={value} 
                                                        fields={this.getAllProperties()}
                                                        propertyBasedFilter={this.state.PropertyBasedFilter}
                                                        onPropertyBasedFilterChange={this.updatePropertyBasedFilter}
                                                        searchFieldUpdate={this.searchFieldUpdate} 
                                                        toggleAllShowProperties = {this.toggleAllShowProperties}
                                                        toggleShowProperties  = {this.toogleFilterProperty}
                                                        ShowFilterConsumer    = {this.state.ShowFilterConsumer}
                                                        ShowFilterAssemblyGroup    = {this.state.ShowFilterAssemblyGroup}
                                                        ShowFilterDistributer = {this.state.ShowFilterDistributer}
                                                        ShowFilterPlugBox = {this.state.ShowFilterPlugBox}
                                                        ShowFilterGenerator   = {this.state.ShowFilterGenerator}
                                                        ShowFilterFixture     = {this.state.ShowFilterFixture}
                                                        ShowFilterStructures  = {this.state.ShowFilterStructures}
                                                        ShowFilterSupport     = {this.state.ShowFilterSupport}
                                                        ShowFilterGroundSupport     = {this.state.ShowFilterGroundSupport}
                                                        ShowFilterHouseRiggingPoint     = {this.state.ShowFilterHouseRiggingPoint}
                                                        ShowFilterAudio       = {this.state.ShowFilterAudio}
                                                        ShowFilterMeshes      = {this.state.ShowFilterMeshes}
                                                        ShowFilterDockPoints      = {this.state.ShowFilterDockPoints}
                                                        ShowFilterVisible     = {this.state.ShowFilterVisible}
                                                        ShowFilterInputs      = {this.state.ShowFilterInputs}
                                                        ShowFilterOutputs     = {this.state.ShowFilterOutputs}
                                                        ShowFilterSelected    = {this.state.ShowFilterSelected}
                                                        showInputOutput       = {this.state.showInputOutput}
                                                        isInEditMode          = {!!this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW}/>
    }
  }
  renderColumnsDisplay = () =>
  {
    let container = this.firstUuid !== 0 ? this.getCurrentWorksheet(this.firstUuid).WorksheetState : this.drawingWorksheetState;
    if (!container) { return null; }

    let propertyWorksheetState = this.getCurrentWorksheetState(DEFAULT_VIEW)
    let inventoryWorksheetState = this.getCurrentWorksheetState(INVENTORY_VIEW)

    switch(this.state.WorksheetViewType)
    {
      case CASE_VIEW:     return null
      case RACK_VIEW:     return null
      case TRUCK_VIEW:    return null
      case GEL_VIEW:      return null
      case SUMMERY_VIEW:  return null
      case SUPPORT_VIEW:  return null
      case STRUCTURE_VIEW:return null
      case Electrical_VIEW:return null
      case CONNECTION_VIEW:return  null
      case NOTES_VIEW:     return null
      case INVENTORY_VIEW:  return <InventoryColumnsDisplay         key="1"     
                                                                    PresetOptions        = {this.state.inventoryPropertyPresets}
                                                                    inventoryWorksheetState = {inventoryWorksheetState}
                                                                    isInEditMode = {!!this.state.editMode}
                                                                    propertyList={this.state.propertyList_All}
                                                                    onPropertyPresetChanged={this.propertyPresetChanged(INVENTORY_VIEW)}
                                                                    onGroupByPropertyChanged={this.GroupByPropertyChanged}
                                                                    onGroupByPropertyDeleted={this.GroupByPropertyDeleted}
                                                                    changeShowSummeryChange = {this.changeWorksheetValue("ShowSummeryTable")}
                                                                    changeUseGroupingIndentation = {this.changeWorksheetValue("UseGroupingIndentation")}
                                                                    currentWorksheetSettings={this.state}
                                                                    toggleShowProperties={this.toggleShowProperties}
                                                                    toggleShowInventorySummary={this.toggleShowInventorySummary}
                                                                    changeAccessoriesChanged={this.changeWorksheetValue("ShowAccessories")}
                                                                    changeInventoryErrorChanged={this.changeWorksheetValue("ShowInventoryError")}
                                                                    />
      default:              return <ObjectPropertiesColumnsDisplay  key="2"  
                                                                    ShowFilterConsumer = {this.state.ShowFilterConsumer}
                                                                    ShowFilterDistributer = {this.state.ShowFilterDistributer}
                                                                    ShowFilterPlugBox = {this.state.ShowFilterPlugBox}
                                                                    ShowFilterAssemblyGroup = {this.state.ShowFilterAssemblyGroup}
                                                                    ShowFilterGenerator = {this.state.ShowFilterGenerator}
                                                                    ShowFilterFixture    = {this.state.ShowFilterFixture}
                                                                    ShowFilterStructures = {this.state.ShowFilterStructures}
                                                                    ShowFilterSupport    = {this.state.ShowFilterSupport}
                                                                    ShowFilterHouseRiggingPoint    = {this.state.ShowFilterHouseRiggingPoint}
                                                                    ShowFilterGroundSupport    = {this.state.ShowFilterGroundSupport}
                                                                    ShowFilterAudio      = {this.state.ShowFilterAudio}
                                                                    ShowFilterMeshes     = {this.state.ShowFilterMeshes}
                                                                    ShowFilterDockPoints     = {this.state.ShowFilterDockPoints}
                                                                    ShowFilterVisible    = {this.state.ShowFilterVisible}
                                                                    ShowFilterInputs      = {this.state.ShowFilterInputs}
                                                                    ShowFilterOutputs     = {this.state.ShowFilterOutputs}
                                                                    ShowFilterSelected    = {this.state.ShowFilterSelected}
                                                                    PresetOptions        = {this.state.propertyPresets}
                                                                    SelectedPreset       = {propertyWorksheetState.ActivePropertyPreset}
                                                                    GroupByProperty = {this.state.GroupByProperty}
                                                                    onGroupByPropertyChanged={this.GroupByPropertyChanged}
                                                                    onGroupByPropertyDeleted={this.GroupByPropertyDeleted}
                                                                    propertyList={this.getAllProperties()}
                                                                    enabledProperties={propertyWorksheetState.ExtraEnabledProperties}
                                                                    toggleShowProperties={this.toggleShowProperties} 
                                                                    onPropertyPresetChanged={this.propertyPresetChanged(DEFAULT_VIEW)}
                                                                    propertyBasedFilter={this.state.PropertyBasedFilter}
                                                                    ShowSummeryTable  = {this.state.ShowSummeryTable}
                                                                    showSummeryChange = {this.changeWorksheetValue("ShowSummeryTable")}
                                                                    isInEditMode      = {!!this.state.editMode}
                                                                    UseGroupingIndentation = {this.state.UseGroupingIndentation}
                                                                    changeUseGroupingIndentation = {this.changeWorksheetValue("UseGroupingIndentation")}
                                                                    />
    }
  }

  GroupByPropertyDeleted = (idx) => {
    this.GroupByPropertyChanged(idx, undefined)
  }

  GroupByPropertyChanged = (idx, value) =>
  {
    let newGR = this.state.GroupByProperty
    if(!value){
      newGR.splice(idx, 1)
    }else{
      newGR[idx] = value
    }

    this.changeWorksheetValue("GroupByProperty")(newGR)
  }

  changeWorksheetValue = (property) => (checked) => {
    let currentWorksheetState = this.getCurrentCompleteWorksheetState()

    this.setState({[property]: checked})
    let newWorksheetState = {
      ...currentWorksheetState,
      [this.state.WorksheetViewType]: {
        ...currentWorksheetState[this.state.WorksheetViewType],
        [property]: checked
      }
    }

    if (this.firstUuid !== 0)
    {
      window.LR_SetWorksheet({UUID: this.firstUuid, WorksheetState: newWorksheetState})
    }
    else if (this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW)
    {
      window.LR_SetDrawingWorksheetState({EditModeWorksheetState: newWorksheetState[DEFAULT_VIEW]})
    }
    else
    {
      window.LR_SetDrawingWorksheetState({WorksheetState: newWorksheetState})
    } 
  }



  renderViewSelection = () =>
  {
    let workspace = CheckWorkspace()
    return (
      <Menu style={{marginTop:"1em", overflow: "visible"}} size="mini">
        <Menu.Menu style={{overflow: "auto"}}>
          <Menu.Item position="left"> 
            <Popup trigger={<Icon style={{cursor: "pointer"}} name={this.state.showAssemblySheet ? "file alternate" : "file alternate outline"} onClick  = {()=> {this.setState({showAssemblySheet: !this.state.showAssemblySheet})}}/>}>{LocalizedStrings.ToggleAssemblySheet}</Popup>    
          </Menu.Item>
            {!this.state.showfilterBar ? this.renderButtonsExport(): null}
          
          {workspace?.Worksheet.Properties ? 
                                          <Menu.Item name     = {LocalizedStrings.View_Default}
                                                     active   = {this.state.WorksheetViewType === DEFAULT_VIEW}
                                                     onClick  = {()=> {this.changeView(DEFAULT_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.Inventory ?
                                          <Menu.Item name     = {LocalizedStrings.View_Inventory}
                                                    active   = {this.state.WorksheetViewType === INVENTORY_VIEW}
                                                    onClick  = {()=> {this.changeView(INVENTORY_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.Structures ? 
                                          <Menu.Item name     = {LocalizedStrings.View_Structure}
                                                     active   = {this.state.WorksheetViewType === STRUCTURE_VIEW}
                                                     onClick  = {()=> {this.changeView(STRUCTURE_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.Supports ? 
                                          <Menu.Item name     = {LocalizedStrings.Support_Structure}
                                                     active   = {this.state.WorksheetViewType === SUPPORT_VIEW}
                                                     onClick  = {()=> {this.changeView(SUPPORT_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.Trucks ? 
                                          <Menu.Item name     = {LocalizedStrings.View_TruckList}
                                                    active   = {this.state.WorksheetViewType === TRUCK_VIEW}
                                                    onClick  = {()=> {this.changeView(TRUCK_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.Cases ? 
                                          <Menu.Item name     = {LocalizedStrings.View_CaseList}
                                                    active   = {this.state.WorksheetViewType === CASE_VIEW}
                                                    onClick  = {()=> {this.changeView(CASE_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.Racks ? 
                                          <Menu.Item name     = {LocalizedStrings.View_RackList}
                                                    active   = {this.state.WorksheetViewType === RACK_VIEW}
                                                    onClick  = {()=> {this.changeView(RACK_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.Gels  ?  
                                          <Menu.Item name     = {LocalizedStrings.View_GelList}
                                                    active   = {this.state.WorksheetViewType === GEL_VIEW}
                                                    onClick  = {()=> {this.changeView(GEL_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.ElectricalOverview  ?
                                          <Menu.Item name     = {LocalizedStrings.View_Electical}
                                                      active   = {this.state.WorksheetViewType === Electrical_VIEW}
                                                      onClick  = {()=> {this.changeView(Electrical_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.Connections  ? 
                                          <Menu.Item name     = {LocalizedStrings.View_Connections}
                                                     active   = {this.state.WorksheetViewType === CONNECTION_VIEW}
                                                     onClick  = {()=> {this.changeView(CONNECTION_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.Notes  ?
                                          <Menu.Item name     = {LocalizedStrings.View_Notes}
                                                    active   = {this.state.WorksheetViewType === NOTES_VIEW}
                                                    onClick  = {()=> {this.changeView(NOTES_VIEW)}}
                                          /> : null}
          {workspace.Worksheet.Structural  ?
                                          <Menu.Item name     = {LocalizedStrings.View_Static}
                                                   active   = {this.state.WorksheetViewType === STRUCTURAL_CALCULATION}
                                                   onClick  = {()=> {this.changeView(STRUCTURAL_CALCULATION)}}
                                          /> : null}
          {workspace.Worksheet.Overview  ?
                                          <Menu.Item name     = {LocalizedStrings.View_Overview}
                                                     active   = {this.state.WorksheetViewType === SUMMERY_VIEW}
                                                     onClick  = {()=> {this.changeView(SUMMERY_VIEW)}}
                                          />: null}
        </Menu.Menu>
      <Menu.Item position="right">
                    {!this.state.showfilterBar ? this.renderMenuPresetSelection(): null}
                    <Popup trigger={<Icon style={{cursor: "pointer"}} name={this.state.showfilterBar ? "filter" : "filter"} onClick  = {()=> {this.setState({showfilterBar: !this.state.showfilterBar})}}/>}>{LocalizedStrings.ToggleFilter}</Popup>
                    {this.props.mobile ? null : <Popup trigger={<Icon style={{cursor: "pointer"}} name={this.state.showRenderer ? "eye" : "eye slash"} onClick  = {()=> {this.setState({showRenderer: !this.state.showRenderer})}}/>}>{LocalizedStrings.ToggleRenderer}</Popup> }
          </Menu.Item>
      </Menu>
    )
  }

  renderButtonsExport()
  {
    return    <Menu.Item position="left"> 
    <Button.Group vertical>

    <Button size='small' fluid compact positive onClick={this.openExportPdf}>{LocalizedStrings.ExportPdf}</Button>
    <Button size='small' fluid compact positive onClick={this.exportToCsv} style={{marginTop:"0.15rem"}}>{LocalizedStrings.ExportCsv}</Button>                                          
    { IsVectorworksContext() ? <Button fluid size='small' compact positive onClick={this.exportToWorksheet}>{LocalizedStrings.ExportWorksheet}</Button> : null} 
</Button.Group>
</Menu.Item>
  }

  renderMenuPresetSelection()
  {
    let onChange = (_, {value, type}) => {
      if (type === "Inventory") {
        window.LR_GetInventoryPropertyTemplate({Name: value}).then(res => {
          this.propertyPresetChanged(INVENTORY_VIEW)(res.PropertyTemplate)
        })
      } else if (type === "Property") {
        window.LR_GetPropertyTemplate({Name: value}).then(res => {
          this.propertyPresetChanged(DEFAULT_VIEW)(res.PropertyTemplate)
        })
      }
    }


    let propertyWorksheetState = this.getCurrentWorksheetState(DEFAULT_VIEW)
    let inventoryWorksheetState = this.getCurrentWorksheetState(INVENTORY_VIEW)

    switch(this.state.WorksheetViewType)
    {
      case CASE_VIEW:     return null
      case RACK_VIEW:     return null
      case TRUCK_VIEW:    return null
      case GEL_VIEW:      return null
      case SUMMERY_VIEW:  return null
      case SUPPORT_VIEW:  return null
      case STRUCTURE_VIEW:return null
      case INVENTORY_VIEW:return <Form.Dropdown search={(options, search) => options.filter(opt => opt.key.toLowerCase().includes(search.toLowerCase()))} button type="Inventory" options={this.state.inventoryPropertyPresets} value={inventoryWorksheetState.ActivePropertyPreset} onChange={onChange}/>
      default:            return <Form.Dropdown search={(options, search) => options.filter(opt => opt.key.toLowerCase().includes(search.toLowerCase()))} button type="Property" options={this.state.propertyPresets} value={propertyWorksheetState.ActivePropertyPreset} onChange={onChange}/>
    }
  }

  getPropertyIdentifier(prop)
  {
    if(!prop)
    {
      console.error("Faile to get property")
      return "Empty"
    }
    if (prop.ArrayName)
    {
      return prop.PropertyName + "_" + prop.ArrayName
    }
    return prop.PropertyName
  }

  getTableForCurrentWorksheet = (showPages = false, tableRef=undefined) =>
  {
    let currentWorksheet = this.getCurrentWorksheet(this.state.firstUuid);

    let inventoryWorksheetState = this.getCurrentWorksheetState(INVENTORY_VIEW)
    let currentWorksheetState = this.getCurrentWorksheetState()
    let searchFilter = currentWorksheetState.SearchFilter

    switch(this.state.WorksheetViewType)
    {
      case PRESETS_VIEW:    { return <></>;  }
      case INVENTORY_VIEW:  { return <InventoryTable worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} searchFilter={searchFilter} pages={Paper[this.state.selectPaperFormat].pages} headerData={this.state.worksheetCellData} worksheet={this.state.firstUuid} propertyList={this.state.propertyList} objects={this.state.objects} inventoryWorksheetState={inventoryWorksheetState} selectedPreset={inventoryWorksheetState.ActivePropertyPreset} onSelectionChanged={this.onSelectionPropertyChanged} showAccessories={this.state.ShowAccessories} showInventoryError={this.state.ShowInventoryError} ShowSummeryTable={this.state.ShowSummeryTable}/>;  }
      case STRUCTURE_VIEW:  { return <GraphList ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} searchFilter={searchFilter} pages={Paper[this.state.selectPaperFormat].pages} headerData={this.state.worksheetCellData} worksheet={this.state.firstUuid} rootObject={currentWorksheet ? currentWorksheet.LinkedObjectObject.UUID : 0} structureGroupByObject={this.state.structureGroupByObject}/>; }
      case SUPPORT_VIEW:    { return <SupportView ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} searchFilter={searchFilter} pages={Paper[this.state.selectPaperFormat].pages} headerData={this.state.worksheetCellData} worksheet={this.state.firstUuid} sorting={this.state.hoistFilter} origin={this.state.originsFilter} rootObject={currentWorksheet ? currentWorksheet.LinkedObjectObject.UUID : 0}/>; }
      case TRUCK_VIEW:      { return <TruckList ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} searchFilter={searchFilter} pages={Paper[this.state.selectPaperFormat].pages} headerData={this.state.worksheetCellData} worksheet={this.state.firstUuid} rootObject={currentWorksheet ? currentWorksheet.LinkedObjectObject.UUID : 0} objects={this.state.objects} onTruckChanged={(truckUuid) => { this.setState({selectedTruckUuid: truckUuid}) }}/>; }
      case CASE_VIEW:       { return <CaseList ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} searchFilter={searchFilter} pages={Paper[this.state.selectPaperFormat].pages} headerData={this.state.worksheetCellData} worksheet={this.state.firstUuid} rootObject={currentWorksheet ? currentWorksheet.LinkedObjectObject.UUID : 0} objects={this.state.objects} onTruckChanged={(truckUuid) => { this.setState({selectedTruckUuid: truckUuid}) }}/>; }
      case GEL_VIEW:        { return <GelList ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} searchFilter={searchFilter} pages={Paper[this.state.selectPaperFormat].pages} headerData={this.state.worksheetCellData} worksheet={this.state.firstUuid} rootObject={currentWorksheet ? currentWorksheet.LinkedObjectObject.UUID : 0} objects={this.state.objects}/>; }
      case Electrical_VIEW: { return <ElectricalList ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} searchFilter={searchFilter} pages={Paper[this.state.selectPaperFormat].pages} headerData={this.state.worksheetCellData} worksheet={this.state.firstUuid} rootObject={currentWorksheet ? currentWorksheet.LinkedObjectObject.UUID : 0} objects={this.state.objects}/>; }
      case RACK_VIEW:       { return <RackList ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} searchFilter={searchFilter} pages={Paper[this.state.selectPaperFormat].pages} headerData={this.state.worksheetCellData} worksheet={this.state.firstUuid} rootObject={currentWorksheet ? currentWorksheet.LinkedObjectObject.UUID : 0} objects={this.state.objects}/>; }
      case SUMMERY_VIEW:    { return <SummeryList ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} searchFilter={searchFilter}  pages={Paper[this.state.selectPaperFormat].pages}headerData={this.state.worksheetCellData} worksheet={this.state.firstUuid} rootObject={currentWorksheet ? currentWorksheet.LinkedObjectObject.UUID : 0} objects={this.state.objects}/>; }
      case NOTES_VIEW:      { return <NotesView ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} worksheet={this.state.firstUuid}/>; }
      case STRUCTURAL_CALCULATION:      { return <StructuralList ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} worksheet={this.state.firstUuid}/>; }
      case CONNECTION_VIEW: { return <ConnectionView ref={tableRef} worksheetScale={this.state.WorksheetScale} printMargin={{Bottom: this.state.MarginBottom, Left: this.state.MarginLeft, Right: this.state.MarginRight, Top: this.state.MarginTop}} showPages={showPages} searchFilter={searchFilter} worksheet={this.state.firstUuid} pages={Paper[this.state.selectPaperFormat].pages}headerData={this.state.worksheetCellData} objects={this.state.objects}/>; }

      default:              { return this.DefaultTable(showPages); }
    }
  }

  renderContent = () =>
  {
    let table = this.getTableForCurrentWorksheet(false, this.tableRef);

    
    
    let gridTemplateColumns  = this.state.showRenderer ? (this.state.fullRenderer ? "1em 100fr" : "10fr 65fr 1em 25fr") : "10fr 65fr";

    if(!this.state.showAssemblySheet)
    {
      gridTemplateColumns = "0 65fr 1em 25fr"
    }

    if(!this.state.showRenderer)
    {
      gridTemplateColumns = "auto 65fr"
    }

    let gridTemplateRows     = "auto auto 80fr";
    const showCmdLine = [INVENTORY_VIEW, DEFAULT_VIEW].includes(this.state.WorksheetViewType)

    if(this.props.mobile)
    {
      return(<>
              {this.renderViewSelection()} 
              {this.state.showfilterBar ? this.renderSearchBar() : null} 
              <Button.Group widths="3" compact>
                <Button onClick={()=>{window.LR_ShowCreateDrawingNote({UUID: this.firstUuid})}} >{LocalizedStrings.AddNote}</Button>
                <Button onClick={()=>{window.LR_CommitToServer({})}} >{LocalizedStrings.CommitToProject}</Button>
                <Button onClick={()=>{window.LR_CreateBranchAsync({UUID: this.firstUuid})}} >{LocalizedStrings.CreateBranch}</Button>
              </Button.Group>
              {this.state.showRenderer  ? this.getCurrentRenderer() : 
              <>
              {showCmdLine && this.renderInputField()}

              <div style={{overflow:"auto"}} >
              <div>

                {this.renderTable(table)}
              </div>
              </div>
              </>
            }
      </>)
    }
    


    const iconStyle = {position: 'absolute', top: 'calc(50% - 7px)'}

    let propertyOption = this.getAllProperties().map(prop => ({value: this.getPropertyIdentifier(prop), text: prop.LocalizedName, key: this.getPropertyIdentifier(prop)}))

    // only show command line for tables that implement onSelectionChanged prop
    let worksheet = this.getCurrentWorksheet(this.firstUuid)

    return(
    <div style={{height: "100%", marginLeft:this.props.mobile? "0.1em":"1rem", marginRight:this.props.mobile? "0.1em":"1rem", overflow:"hidden"}}>                  
                        {this.renderViewSelection()} 
          <div style={{ display: "grid", 
                        gridTemplateColumns: gridTemplateColumns,
                        gridTemplateRows: gridTemplateRows,
                        height:"100%",
                        gridColumnGap: "1rem",
                        gridRowGap: "1rem",
                        alignItems: "top",
                        marginLeft: this.state.showAssemblySheet ? 0 : "-1rem",
                        marginRight: this.state.showRenderer ? 0 : "-1rem"
                      }}>

      
            
            {!this.state.fullRenderer ? <><div style={{gridColumn: "2 / span 2", gridRow:"1"}}>
              {this.state.showfilterBar ?<Segment> {this.renderSearchBar()} </Segment> : null} 
              {showCmdLine && this.renderInputField()}
            </div>
            
            {this.state.showAssemblySheet
            ?
              <div style={{gridColumn: "1 / 2", gridRow: "1 / 4", overflow: 'auto'}}>{this.SideBar(worksheet)} </div>
            : null}         

            <div style={{gridColumn: "2 / 3", gridRow:"3 / 4", overflow:"auto", marginBottom:"5em", height: !this.state.showfilterBar ? "calc(100% - 10rem)" : "calc(100% - 6rem)"}} >
              <div id='csv-data-entry' style={{marginBottom:"6rem"}}>
              {this.renderTable(table)}
              </div>
            </div></>: null}

            {
              this.state.showRenderer ? <>
              <div key={"caret"} style={{gridColumn: this.state.fullRenderer ? "1" : "3", gridRow: "1 / 4" , position: 'relative'}} onClick={this.switchFullRenderer}>
                {this.state.fullRenderer ? 
                  <Icon name="caret right" link style={iconStyle}/> : 
                  <Icon name="caret left"  link style={iconStyle}/>}
              </div>
              <div key={"renderer"} style={{gridColumn: this.state.fullRenderer ? "2" : "4", gridRow: "1 / 4" , height:this.state.rendererHeight, position:"relative"}}>

                  {this.getCurrentRenderer()}
                  <Form>
                      <Form.Select label={LocalizedStrings.SavedView} fluid compact options={this.state.SavedViews} onChange={this.activateSavedView}/>
                  </Form>
              </div>
            </> : null
            }
          </div>      
          <TableToPdfModal 
            open={this.state.openExportPdf} 
            currentView={this.state.WorksheetViewType}
            propertyOptions={propertyOption} 
            onClose={()=>this.setState({openExportPdf: false})}
            worksheetScale={this.state.WorksheetScale}
            onWorksheetScaleChange={WorksheetScale => this.setState({WorksheetScale})}
            getCurrentWorksheetState={this.getCurrentWorksheetState.bind(this)}
            onChangeMargins={this.onChangeMargins.bind(this)}
            onChangePdfFormat={this.onChangePdfFormat.bind(this)}
            onChangeUsePageBreak={this.onChangeUsePageBreak.bind(this)}
            selectedPaperFormat={this.state.selectPaperFormat}
            setSelectedPaperFormat={(i)=>this.setState({selectPaperFormat: i})}
            worksheet={this.firstUuid}
          >
            {this.getTableForCurrentWorksheet(true, undefined)}
          </TableToPdfModal>
          <LRModal
            open={this.state.openWorksheetProperties}
            title={LocalizedStrings.AssemblySheetProperties}
            scrolling={false}
            size="fullscreen"
            onOkClick={this.onOKWorksheetProperties}
            onCancelClick={this.closeWorksheetProperties}
            >  
            { this.firstUuid !== 0 ?  this.renderWorksheetData(): undefined }
        </LRModal>
    </div> )
  }

  onChangeMargins(nMargins){
    this.setState(nMargins)
  }

  onChangePdfFormat(nPdfFormat){
    this.setState({PdfFormat: nPdfFormat})
  }

  onChangeUsePageBreak(usePageBreak){
    this.setState({UsePageBreak: usePageBreak})
  }

  duplicateSelectedWorksheet = () =>
  {
    window.LR_DuplicateWorksheet({UUID: this.state.firstUuid})
  }

  openWorksheetProperties = () =>
  {
    let worksheet = this.getCurrentWorksheet(this.firstUuid)
    this.setState({openWorksheetProperties: true, CurrWorksheetTimeDuration: worksheet.TimeDuration, BuildDescription: worksheet.BuildDescription})
  }

  onOKWorksheetProperties = () =>
  {
    let arg = {
      UUID: this.firstUuid,
      BuildDescription: this.state.BuildDescription
    }
    if(this.state.CurrWorksheetTimeDuration)
    {
      arg.TimeDuration = this.state.CurrWorksheetTimeDuration
    }

    window.LR_SetWorksheet(arg)
    this.closeWorksheetProperties()
  }

  closeWorksheetProperties = () =>
  {
    this.setState({openWorksheetProperties: false})
  }



  switchFullRenderer = () => this.setState({
    fullRenderer: !this.state.fullRenderer
  }, this.state.fullRenderer ? () => this.setState({
    showRenderer: false
  }, () => this.setState({
    showRenderer: true})
  ) : undefined)

  openWorksheet = async () =>
  {
    let result = await window.LR_GetLinkedProject()
    if(result.Owner)
    {
      let worksheet =""
      if(this.firstUuid)
      {
        worksheet = "/uuid/" + this.firstUuid
      }
      window.LR_OpenLink({UseBaseUrl: true, URL: "/" + result.Owner + "/" + result.Project + "/worksheet" + worksheet});
    }
    else
    {
        addNotification(LocalizedStrings.NoOnlineProjectSelectedHeader, LocalizedStrings.NoOnlineProjectSelectedText)
    }
  }

  openExportPdf = async () =>
  {
    this.setState({openExportPdf: true})
  }

  closeExportPdf = () =>
  {
    if (this.tableRef && this.tableRef.current && this.tableRef.current.setUpCallbacks)
    {
      this.tableRef.current.setUpCallbacks()
      this.updateTable()
    }
    this.setState({openExportPdf: false})
  }

  updateTable = () => {
    switch(this.state.WorksheetViewType)
    {
      case PRESETS_VIEW:    /*not needed because we can only set via command line*/ break;
      case INVENTORY_VIEW:  globalCallbacks.updateInventorySummery(); break;
      case STRUCTURE_VIEW:  globalCallbacks.updateGraphList(); break;
      case SUPPORT_VIEW:    globalCallbacks.updateSupportList(); break;
      case TRUCK_VIEW:      globalCallbacks.updateInventoryContainerList(); break;
      case CASE_VIEW:       globalCallbacks.updateCaseList(); break;
      case GEL_VIEW:        globalCallbacks.updateGelList(); break;
      case Electrical_VIEW: globalCallbacks.UpdateGenerators(); break;
      case RACK_VIEW:       globalCallbacks.updateRackList(); break;
      case SUMMERY_VIEW:    globalCallbacks.refreshSummeryList(); break;
      case NOTES_VIEW:      globalCallbacks.RefetchNotesView(); break;
      case CONNECTION_VIEW: globalCallbacks.UpdateConnectionList(); break;

      default:              /*not identifyable*/ break;
    }
  }

  exportToCsv = () =>
  {    
    let s = new XMLSerializer();
    let source = document.getElementById("csv-data-entry");
    if (source)
    {
      let r = s.serializeToString(source);
      window.LR_PrintTable({HTML: r, Type: "CSV", Worksheet: this.firstUuid, Format: this.state.selectPaperFormat})
    }
  }

  exportToWorksheet= () =>
  {
    let s = new XMLSerializer();
    let source = document.getElementById("csv-data-entry");
    if (source)
    {      
      let r = s.serializeToString(source);
      window.LR_PrintTable({Path:"VW", PayLoad:{HTML: r, Type: "VWWorksheet", Worksheet: this.firstUuid, Format: this.state.selectPaperFormat}})
    }
  }

  activateSavedView = (e, {value}) =>
  {
    window.LR_ShowSavedView({UUID: value})
  }

  renderTable(table)
  {
    if(this.state.loading && !this.tableHasObjects())                  { return <Segment placeholder loading></Segment>}
    else if(this.tableHasObjects())         { return table}
    else                                    { return  <Segment placeholder><Header icon>{LocalizedStrings.NoObjects}</Header></Segment> }
  }

  tableHasObjects()
  {
    return this.state.objects.length > 0
  }

  getCurrentRenderer()
  {
    let currentWorksheet = this.getCurrentWorksheet(this.state.firstUuid);

    // Truck Renderer
    // Default Truck uuid: "{00000000-0000-0000-0000-000009000000}"
    if (this.state.WorksheetViewType === TRUCK_VIEW)
    {
      if (this.state.selectedTruckUuid !== "{00000000-0000-0000-0000-000009000000}" && this.state.selectedTruckUuid !== undefined)
      {
        return <TruckRenderer truckUuid={this.state.selectedTruckUuid} />
      }
      else
      {
        return <Segment placeholder style={{height: "100%"}}>
          <Header icon>
            Just the default truck
          </Header>
        </Segment>
      }
    }

    return <>
      {IsRunningAsBrowser() || this.props.renderOnly ?
        <>
          <Sidebar visible={this.state.navigationOpen} direction="left" style={{ top: this.props.renderOnly ? "0" : "5em", zHeight: "12000", width: this.props.renderOnly ? "100%" : "30em" }}>
            <Segment style={{ height: "100%" }}>
              <Button fluid onClick={() => this.setState({ navigationOpen: false })}>{LocalizedStrings.Close}</Button>
              {IsRunningAsBrowser() ? <NavigationContainer /> : <Tab menu={{ pointing: true }} panes={panes} />}
            </Segment>
          </Sidebar>
          <Button fluid onClick={() => this.setState({ navigationOpen: true })}>{LocalizedStrings.Navigation}</Button>
        </>
        : null}
      <Renderer
        WaitForLoading={this.props.renderOnly ? undefined : !this.tableHasObjects()}
        rootUuid={currentWorksheet ? currentWorksheet.LinkedObjectObject.UUID : 0}
        excludeFirstObject={!!currentWorksheet}
        hideEditMode={true}
        webApp={IsRunningAsBrowser()}
        supportInfo={{ Show: this.state.WorksheetViewType === SUPPORT_VIEW, Sorting: this.state.hoistFilter }} />
    </>

  }

  renderInputField()
  {
    let displayValue = this.state.InputFieldContent;

    if(this.selectedCell && this.selectedCell.unit)
    {
      let globalSettings = this.props.globalSettings;
      let baseUnit = this.selectedCell.unit;
      let unit = getUnit(baseUnit, globalSettings);
      displayValue = getUnitToString(displayValue, unit, baseUnit)
    }
 
    return <Popup
        trigger={
              <Input ref={this.inputRef} 
                  label={{ tag: true, content: LocalizedStrings.CommandLine }}
                  labelPosition='right'
                  style={{width: "100%"}} 
                  value={displayValue} 
                  onChange={(e, {value}) => {this.setState({InputFieldContent: value})}} 
                  onKeyDown={(e) => {if (e.keyCode === k_KEY_ENTER) { this.handleCommandLine()  }}} 
                  onFocus={() => {/*this.updateInputFieldContent();*/ this.setState({InputFieldFocus: true})}} 
                  onBlur={() => {this.setState({InputFieldFocus: false});}} />

        }
        content={<>
        <ReactMarkdown children={LocalizedStrings.CommandLineText}/>
        </>}
        on='focus'
        />

  }

  handleCommandLine = () =>
  {
    let inputFieldContent = this.state.InputFieldContent;

    if(this.selectedCell && this.selectedCell.unit)
    {
      let globalSettings = this.props.globalSettings;
      let baseUnit       = this.selectedCell.unit;
      let unit           = getUnit(baseUnit, globalSettings);

      if(baseUnit === BASE_UNIT_LENGTH && unit=== UNIT_FeetInches && Feet_Inch_Regex.test(inputFieldContent))
      {
        inputFieldContent = getUnitFromString(inputFieldContent, unit, baseUnit);
      } 
    } 
    
    if (this.selectedCell?.ident === undefined) { return; }

    let numberCombineTokens = ["+", "-", "...", "thru", "t"];
    let singleValue = true;

    let currentStartIndex = 0;

    for (let tok of numberCombineTokens)
    {
      let foundIndex = inputFieldContent.indexOf(tok, currentStartIndex);
      if (foundIndex !== -1)
      {
        let valueBefore = inputFieldContent.substring(currentStartIndex, foundIndex).split(/\s+/).filter(x => x !== "");
        valueBefore = valueBefore.length === 0 ? undefined : valueBefore[valueBefore.length - 1];

        let tokEndIndex = foundIndex + tok.length;
        let findNextWhitespaceIndex = inputFieldContent.indexOf(/\s+/, tokEndIndex);
        let valueAfter = "0"
        if (findNextWhitespaceIndex !== -1)
        {
          valueAfter = inputFieldContent.substring(tokEndIndex, findNextWhitespaceIndex);
        }
        else
        {
          let rest = inputFieldContent.substring(tokEndIndex);
          if (rest)
          {
            valueAfter = rest;
          }
        }

        if (!isNaN(valueBefore) && !isNaN(valueAfter)) 
        { 
          singleValue = false; 
          break;
        }
        currentStartIndex = foundIndex + tok.length;
      }
    }

    let entry = inputFieldContent
    if(singleValue)
    {
      entry = getCoreValueFromUnit(inputFieldContent.replace(",", "."), getUnit(this.selectedCell.unit, this.props.globalSettings) , this.selectedCell.unit)
    }

    let value = ""
    if(singleValue) { value += "'"  }
    value += entry
    if(singleValue) { value += "'"  }

    let stringNames = this.selectedCell.ident.split(";");
    let propertyName = stringNames[0]
    let arrayName = stringNames[1]

    if(arrayName)
    {
      let command = "(" + arrayName + " "  + this.selectedArrayIndex;
      if(this.selectedCell.IfInArrayWithName){ command += " " + propertyName; }
      command += ") "
      command += value

      window.LR_CommandLine({ Command: "Set " + command });
    }
    else
    {
      window.LR_CommandLine({ Command: "Set " + propertyName + " " + value });
    }

    this.higlightCommandLineInput()
    
  }

  updateInputFieldContent()
  {
    let value = "";
    if (this.selectedObjects.length > 0)
    {
      value = this.selectedObjects[0][this.selectedProperty];
      for (let i = 1; i < this.selectedObjects.length; i++)
      {
        let selectedObject = this.selectedObjects[i]
        if (selectedObject[this.selectedProperty] !== value)
        {
          value = "";
          break;
        }
      }
    }

    this.setState({InputFieldContent: value}, this.higlightCommandLineInput)
  }

  handleUserChange = (e, {value} ) =>
  {
    window.LR_SetWorksheet({UUID: this.firstUuid, DefaultAssingedUsers: value})
  }

  handleTimeChange = (name, value) =>
  {
    this.setState({CurrWorksheetTimeDuration: value})
  }

  renderWorksheetData()
  {
    let worksheet = this.getCurrentWorksheet(this.firstUuid)
    return (
      <>
      <Grid columns = "4" divided>
          <Grid.Row>
          <Grid.Column>
            <h5>{LocalizedStrings.WorksheetProperties}</h5>
            <Form>
              <Form.Checkbox label={LocalizedStrings.Show_FirstObject} checked={worksheet.ShowFirstObject}
                                    onChange={(e) => { this.toggleShowFirstObject(); }}/>
              <Form.Checkbox label={LocalizedStrings.Worksheet_ProtectTime} checked={worksheet.ProtectTime}
                                    onChange={this.toggleWorksheetProtectTime}/>
              <Form.Select  multiple
                            label = {LocalizedStrings.DependsOn}
                            options={this.state.selectWorksheetOptions}
                            value = {this.state.currentWorksheetDependency}
                            onChange={this.worksheetDependencyChanged}/>
              <Form.Select  label = {LocalizedStrings.LinkedTimeLine}
                            options = {this.state.selectLinkedTimeLineOptions}
                            value = {this.state.currentLinkedTimeLine}
                            onChange = {this.linkedTimeLineChanged}/>

              <Form.Select label={LocalizedStrings.LinkedSavedView} value={this.state.currentLinkedSavedView} compact options={this.state.SavedViews} onChange={this.activateSavedViewWorksheet}/>
            </Form>
            
            
              </Grid.Column>
              <Grid.Column>
              <h5>{LocalizedStrings.DefaultAssignedUsers}</h5>
               { worksheet.DefaultAssingedUsers.map((user, i)=> {return<UserAvatar key={i} user={user}/> })   }
                <Dropdown 
                style={{marginTop:"1em"}}
                fluid
                multiple
                search
                selection
                value={worksheet.DefaultAssingedUsers}
                onChange={this.handleUserChange}
                options={this.state.Users}
                label={LocalizedStrings.DefaultAssignedUsers}
                />
                <Divider/>
                <h4>{LocalizedStrings.Users}</h4>
                <Table>
                  <Table.Body>
                  {this.state.WorksheetUsers.map(user => {
                    return (
                      <Table.Row key={user.Name}>
                        <Table.Cell>
                          <UserAvatar user={user.Name}/>{user.Name}
                        </Table.Cell>
                        <Table.Cell>
                          <i>{ToTimeCode(user.TheoreticalTimeDuration)}</i>
                        </Table.Cell>                        
                      </Table.Row>
                    );
                    })}
                    </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                  <h4>{LocalizedStrings.TimeDuration}</h4>
                  <Form>
                    <UnitInput  name      = "TimeCode" 
                                label     = {LocalizedStrings.NeededTime}
                                baseUnit      = {BASE_UNIT_TIME_CODE} 
                                inline
                                fluid
                                labelPosition = "left"
                                value = {this.state.CurrWorksheetTimeDuration}
                                onStateUpdate = {this.handleTimeChange}
                                step = "1"/>
                    <Button fluid onClick={()=>{this.setState({CurrWorksheetTimeDuration: worksheet.TheoreticalTimeDuration})}}>{LocalizedStrings.CalculateTime}</Button>
                  </Form>
                    
                  <h4>{LocalizedStrings.LinkToWorksheet}</h4>
                  {worksheet ? <QRCodeCanvas style={{height:"10em", width:"10em", margin:"0.4em"}} value={worksheet.Href}/> : null}
              </Grid.Column>
              <Grid.Column>
              <h4>{LocalizedStrings.ActivePresets}</h4>
                {this.state.activePresets.map(preset => { return <div key={preset.Name}>{preset.Name}</div> })}
                {this.state.activePresets.length === 0 ? 
                <Segment placeholder>
                  <Header icon>
                  <Icon.Group >
                      <Icon size='big' color='red' name='dont'  />
                      <Icon name='edit outline' />
                    </Icon.Group>
                    {LocalizedStrings.NoPresets}
                  </Header>
                </Segment>: null}
              </Grid.Column>
              
              </Grid.Row>
            </Grid>
            <h5>{LocalizedStrings.WorksheetBuildDescription}</h5>
            <ReactMde
                  getIcon={(commandName) => <Icon name={commandName} />}
                  value={this.state.BuildDescription}
                  onChange={(data) => this.setState({BuildDescription: data, BuildDescriptionChanged: true})}
                  selectedTab={this.state.selectedTabSimple}
                  onTabChange={(data) => this.setState({selectedTabSimple: data})}
                  generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown remarkPlugins={[gfm]}>{this.state.BuildDescription}</ReactMarkdown>) }
                  minEditorHeight={300}
                  childProps={{ writeButton: { tabIndex: -1 } }}
                />
      </>
    );
  }

  activateSavedViewWorksheet = (event, {value}) => 
  {
    let worksheet = this.getCurrentWorksheet(this.firstUuid)
    
    window.LR_SetWorksheet({UUID: worksheet.UUID, LinkedSavedView: value});
    this.setState({currentLinkedSavedView: value})
    window.LR_ShowSavedView({UUID: value})
  }

  worksheetDependencyChanged = (event, {value}) => 
  {
    let worksheet = this.getCurrentWorksheet(this.firstUuid)
    
    window.LR_SetWorksheet({UUID: worksheet.UUID, DependsOn: value});
    this.setState({currentWorksheetDependency: value})
  }

  linkedTimeLineChanged = (event, {value}) =>
  {
    let worksheet = this.getCurrentWorksheet(this.firstUuid);

    this.setState({currentLinkedTimeLine: value})
    window.LR_PlayTimelineUntil({UUID: value});
    window.LR_SetWorksheet({UUID: worksheet.UUID, LinkedTimeLinePhaseChange: value});

    this.updateActivePresets();
  }

  toggleAllShowProperties = (val) =>
  {
    this.ShowFilterAssemblyGroup = val
    this.ShowFilterConsumer = val
    this.ShowFilterDistributer = val
    this.ShowFilterPlugBox = val
    this.ShowFilterGenerator = val
    this.ShowFilterFixture = val
    this.ShowFilterStructures = val
    this.ShowFilterSupport = val
    this.ShowFilterGroundSupport = val
    this.ShowFilterHouseRiggingPoint = val
    this.ShowFilterAudio = val
    this.ShowFilterMeshes = val
    this.ShowFilterDockPoints = val

    this.setState({
      ShowFilterConsumer : this.ShowFilterConsumer,
      ShowFilterAssemblyGroup : this.ShowFilterAssemblyGroup,
      ShowFilterDistributer : this.ShowFilterDistributer,
      ShowFilterPlugBox : this.ShowFilterPlugBox,
      ShowFilterGenerator : this.ShowFilterGenerator,
      ShowFilterFixture : this.ShowFilterFixture,
      ShowFilterStructures : this.ShowFilterStructures,
      ShowFilterSupport : this.ShowFilterSupport,
      ShowFilterGroundSupport : this.ShowFilterGroundSupport,
      ShowFilterHouseRiggingPoint : this.ShowFilterHouseRiggingPoint,
      ShowFilterAudio : this.ShowFilterAudio,
      ShowFilterMeshes : this.ShowFilterMeshes,
      ShowFilterDockPoints : this.ShowFilterDockPoints
    })

    this.finishUpdateShowProperties()
  }

  toogleFilterProperty = (propToToggle, e) =>
  {
    // persist is/will be outdated but doesn't break the code
    e.persist()
    if (e.altKey)
    {
      this.ShowFilterAssemblyGroup = false
      this.ShowFilterConsumer = false
      this.ShowFilterDistributer = false
      this.ShowFilterPlugBox = false
      this.ShowFilterGenerator = false
      this.ShowFilterFixture = false
      this.ShowFilterStructures = false
      this.ShowFilterSupport = false
      this.ShowFilterGroundSupport = false
      this.ShowFilterHouseRiggingPoint = false
      this.ShowFilterSupport = false
      this.ShowFilterAudio = false
      this.ShowFilterMeshes = false
      this.ShowFilterDockPoints = false
      this.ShowFilterVisible = false
      this.ShowFilterInputs = false
      this.ShowFilterOutputs = false
      this.ShowFilterSelected = false;

      this[propToToggle] = true
      
      this.setState({
        ShowFilterConsumer : this.ShowFilterConsumer,
        ShowFilterAssemblyGroup : this.ShowFilterAssemblyGroup,
        ShowFilterDistributer : this.ShowFilterDistributer,
        ShowFilterPlugBox : this.ShowFilterPlugBox,
        ShowFilterGenerator : this.ShowFilterGenerator,
        ShowFilterFixture : this.ShowFilterFixture,
        ShowFilterStructures : this.ShowFilterStructures,
        ShowFilterSupport : this.ShowFilterSupport,
        ShowFilterGroundSupport : this.ShowFilterGroundSupport,
        ShowFilterHouseRiggingPoint : this.ShowFilterHouseRiggingPoint,
        ShowFilterAudio : this.ShowFilterAudio,
        ShowFilterMeshes : this.ShowFilterMeshes,
        ShowFilterDockPoints : this.ShowFilterDockPoints,
        ShowFilterVisible : this.ShowFilterVisible,
        ShowFilterOutputs : this.ShowFilterOutputs,
        ShowFilterInputs : this.ShowFilterInputs,
        ShowFilterSelected : this.ShowFilterSelected,
      })
    }
    else
    {
      this[propToToggle] = !this[propToToggle]
      this.setState({[propToToggle]: !this.state[propToToggle]});
    }

    this.finishUpdateShowProperties()
  }

  finishUpdateShowProperties = () =>
  {

    let currentWorksheetState = this.getCurrentCompleteWorksheetState()
    if(!currentWorksheetState)
    {
      return;
    }

    let newWorksheetState = {
      ...currentWorksheetState,

      [this.state.WorksheetViewType]: {
        ...currentWorksheetState[this.state.WorksheetViewType],

        ShowFilterAssemblyGroup: this.ShowFilterAssemblyGroup,
        ShowFilterConsumer: this.ShowFilterConsumer,
        ShowFilterDistributer: this.ShowFilterDistributer,
        ShowFilterPlugBox: this.ShowFilterPlugBox,
        ShowFilterGenerator: this.ShowFilterGenerator,
        ShowFilterFixture: this.ShowFilterFixture,
        ShowFilterStructures: this.ShowFilterStructures,
        ShowFilterSupport: this.ShowFilterSupport,
        ShowFilterHouseRiggingPoint: this.ShowFilterHouseRiggingPoint,
        ShowFilterGroundSupport: this.ShowFilterGroundSupport,
        ShowFilterAudio: this.ShowFilterAudio,
        ShowFilterVisible: this.ShowFilterVisible,
        ShowFilterInputs: this.ShowFilterInputs,
        ShowFilterOutputs: this.ShowFilterOutputs,
        ShowFilterMeshes: this.ShowFilterMeshes,
        ShowFilterDockPoints: this.ShowFilterDockPoints,
        ShowFilterSelected: this.ShowFilterSelected,
        ShowSummeryTable: this.ShowSummeryTable,
      }
    }

    if (this.firstUuid !== 0)
    {
      window.LR_SetWorksheet({UUID: this.firstUuid, WorksheetState: newWorksheetState})
    }
    else if (this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW)
    {
      window.LR_SetDrawingWorksheetState({EditModeWorksheetState: newWorksheetState[DEFAULT_VIEW]})
    }
    else
    {
      window.LR_SetDrawingWorksheetState({WorksheetState: newWorksheetState})
    }

    this.filterObjects(this.allObjects).then(res => { this.setState({objects: res}); })    
  }

  getCompletePropertyInfo(property)
  {
    if (this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW)
    {
      return this.getCompletePropertyInfoEditMode(property)
    }

    return this.state.propertyList_All.find(prop => (prop.ArrayName || undefined) === (property.ArrayName || undefined) && prop.PropertyName === property.PropertyName)
  }

  getCompletePropertyInfoEditMode(property)
  {
    return this.state.propertyList_Geometry.find(prop => (prop.ArrayName || undefined) === (property.ArrayName || undefined) && prop.PropertyName === property.PropertyName)
  }

  toggleShowInventorySummary = (summeryProps, checked, e) =>
  {
    let newEnabledProperties = this.getCurrentWorksheetState().InventorySummeryProps

    const setSingleProperty = (prop) => {
      let foundCompleteProperty = this.getCompletePropertyInfo(prop)
  
      if (foundCompleteProperty)
      {      
        
        let extraPropertyIndex = newEnabledProperties.findIndex(p => (p.ArrayName || undefined) === (prop.ArrayName || undefined) && p.PropertyName === prop.PropertyName)
        
        if (e && e.altKey && !Array.isArray(summeryProps))
        {
          newEnabledProperties = [prop]
        }
        else if (extraPropertyIndex === -1 && checked)
        {
          newEnabledProperties.push(prop)
        }
        else if (extraPropertyIndex !== -1 && !checked)
        {
          newEnabledProperties.splice(extraPropertyIndex, 1)
        }
      }
    }
    

    if(Array.isArray(summeryProps)){
      for(let i of summeryProps){
        setSingleProperty(i)
      }
    }else{
        setSingleProperty(summeryProps)
    }
    let container = this.getCurrentCompleteWorksheetState()
    let newWorksheetState = {
      ...container,
      [this.state.WorksheetViewType]: {
        ...container[this.state.WorksheetViewType],
        InventorySummeryProps: newEnabledProperties.map(o => ({PropertyName: o.PropertyName, ArrayName: o.ArrayName}))
      }
    }

    if (this.firstUuid !== 0)
    {
      window.LR_SetWorksheet({UUID: this.firstUuid, WorksheetState: newWorksheetState})
    }
    else if (this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW)
    {
      window.LR_SetDrawingWorksheetState({EditModeWorksheetState: newWorksheetState[DEFAULT_VIEW]})
    }
    else
    {
      window.LR_SetDrawingWorksheetState({WorksheetState:newWorksheetState})
    }
  }

  /**
   * 
   * @param {string | string[]} propToToggle 
   * @param {*} checked 
   * @param {*} e 
   */
  toggleShowProperties = (propToToggle, checked, e) =>
  {
    let newEnabledProperties = this.getCurrentWorksheetState().ExtraEnabledProperties

    const setSingleProperty = (prop) => {
      let foundCompleteProperty = this.getCompletePropertyInfo(prop)
  
      if (foundCompleteProperty)
      {      
        
        let extraPropertyIndex = newEnabledProperties.findIndex(p => (p.ArrayName || undefined) === (prop.ArrayName || undefined) && p.PropertyName === prop.PropertyName)
        
        if (e && e.altKey && !Array.isArray(propToToggle))
        {
          newEnabledProperties = [prop]
        }
        else if (extraPropertyIndex === -1 && checked)
        {
          newEnabledProperties.push(prop)
        }
        else if (extraPropertyIndex !== -1 && !checked)
        {
          newEnabledProperties.splice(extraPropertyIndex, 1)
        }
      }
    }
    

    if(Array.isArray(propToToggle)){
      for(let i of propToToggle){
        setSingleProperty(i)
      }
    }else{
        setSingleProperty(propToToggle)
    }
    
    let container = this.getCurrentCompleteWorksheetState()
    let newWorksheetState = {
      ...container,
      [this.state.WorksheetViewType]: {
        ...container[this.state.WorksheetViewType],
        ExtraEnabledProperties: newEnabledProperties.map(o => ({PropertyName: o.PropertyName, ArrayName: o.ArrayName}))
      }
    }

    if (this.firstUuid !== 0)
    {
      window.LR_SetWorksheet({UUID: this.firstUuid, WorksheetState: newWorksheetState})
    }
    else if (this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW)
    {
      window.LR_SetDrawingWorksheetState({EditModeWorksheetState: newWorksheetState[DEFAULT_VIEW]})
    }
    else
    {
      window.LR_SetDrawingWorksheetState({WorksheetState:newWorksheetState})
    }

    this.updatePropertyList(this.state.WorksheetViewType);
  }

  updateShowFilter = (allFilters) =>
  {
    if (!allFilters) { return; }
    let setSingle = (filterName) =>
    {
      if (allFilters[filterName] !== undefined)
      {
        this[filterName] = allFilters[filterName]
      }
    }

    setSingle("ShowFilterAssemblyGroup")
    setSingle("ShowFilterConsumer")
    setSingle("ShowFilterDistributer")
    setSingle("ShowFilterPlugBox")
    setSingle("ShowFilterGenerator")
    setSingle("ShowFilterFixture")
    setSingle("ShowFilterStructures")
    setSingle("ShowFilterSupport")
    setSingle("ShowFilterGroundSupport")
    setSingle("ShowFilterHouseRiggingPoint")
    setSingle("ShowFilterAudio")
    setSingle("ShowFilterMeshes")
    setSingle("ShowFilterDockPoints")
    setSingle("ShowFilterVisible")
    setSingle("ShowFilterInputs")
    setSingle("ShowFilterOutputs")
    setSingle("ShowFilterSelected")


    this.setState({
      ShowFilterConsumer: this.ShowFilterConsumer,
      ShowFilterAssemblyGroup: this.ShowFilterAssemblyGroup,
      ShowFilterDistributer: this.ShowFilterDistributer,
      ShowFilterPlugBox: this.ShowFilterPlugBox,
      ShowFilterGenerator: this.ShowFilterGenerator,
      ShowFilterFixture: this.ShowFilterFixture,
      ShowFilterStructures: this.ShowFilterStructures,
      ShowFilterSupport: this.ShowFilterSupport,
      ShowFilterGroundSupport: this.ShowFilterGroundSupport,
      ShowFilterHouseRiggingPoint: this.ShowFilterHouseRiggingPoint,
      ShowFilterAudio: this.ShowFilterAudio,
      ShowFilterMeshes: this.ShowFilterMeshes,
      ShowFilterDockPoints: this.ShowFilterDockPoints,
      ShowFilterVisible: this.ShowFilterVisible,
      ShowFilterInputs: this.ShowFilterInputs,
      ShowFilterOutputs: this.ShowFilterOutputs,
      ShowFilterSelected: this.ShowFilterSelected,
    })
  }

  propertyPresetChanged = (viewType) => (propertyList) =>
  {
    let worksheet = propertyList.WorksheetState
    let container = {}
    container.ExtraEnabledProperties = []

    worksheet.ExtraEnabledProperties.forEach(prop => {
      let foundCompleteProperty = this.getCompletePropertyInfo(prop);

      if (foundCompleteProperty)
      {
        container.ExtraEnabledProperties.push(foundCompleteProperty)
      }
    })

    this.updateShowFilter(worksheet)

    let newState = {
      PropertyBasedFilter: worksheet.PropertyBasedFilter,
      GroupByProperty: worksheet.GroupByProperty
    }

    this.setState({
      ...newState
    })

    let currentWorksheetState = this.getCurrentCompleteWorksheetState()

    this.PropertyBasedFilter = worksheet.PropertyBasedFilter
    this.GroupByProperty = worksheet.GroupByProperty

    let newWorksheetState = {
      ...currentWorksheetState,
      [viewType]: {
        GroupByProperty: worksheet.GroupByProperty,
        ShowFilterConsumer: this.ShowFilterConsumer,
        ShowFilterAssemblyGroup: this.ShowFilterAssemblyGroup,
        ShowFilterDistributer: this.ShowFilterDistributer,
        ShowFilterPlugBox: this.ShowFilterPlugBox,
        ShowFilterGenerator: this.ShowFilterGenerator,
        ShowFilterFixture: this.ShowFilterFixture,
        ShowFilterStructures: this.ShowFilterStructures,
        ShowFilterSupport: this.ShowFilterSupport,
        ShowFilterGroundSupport: this.ShowFilterGroundSupport,
        ShowFilterHouseRiggingPoint: this.ShowFilterHouseRiggingPoint,
        ShowFilterAudio: this.ShowFilterAudio,
        ShowFilterMeshes: this.ShowFilterMeshes,
        ShowFilterDockPoints: this.ShowFilterDockPoints,
        ShowFilterVisible: this.ShowFilterVisible,
        ShowFilterSelected: this.ShowFilterSelected,
        ShowInventoryError: worksheet.ShowInventoryError,
        ShowAccessories: worksheet.ShowAccessories,
        ShowFilterInputs: this.ShowFilterInputs,
        ShowFilterOutputs: this.ShowFilterOutputs,
        ExtraEnabledProperties: container.ExtraEnabledProperties.map(prop => ({ArrayName: prop.ArrayName, PropertyName: prop.PropertyName})),
        InventorySummeryProps: worksheet.InventorySummeryProps,
        ActivePropertyPreset: propertyList.Name,
        PropertyBasedFilter: worksheet.PropertyBasedFilter,
      }
    }

    if (this.firstUuid !== 0)
    {
      window.LR_SetWorksheet({UUID: this.firstUuid, WorksheetState: newWorksheetState})
    }
    else if (this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW)
    {
      window.LR_SetDrawingWorksheetState({EditModeWorksheetState: newWorksheetState[DEFAULT_VIEW]})
    }
    else
    {
      window.LR_SetDrawingWorksheetState({WorksheetState: newWorksheetState})
    }
    
    this.updatePropertyList(viewType)
  }

  toggleShowFirstObject = (e) => 
  {
    if (this.firstUuid !== 0)
    {
      let worksheet = this.getCurrentWorksheet(this.firstUuid);
      let newValue = !worksheet.ShowFirstObject;
      this.getCallEngine().getAllProperties();
      worksheet.ShowFirstObject = newValue;
      let prop = 
      {
        UUID: worksheet.UUID,
        ShowFirstObject: newValue,
      }
      window.LR_SetWorksheet(prop);
      this.setState({ShowFirstObject: newValue});
    }
  }

  toggleWorksheetProtectTime = (e) => {
    if (this.firstUuid !== 0)
    {
      let worksheet = this.getCurrentWorksheet(this.firstUuid);
      let newValue = !worksheet.ProtectTime;
      this.getCallEngine().getAllProperties();
      worksheet.ProtectTime = newValue;
      let prop = 
      {
        UUID: worksheet.UUID,
        ProtectTime: newValue
      }
      window.LR_SetWorksheet(prop);
    }
  }

  updateActivePresets = async () => {

    if (this.firstUuid)
    {
        let res = await window.LR_GetWorksheetActivePresets({UUID: this.firstUuid})
        this.setState({activePresets: res.Presets})
    }
  }

  loadAllObjects = async () =>
  {
    this.setState({loading: true})
    let newObjects = await this.getCompleteTree();

    if (this.state.editMode)
    {
      newObjects = newObjects.map(o => ({UUID: o.UUID, Resolved: {...o}}))
    }


    this.objects    = newObjects
    this.allObjects = newObjects

    if(this.firstUuid)
    {
      let currentWorksheet = this.getCurrentWorksheet(this.firstUuid)
      if (!currentWorksheet.ShowFirstObject) { this.objects.shift(); }
    }


    

    let filteredObjects = await this.filterObjects(this.objects);

    this.setState({objects: filteredObjects, loading: false});
  }

  updateDrawingWorksheetState = async () =>
  {
    let allDrawingStates = (await window.LR_GetDrawingWorksheetState()).WorksheetState

    if(!allDrawingStates)
    {
      console.error("updateDrawingWorksheetState failed", allDrawingStates)
      return
    }

    let worksheetState = allDrawingStates[this.state.WorksheetViewType]
    if (!worksheetState) 
    {
      worksheetState = {}
    }
    let worksheetCellData = await window.LR_GetCellState({})

    this.ShowFilterConsumer = worksheetState.ShowFilterConsumer
    this.ShowFilterAssemblyGroup = worksheetState.ShowFilterAssemblyGroup
    this.ShowFilterDistributer = worksheetState.ShowFilterDistributer
    this.ShowFilterPlugBox = worksheetState.ShowFilterPlugBox
    this.ShowFilterGenerator = worksheetState.ShowFilterGenerator
    this.ShowFilterFixture = worksheetState.ShowFilterFixture
    this.ShowFilterStructures = worksheetState.ShowFilterStructures
    this.ShowFilterSupport = worksheetState.ShowFilterSupport
    this.ShowFilterGroundSupport = worksheetState.ShowFilterGroundSupport
    this.ShowFilterHouseRiggingPoint = worksheetState.ShowFilterHouseRiggingPoint
    this.ShowFilterAudio = worksheetState.ShowFilterAudio
    this.ShowFilterMeshes = worksheetState.ShowFilterMeshes
    this.ShowFilterDockPoints = worksheetState.ShowFilterDockPoints
    this.ShowFilterVisible = worksheetState.ShowFilterVisible
    this.ShowFilterInputs = worksheetState.ShowFilterInputs
    this.ShowFilterOutputs = worksheetState.ShowFilterOutputs
    this.ShowFilterSelected = worksheetState.ShowFilterSelected
    this.ShowSummeryTable = worksheetState.ShowSummeryTable
    this.UseGroupingIndentation = worksheetState.UseGroupingIndentation
    this.ActivePropertyPreset = worksheetState.ActivePropertyPreset
    this.InventorySummeryProps = worksheetState.InventorySummeryProps
    this.HoistSorting = worksheetState.HoistSorting
    this.ShowAccessories = worksheetState.ShowAccessories
    this.ShowInventoryError = worksheetState.ShowInventoryError
    this.GroupByProperty = worksheetState.GroupByProperty

    this.cellData = worksheetCellData.CellData
    
    this.setState({ GroupByProperty: this.GroupByProperty,
                    UseGroupingIndentation: this.UseGroupingIndentation,
                    ShowFilterConsumer: this.ShowFilterConsumer,
                    ShowFilterAssemblyGroup: this.ShowFilterAssemblyGroup,
                    ShowFilterDistributer: this.ShowFilterDistributer,
                    ShowFilterPlugBox: this.ShowFilterPlugBox,
                    ShowFilterGenerator: this.ShowFilterGenerator,
                    ShowFilterFixture: this.ShowFilterFixture,
                    ShowFilterStructures: this.ShowFilterStructures,
                    ShowFilterSupport: this.ShowFilterSupport,
                    ShowFilterGroundSupport: this.ShowFilterGroundSupport,
                    ShowFilterHouseRiggingPoint: this.ShowFilterHouseRiggingPoint,
                    ShowFilterAudio: this.ShowFilterAudio,
                    ShowFilterMeshes: this.ShowFilterMeshes,
                    ShowFilterDockPoints: this.ShowFilterDockPoints,
                    ShowAccessories: this.ShowAccessories,
                    ShowInventoryError: this.ShowInventoryError,
                    ShowFilterVisible: this.ShowFilterVisible,
                    ShowFilterInputs: this.ShowFilterInputs,
                    ShowFilterOutputs: this.ShowFilterOutputs,
                    ShowFilterSelected: this.ShowFilterSelected,
                    ShowSummeryTable: this.ShowSummeryTable,
                    worksheetCellData: this.cellData,
                    InventorySummeryProps: this.InventorySummeryProps,
                    PropertyBasedFilter: worksheetState.PropertyBasedFilter ?? [],
                    hoistFilter: this.HoistSorting})

    this.setState({showInputOutput: !!worksheetState.ExtraEnabledProperties.find(i => i.PropertyName === "ConnectorName" && i.ArrayName === "ElectricalConnections")})

    this.ExtraEnabledProperties = worksheetState.ExtraEnabledProperties
    this.searchFilter = worksheetState.SearchFilter
    this.PropertyBasedFilter = worksheetState.PropertyBasedFilter ?? []
    this.drawingWorksheetState = allDrawingStates
    
    
    this.updatePropertyList(this.state.WorksheetViewType)
    
    
    this.setState({objects: await this.filterObjects(this.allObjects)})
    if (this.getCallEngine().updateInventorySummery) {this.getCallEngine().updateInventorySummery()}
  }

  udpateEditModeWorksheetState = async () =>
  {
    if (!this.state.editMode) { return; }
    let newWorksheetState = (await window.LR_GetEditModeWorksheetState()).WorksheetState
    
    this.drawingEditModeWorksheetState = newWorksheetState

    let properties = newWorksheetState.ExtraEnabledProperties.map(prop => this.getCompletePropertyInfoEditMode(prop))

    this.setState({propertyList: properties})
  }


  updateDrawingSettings = async () =>
  {
    let settings = await window.LR_GetProjectSettings()
    if(settings) {
      this.setState({printMargin: {
        Bottom: settings.DrawingSettings.MarginBottom,
        Left: settings.DrawingSettings.MarginLeft,
        Right: settings.DrawingSettings.MarginRight,
        Top: settings.DrawingSettings.MarginTop
      },
      PdfFormat: settings.PdfFormat
    })
    }
  }

  updatePropertyBasedFilter = (newFilter) =>
  {
    let currentTableState = this.getCurrentWorksheetState()
    if (currentTableState)
    {
      currentTableState.PropertyBasedFilter = newFilter
      this.PropertyBasedFilter = newFilter

      let newWorksheetState = {...this.getCurrentCompleteWorksheetState(), [this.state.WorksheetViewType]: currentTableState}

      if (this.firstUuid !== 0)
      {
        window.LR_SetWorksheet({UUID: this.firstUuid, WorksheetState: newWorksheetState})
      }
      else if (this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW)
      {
        window.LR_SetDrawingWorksheetState({EditModeWorksheetState: newWorksheetState[DEFAULT_VIEW]})
      }
      else
      { 
        window.LR_SetDrawingWorksheetState({WorksheetState: newWorksheetState})
      }

      this.filterObjects(this.objects).then(filteredObjects => {
        this.setState({objects: filteredObjects, loading: false});
      });
    }
  }
  updateHoistFilter = (hoistFilter) =>
  {
    let currentWorksheetState = this.getCurrentCompleteWorksheetState(); //this.firstUuid !== 0 ? this.getCurrentWorksheet(this.firstUuid).WorksheetState : this.drawingWorksheetState;

    let newWorksheetState = {
      ...currentWorksheetState,

      [this.state.WorksheetViewType]: {
        ...currentWorksheetState[this.state.WorksheetViewType],
        HoistSorting: hoistFilter
      }
    }

    if (this.firstUuid !== 0)
    {
      // Worksheet
      window.LR_SetWorksheet({UUID: this.firstUuid, WorksheetState: newWorksheetState})
    }
    else if (this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW)
    {
      window.LR_SetDrawingWorksheetState({EditModeWorksheetState: newWorksheetState[DEFAULT_VIEW]})
    }
    else
    {
      window.LR_SetDrawingWorksheetState({WorksheetState: newWorksheetState})
    }

    this.setState({hoistFilter: hoistFilter})
  }

  /**
   * @param worksheetTypeOverride If not specified the current state WorksheetViewType will be used. Otherwise the override will be used
   * @returns The WorksheetState of the selected worksheet. So ether the drawing worksheet state or the specific worksheet WorksheetState
   */
  getCurrentWorksheetState(worksheetTypeOverride = undefined)
  {
    let null_result = {ExtraEnabledProperties: [], GroupByProperty: [], InventorySummeryProps: [], PropertyBasedFilter: [], ActivePropertyPreset: "Default", SearchFilter: ""}

    let usedWorksheetViewType = worksheetTypeOverride ?? this.state.WorksheetViewType

    let container = this.getCurrentCompleteWorksheetState(usedWorksheetViewType) //this.state.firstUuid ? this.getCurrentWorksheet(this.state.firstUuid).WorksheetState : this.drawingWorksheetState

    if (!container)
    {
      console.warn("Could not find current worksheet container. Ether way is the worksheet-data wrong or something could not be loaded correctly!");
      return null_result // Just set some importent things to a default value
    }

    let currentWorksheetState = container[usedWorksheetViewType]
    if (!currentWorksheetState)
    {
      console.warn("The current worksheet-type was not registered bevore. You probably opened an old file!");
      return null_result
    }

    return currentWorksheetState
  }

  /**
   * @param worksheetTypeOverride Used to catch the editModeWorksheetState if this.state.editMode is true
   * 
   * @returns All tables WorksheetStates for the selected worksheet
   */
  getCurrentCompleteWorksheetState(worksheetTypeOverride)
  {
    let container = this.firstUuid ? this.getCurrentWorksheet(this.firstUuid).WorksheetState : this.drawingWorksheetState

    let usedWorksheetViewType = worksheetTypeOverride ?? this.state.WorksheetViewType

    if (this.state.editMode && usedWorksheetViewType === DEFAULT_VIEW)
    {
      container[DEFAULT_VIEW] = this.drawingEditModeWorksheetState
    }

    if (!container)
    {
      console.error("Could not find the current worksheet. The data for the worksheets is probably not complete!");
      return undefined // Just set some importent things to a default value
    }

    return container;
  }

  setUpCallbacks()
  {

    this.getCallEngine().ShowWorksheet = async (arg) => 
    {
      let uuid = arg.UUID
      if(uuid=== EMPTY_UUID)
      {
        uuid = 0
      }
      this.onWorksheetSingleClick(uuid)
    }

    this.getCallEngine().TableViewRemoveProperty = async (arg) => 
    {
      this.toggleShowProperties({
        PropertyName:arg.PropertyName,
        ArrayName:arg.ArrayName
      }, false)
    }

    this.getCallEngine().GetPropertyLocalizedOverwrite = async (arg) => 
    {
      let LocalizedOverwrite  = await window.LR_GetPropertyLocalizedOverwrite()
      this.setState({LocalizedOverwrite})
    }

    this.getCallEngine().getUserOverviewTableView = async () => 
    {
      let users   = await window.LR_GetUsers()
      const members = users?.Users
        ?.map((user) => {
          return {
            text: <>{user.Name}</>,
            key: user.UUID,
            value: user.UUID,
          };
        });

      this.setState({Users: members});
      
    }

    this.getCallEngine().updateProperties = async (modifiedObjects) => 
    {
      //if (this.getCallEngine().editMode === EDIT_Geometry) { return; }
      let selectionChanged = false

      modifiedObjects.forEach(partialObject =>
      {        
        this.objects = this.allObjects.map(element => 
        {
          if(element.Resolved)
          {
            if (element.Resolved.UUID === partialObject.UUID)
            {
              if (partialObject.Selected !== undefined && partialObject.Selected !== element.Resolved.Selected)
              {
                selectionChanged = true
              }
              return({
                ...element,
                Resolved : {...element.Resolved,...partialObject}
              });
            }
          }
          return element;
        });

        this.allObjects = this.objects.map(e => e)
        
      })
      ;
      let selectedObjects = this.allObjects.filter(o => o?.Resolved?.Selected).map(e => e.Resolved.UUID)

      if (selectionChanged)
      {
        if (this.tableRef.current?.objectSelectionChanged)
        {
          this.tableRef.current.objectSelectionChanged(selectedObjects)
        }
      }

      let filteredObjects = await this.filterObjects(this.objects);
      this.setState({objects: filteredObjects, loading: false});
      this.objects = filteredObjects
    }

    this.getCallEngine().getAllProperties = async() => 
    {
      // In the case where this.firstUuid !== 0, firstObject is the first child of firstUuid
      // because we don't want the next objects after the rootObject
      if (this.state.editMode) {
        this.firstUuid = 0
        //this.onWorksheetSingleClick(0)
      }

      this.loadAllObjects()
    }

    this.getCallEngine().getTimeLines = async () => 
    {
      let timeLinesArray = await window.LR_GetTimePhaseChanges();

      if(Array.isArray(timeLinesArray.Changes))
      {
        let stateArray = timeLinesArray.Changes.map(timePhas => {
          return {
            value: timePhas.UUID,
            text: timePhas.Name,
          };
        });
  
        this.setState({selectLinkedTimeLineOptions: stateArray});
        
      }
      else{
        console.error("getTimeLines failed", timeLinesArray)
      }


    }

    this.getCallEngine().getSavedViewsWorksheet = async () =>
    {
      let res = await window.LR_GetSavedViews();

      let SavedViews = res.SavedViews.map((temp) => {
        return (
          {
            key: temp.UUID,
            value: temp.UUID,
            text: temp.Name,
          }
        );
      });

      this.setState({ SavedViews });
    }

    this.getCallEngine().getWorksheets = async () =>
    {
      let workSheetArray = await window.LR_GetWorksheets();
      let deleted = true;
      let worksheetDependencies = [];
      let worksheetUsers = [];
      let worksheetLinkedTimeLine = undefined;
      let currentLinkedSavedView = EMPTY_UUID;

      if(workSheetArray && Array.isArray(workSheetArray.Worksheets))
      {
        workSheetArray.Worksheets.forEach(element => 
          {
            if(this.firstUuid === element.UUID) 
            { 
              deleted = false; 
              worksheetDependencies = element.DependsOn;
              worksheetUsers = element.AssignedUsers;
              worksheetLinkedTimeLine = element.LinkedTimeLinePhaseChange;
              currentLinkedSavedView = element.LinkedSavedView;
            }
          });
      }
      else
      {
        console.error("LR_GetWorksheets failed ", workSheetArray)
      }


      

      this.updateActivePresets();

      if (worksheetLinkedTimeLine === EMPTY_UUID) 
      {
        //window.LR_DeactivatePresets();
      }

      
      if(deleted) {  this.firstUuid = 0; }

      let worksheets = workSheetArray.Worksheets;
      worksheets.sort(function(a, b) {return a.Order - b.Order});

      this.setState({
        worksheets:                   workSheetArray.Worksheets, 
        currentWorksheetDependency:   worksheetDependencies, 
        WorksheetUsers:               worksheetUsers, 
        currentLinkedSavedView:       currentLinkedSavedView, 
        currentLinkedTimeLine:        worksheetLinkedTimeLine},
        () => {this.updatePropertyList(this.state.WorksheetViewType); if (this.getCallEngine().updateInventorySummery) { this.getCallEngine().updateInventorySummery() }});
    }

    this.getCallEngine().TableViewUpdateGlobalSettings = (settings) =>
    {
      this.setState({Unit: settings.App_DisplayUnit_Length.Value})
    }

    this.getCallEngine().updateWorksheetVisibliltyObjects = (visibleObject) =>
    {
      let currentWorksheet = this.getCurrentWorksheet(this.firstUuid);
      if (currentWorksheet && currentWorksheet.LinkedVisibilityObject === visibleObject.UUID)
      {
        this.loadAllObjects()
      }
    }

    this.getCallEngine().drawingChanged = async () =>
    {
      await this.updateDrawingSettings()
      await this.updateDrawingWorksheetState()
    }

    this.getCallEngine().updatePropertyPresets = async () =>
      {
        let propertyPresets         = await window.LR_GetPropertyTemplateMap()
        let inventoryPropertyPreset = await window.LR_GetInventoryPropertyTemplateMap()

        let propPresetOptions = propertyPresets.ResourceMap.propertyTemplate.map(entry => {return {
                                    key: entry.Name,
                                    text: this.renderLabelForPreset(entry),
                                    value: entry.Name
                                    }})

        let invPropPresetOptions = inventoryPropertyPreset.ResourceMap.inventoryPropertyTemplate.map(entry => ({
                                    key: entry.Name,
                                    text: this.renderLabelForPreset(entry),
                                    value: entry.Name
        }))

        this.sortPresetTables(propPresetOptions)
        this.sortPresetTables(invPropPresetOptions)

        this.setState({propertyPresets: propPresetOptions, 
                      inventoryPropertyPresets: invPropPresetOptions })
      }

      this.getCallEngine().worksheetEditMode = (editMode) =>
      {
        this.allObjects = []
        this.objects    = []
        this.setState({editMode: editMode.UUID, propertyList: [], objects: []}, async () => {
          if (editMode.UUID)
          {
            this.udpateEditModeWorksheetState()
          }
          else 
          {
            await this.updateDrawingWorksheetState()
          }
          this.getCallEngine().getAllProperties()
        })
      }
  }

  sortPresetTables = (list) =>
  {
    list.sort((a,b) => 
    {

      return String(a.Const) - String(b.Const)
    }
  )
  }

  renderLabelForPreset = (entry) =>
  {
    if(this.props.mobile)
    {
      return entry.Name
    }
    return(
      <> 
        <Label color={entry.Const ? entry.Name === "Default" ? "blue" : "grey" : "green"}>
          {entry.Const ? LocalizedStrings.DefaultGenerated : LocalizedStrings.UserGenerated}
        </Label>
        {entry.Name}
        {
        !entry.Const ? 
         <Icon  name    = "delete" 
                color   = "red"
                onClick = {(e) => {this.deletePreset(e, entry.Name)}}/> : null
        }
      </>
    )
  }

  deletePreset = (e, entry) => {
    window.LR_DeletePropertyTemplate({Name: entry})
    e.stopPropagation()
  }

  getPropFromIdentifier(ident)
  {
    let strings = ident.split("_");
    if (strings.length > 1)
    {
      return {PropertyName: strings[0], ArrayName: strings[1]}
    } 
    else if (strings.length === 1)
    {
      return {PropertyName: strings[0], ArrayName: ""}
    }
    return undefined
  }

  DefaultTable = (showPages = false) =>
  {
    let pagesProps = {
      showPages,
      printMargin: {
        Bottom: this.state.MarginBottom,
        Left: this.state.MarginLeft,
        Right: this.state.MarginRight,
        Top: this.state.MarginTop
      },
      PdfFormat: showPages ? this.state.PdfFormat : undefined,
      pages: Paper[this.state.selectPaperFormat].pages
    }

    return <DefaultTable
      worksheetState={this.getCurrentWorksheetState()}
      worksheetUUID={this.firstUuid}
      propertyList={this.state.propertyList}
      localizedOverwrite={this.state.LocalizedOverwrite}
      headerData={this.state.worksheetCellData}
      objects={this.allObjects}
      onAddDefaultColumn={this.AddDefaultColum.bind(this)}
      onSelectionPropertyChanged={this.onSelectionPropertyChanged.bind(this)}
      onUpdateWorksheetStateSortBy={this.updateWorksheetStateSortBy.bind(this)}
      worksheetScale={this.state.WorksheetScale}
      showSummaryTable={this.state.ShowSummeryTable}
      pagesProps={pagesProps}
      UsePageBreak={this.state.UsePageBreak && showPages}
    />
  }

  // update which property the table is sorted by (so it can be replicated on print)
  updateWorksheetStateSortBy = (sortedProperties) => {
    const container = this.getCurrentCompleteWorksheetState()
    const newWorksheetState = {
      ...container,
      [this.state.WorksheetViewType]: {
        ...container[this.state.WorksheetViewType],
        SortedByProperties: sortedProperties.map((prop) => ({
          PropertyName: prop.ident,
          Ascending: prop.dir === "ascending",
        })),
      },
    };

    // check whether the worksheet UUID is valid, otherwise update the drawing
    if (this.firstUuid !== 0)
    {
        window.LR_SetWorksheet({UUID: this.props.worksheet, WorksheetState: newWorksheetState})
    }
    else if (this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW)
    {
      window.LR_SetDrawingWorksheetState({EditModeWorksheetState: newWorksheetState[DEFAULT_VIEW]})
    }
    else
    {
        window.LR_SetDrawingWorksheetState({WorksheetState: newWorksheetState})
    }
  }

  AddDefaultColum = (e) =>
  {
    this.toggleShowProperties({PropertyName:"Name"}, true, e)
  }

  getValueFromCell = (object, cell, arrayIndex) =>
  {
    if (!cell.ident) { return undefined; }
    let stringNames = cell.ident.split(";");
    let propertyName = stringNames[0]
    let arrayName = stringNames[1]

    if (arrayName)
    {
      let array = object.Resolved[arrayName]
      if (Array.isArray(array))
      {
        if (cell.IfInArrayWithName)
        {
          if(arrayIndex >= array.length) {return undefined;}
          return array[arrayIndex][propertyName]
        }
        else
        {
          return array[arrayIndex]
        }
      }
      else if (Array.isArray(object.Resolved.ExtraData))
      {
        if (arrayIndex === 0)
        {
          return object.Resolved[propertyName]
        }
        return object.Resolved.ExtraData[arrayIndex - 1][propertyName]
      }
    }
    else
    {
      return object.Resolved[propertyName]
    }
    return undefined
  }

  onSelectionPropertyChanged = (cells, objects, arrayIndex) =>
  {
    this.selectedCell = cells
    this.selectedArrayIndex = arrayIndex

      if (cells === undefined) 
      {
        this.setState({InputFieldContent: ""})
        return;
      }
      let displayValue = undefined
      if (this.selectedCell)
      {
        objects.forEach(object => {
          let nVal = this.getValueFromCell(object, this.selectedCell, this.selectedArrayIndex)
          if(nVal !== undefined){
            let value = getUnitValueFromCore(nVal, getUnit(this.selectedCell.unit, this.props.globalSettings), this.selectedCell.unit)
            if (displayValue === undefined) { displayValue = value }
            else if (displayValue !== value){ displayValue = "" }
          }
          
        })
      }

      // For Object Properties that are empty
      if(typeof displayValue === 'object') { displayValue = displayValue.NameIdentifier ? displayValue.NameIdentifier :  displayValue.Name}

      
      // On IOS, we don't want to highlight this here
      this.setState({InputFieldContent: displayValue ?? ""},window.IsIOS ? ()=>{} : this.higlightCommandLineInput)
  }

  higlightCommandLineInput = () => 
  {
    if(this.inputRef && this.inputRef.current)
    {
      this.inputRef.current.focus();
      this.inputRef.current.select();

        setTimeout(()=>{
          this.inputRef.current.select();
        }, 50)
    }

  }

  SideBar = (worksheet) => 
  {


    let webApp = IsRunningAsBrowser() 
    return(
      <div style={{position:"relative", height:"100%"}}>
      <h5>{LocalizedStrings.WorksSheetHeader}</h5>
      <Form>
      <LRFilterInput value = {this.state.searchFilterWorksheet} onChange={(value)=> {this.setState({searchFilterWorksheet: value})}}/>
      <Form.Select  label = {LocalizedStrings.FilterByTimeLine}
                            options = {[{
                              value: EMPTY_UUID,
                              text: LocalizedStrings.FilterByTimeLineAll
                            },...this.state.selectLinkedTimeLineOptions]}
                            value = {this.state.timeLineFilter}
                            onChange = {(event, {value})=>{this.setState({timeLineFilter: value})}}/>
      </Form>
      <div style={{height: `calc(100% - ${worksheet ? "32em": IsVectorworksContext() ? "28em" : "25em"})`, overflow: "auto"}}>
      <Menu vertical secondary fluid>
            <Menu.Item  active        = {this.sceneWorksheet.UUID === this.firstUuid}
                        name          = {this.sceneWorksheet.Name} 
                        icon          = "sitemap"
                        onClick       = {() => this.onWorksheetSingleClick(this.sceneWorksheet.UUID)}
                        />
            {this.state.worksheets.map((element, i) =>
              {
                let name = `${element.Name} : ${element.LinkedTimeLinePhaseChangeObject.Name}`
                if(this.state.searchFilterWorksheet !== "" && !name.toLowerCase().includes(this.state.searchFilterWorksheet.toLowerCase()) )
                {
                  return null
                }

                if(this.state.timeLineFilter !== EMPTY_UUID && this.state.timeLineFilter !== element.LinkedTimeLinePhaseChange)
                {
                  return null
                }


                return(
                  <Menu.Item  key           = {"worksheet" + element.UUID}
                              active        = {element.UUID === this.firstUuid}
                              content       = {name}
                              as={webApp ? Link : undefined}     
                              to={webApp ? `/${this.props.username}/${this.props.projectname}/worksheet/uuid/${element.UUID}` : undefined}
                              icon          = 
                              {
                                <Icon name    = 'delete' 
                                      color   = 'red'
                                      onClick = {(e) => {e.stopPropagation(); this.deleteWorksheet(element.UUID)}}
                                />
                              }
                              onClick       = {() => this.onWorksheetSingleClick(element.UUID)}
                              onDoubleClick = {() => this.onWorksheetDoubleClick(element.Name, i)}
                              draggable     = {true}
                              onDragStart   = {() => {this.draggedIndex = i;}}
                              onDragEnter   = {() => {this.reorderWorksheet(i);}}
                              onDragEnd     = {(event) => {this.setWorksheetOrder(); event.stopPropagation();}}
                              > 
                    
                    {this.state.editableWorksheetIndex === i 
                      ? <Input  name      = "WorksheetName" 
                                value     = {this.state.worksheetName}
                                autoFocus
                                onChange  = {this.onWorksheetValueChanged} 
                                onBlur    = {this.updateWorksheetData}
                                onKeyDown = {this.onWorksheetKeyDown}
                                />
                      : []
                    }
                  </Menu.Item>
                );
              })
            }
      </Menu>

      <div style={{position:"absolute",  bottom: IsElectronContext() ? "7em" :"8em", width: "10em"} }>
        {worksheet ?<b>{LocalizedStrings.QRCodeToWorksheet}</b>   : null}
        {worksheet ? <>
        <div style={{display:"flex"}}>
            <QRCodeCanvas style={{height: "4em", width:"4em", margin:"0.3em"}}value={worksheet.Href}/>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"center"}}>
              <Button compact size='tiny' disabled={this.state.firstUuid === 0} fluid primary onClick={this.openWorksheetProperties}>{LocalizedStrings.OpenAssemblySheetProperties}</Button>
              <Button compact size='tiny' disabled={this.state.firstUuid === 0} fluid onClick={this.duplicateSelectedWorksheet}>{LocalizedStrings.DuplicateAssemblySheet}</Button>
            </div>
        </div>
        </> : null}

        {webApp ? 
            null:
            <>
              <b>{LocalizedStrings.Export}</b>   
              <br/>
              <Button size='small' fluid compact positive onClick={this.openExportPdf}>{LocalizedStrings.ExportPdf}</Button>
              <Button size='small' fluid compact positive onClick={this.exportToCsv} style={{marginTop:"0.15rem"}}>{LocalizedStrings.ExportCsv}</Button>                                          
              {
                 IsVectorworksContext() ? <Button fluid size='small' compact positive onClick={this.exportToWorksheet}>{LocalizedStrings.ExportWorksheet}</Button> : null
              } 
            </>}
        </div>
      </div>


      </div>

    );
  }

  renderColorField = (propertyName, item) =>
  {
    if(item[propertyName] === undefined) {return null}
    return       <ColorInputField  name            = {propertyName}
                      readOnly
                      size            = "mini"
                      label           = ""
                      colorX          = {item[propertyName+"X"]} 
                      colorY          = {item[propertyName+"Y"]} 
                      colorL          = {item[propertyName+"Z"]} 
                      />
  }

  renderVectorMagnitude = (propertyName, item) =>
  {
    if(item[propertyName] === undefined) {return null}

    let vector = item[propertyName]

    let magnitude = (vector.X * vector.X+ vector.Y * vector.Y +  vector.Z * vector.Z) / (vector.X + vector.Y +  vector.Z)

    return       <UnitInput  baseUnit = {BASE_UNIT_LENGTH} 
                            value    = {magnitude}
                            readOnly
                            transparent
                            label    = {false} />
  }

  renderGoboField = (propertyName, item) =>
  {    class GoboComponent extends Component {
      constructor(props) {
        super()
        this.state = {
          texture: null
        }
        if(!HasOrLoadingTexture(props.name)) {
          LoadTextures()
        }
        GetTexture(props.name)
        .then((tex) => this.setState({texture: tex}))
      }
      render() {
        if(this.state.texture) {
          return (
            <>
            <img alt="" style={{width:'2em', height:'2em', marginRight: '0.5em', verticalAlign: 'middle' }} src={this.state.texture.Texture.image.src}></img>
            {this.props.name}
            </>
          )
          
        }
        return this.props.name ? this.props.name : null
      }
    }

    return <GoboComponent name={item.CurrentGoboObject.Name}/>
  }


  renderObjectField = (propertyName, item) =>
  {
    if(item[propertyName] === undefined && item[propertyName+"Object"] === undefined) {return null}
    
    let pN = propertyName
    // You can also give the name without object added to it
    if(item[propertyName+"Object"] !== undefined)
    {
      pN = propertyName+"Object"
    }
    return <React.Fragment>
              {item[pN].NameIdentifier ? item[pN].NameIdentifier : item[pN].Name}
            </React.Fragment>
  }

  renderRackField = (propertyName, item) =>
  {
    let rack = item[propertyName]
    if(rack === undefined || rack.ContainerType !== INVENTORY_CONTAINER_TYPE_RACK) {return null}
    
    return <React.Fragment>
              {item[propertyName].Name}
            </React.Fragment>
  }

  renderCaseField = (propertyName, item) =>
  {
    let rack = item[propertyName]
    if(rack === undefined || rack.ContainerType !== INVENTORY_CONTAINER_TYPE_CASE) {return null}
    
    return <React.Fragment>
              {item[propertyName].Name}
            </React.Fragment>
  }

  renderUnitInput = (baseUnit, propertyName, item) => 
  {
    let value = item[propertyName]
    return  <UnitInput  baseUnit = {baseUnit} 
                        value    = {value}
                        readOnly
                        transparent
                        label    = {false} />
  }

  // Drag and Drop the Worksheets
  reorderWorksheet = (currentPlace) =>
  {
    let worksheets = [...this.state.worksheets];
    let draggedIndex = this.draggedIndex;
    let j = 0;
    let temp = 0;

    if(currentPlace > draggedIndex)
    {
      for(j = currentPlace; j > draggedIndex; j--)
      {
        temp = worksheets[j].Order;
        worksheets[j].Order   = worksheets[j-1].Order;
        worksheets[j-1].Order = temp;
      }
    } else if(currentPlace < draggedIndex)
    {
      for(j = currentPlace; j < draggedIndex; j++)
      {
        temp = worksheets[j].Order;
        worksheets[j].Order   = worksheets[j+1].Order;
        worksheets[j+1].Order = temp;
      }
    }

    worksheets.sort(function(a, b) {return a.Order - b.Order});
    this.draggedIndex = currentPlace;
    this.setState({worksheets});
  }

  setWorksheetOrder = () =>		
  {		
    window.LR_SetWorksheetOrder(this.state.worksheets);		
  }

  updateData = (item) => 
  {
    window.LR_SetObject(item);
  }

  onKeyDown = (event, item) =>
  {
    event.stopPropagation()
    if(event.keyCode === k_KEY_ENTER) { this.updateData(item); }
  }

  onWindowKeyDown = (event) =>
  {
    if (event.keyCode === k_KEY_ESCAPE)
    {
      this.clearSelectionProperties();
    }

  }

  setActivePreset = (presetUuid, active) =>
  {
    let request = 
    {
      UUID : presetUuid,
      Active : active,
    };
    window.LR_SetActivePreset(request);
  }

  changeView = (value) =>
  {
    this.clearSelectionProperties();
    
    let currentWorksheetState = this.getCurrentWorksheetState(value)

    this.updateShowFilter(currentWorksheetState);
    this.updatePropertyList(value);
    let propertyBasedFilter = currentWorksheetState ? currentWorksheetState.PropertyBasedFilter : []
    this.setState({hoistFilter: currentWorksheetState.HoistSorting ?? 0, WorksheetViewType: value, PropertyBasedFilter: propertyBasedFilter, ShowAccessories: currentWorksheetState.ShowAccessories, ShowInventoryError: currentWorksheetState.ShowInventoryError, ShowSummeryTable: currentWorksheetState.ShowSummeryTable}, () => {
      this.filterObjects(this.allObjects).then(res => {
        this.setState({objects: res}, () => {
          if (this.getCallEngine().updateInventorySummery) {this.getCallEngine().updateInventorySummery()}
        })
      })
    })
  }

  // Worksheets
  onWorksheetSingleClick = (linkedUuid) =>
  {
    this.firstUuid = linkedUuid;
    
    this.getCallEngine().getAllProperties();
    this.createWorksheetOptions(linkedUuid);

    let worksheet = this.getCurrentWorksheet(linkedUuid);

    if (worksheet && worksheet.LinkedTimeLinePhaseChange) 
    {
      window.LR_PlayTimelineUntil({UUID: worksheet.LinkedTimeLinePhaseChange});
    } 
    else 
    {
      window.LR_DeactivatePresets();
    }

    let currentWorksheetState = linkedUuid ? worksheet.WorksheetState[this.state.WorksheetViewType] : this.drawingWorksheetState[this.state.WorksheetViewType]
    this.PropertyBasedFilter = currentWorksheetState.PropertyBasedFilter ?? []

    this.updateShowFilter(currentWorksheetState)
    if (this.firstUuid !== 0)
    {
      this.updateWorksheetCellData(worksheet.CellData)


      this.setState({
        PropertyBasedFilter: this.PropertyBasedFilter, 
        ShowAccessories: currentWorksheetState.ShowAccessories, 
        ShowInventoryError: currentWorksheetState.ShowInventoryError,
        GroupByProperty: currentWorksheetState.GroupByProperty,
        UseGroupingIndentation: currentWorksheetState.UseGroupingIndentation,

      })
    }
    else
    {
      this.updateDrawingWorksheetState();
    }
    
    this.getCallEngine().getWorksheets();

    this.setState({firstUuid: linkedUuid});


    this.updatePropertyList(this.state.WorksheetViewType);
    this.updateHoistFilter(currentWorksheetState.HoistSorting)

    if(worksheet && worksheet.LinkedSavedViewObject.UUID)
    {
      window.LR_ShowSavedView({UUID: worksheet.LinkedSavedViewObject.UUID})
    }
  }

  onWorksheetDoubleClick = (name, index) =>
  {
    this.setState({worksheetName: name, editableWorksheetIndex: index}); 
  }

  updateWorksheetData = () => 
  {
    let request =
    {
      UUID  : this.state.worksheets[this.state.editableWorksheetIndex].UUID,
      Name    : this.state.worksheetName,
    }
    window.LR_SetWorksheet(request);

    this.setState({worksheetName: "", editableWorksheetIndex: undefined});
  }

  updateWorksheetCellData = (cellData) =>
  {
    this.setState({worksheetCellData: cellData})
  }

  onWorksheetValueChanged = (e, {value}) => 
  {
    this.setState({worksheetName: value});
  }

  onWorksheetKeyDown = (event) =>
  {
    if(event.keyCode === k_KEY_ENTER) { this.updateWorksheetData(); }
  }

  deleteWorksheet = (uuid) =>
  {
    let currentWorksheet = this.getCurrentWorksheet(this.firstUuid);
    if (currentWorksheet && currentWorksheet.UUID === uuid && currentWorksheet.LinkedTimeLinePhaseChange) 
    {
      window.LR_DeactivatePresets();
    }
    let request = 
    {
      UUID : uuid
    };
    window.LR_DeleteWorksheet(request);
    this.setState({firstUuid: 0})
  }

  createWorksheetOptions = (uuid) =>
  {
    let options = this.state.worksheets.filter((value) => value.UUID !== uuid)
                                        .map((worksheet) => {
                                          if (worksheet.UUID !== uuid) {
                                              return {
                                              value: worksheet.UUID,
                                              text: worksheet.Name,
                                            };
                                          }
                                          return {};
                                        });

    if (options === undefined) options = [];
    let currentWorksheet = this.getCurrentWorksheet(uuid);

    if (!currentWorksheet)
    {
      return;
    }

    let currentWorksheetState = currentWorksheet.WorksheetState ?? {}
    currentWorksheetState = currentWorksheetState[this.state.WorksheetViewType] ?? {}
                                      

    this.setState({selectWorksheetOptions       : options, 
                    currentWorksheetDependency  : currentWorksheet ? currentWorksheet.DependsOn                 : [], 
                    currentLinkedTimeLine       : currentWorksheet ? currentWorksheet.LinkedTimeLinePhaseChange : undefined,
                    currentLinkedSavedView      : currentWorksheet ? currentWorksheet.LinkedSavedView : undefined,
                    ShowOffsetValues            : currentWorksheet ? currentWorksheetState.ShowOffsetValues          : this.ShowOffsetValues,
                    ShowRotationValues          : currentWorksheet ? currentWorksheetState.ShowRotationValues        : this.ShowRotationValues,
                    ShowDmxValues               : currentWorksheet ? currentWorksheetState.ShowDmxValues             : this.ShowDmxValues,
                    ShowUserAndTime             : currentWorksheet ? currentWorksheetState.ShowUserAndTime           : this.ShowUserAndTime,
                    ShowElectrical              : currentWorksheet ? currentWorksheetState.ShowElectrical            : this.ShowElectrical,
                    WorksheetUsers              : currentWorksheet ? currentWorksheet.AssignedUsers             : []
    });
  }

  // Get All Objects
   
  async getCompleteFirstObject()
  {
    let firstObject = {};
    if(!this.firstUuid)
    {
      firstObject = await window.LR_GetCompleteFirstObject();
    } 
    else
    {
      let currentWorksheet = this.getCurrentWorksheet(this.firstUuid);
      firstObject = await window.LR_GetCompleteFirstChild({UUID: currentWorksheet.LinkedObjectObject.UUID});
    }
    return firstObject;
  }

  async getCompleteObject(uuid)
  {
    let object = await window.LR_GetCompleteObject({UUID: uuid});
    return object;
  }

  async getCompleteTree() 
  {
    let completeFlatTree = [];
    if (!this.firstUuid)
    {
      completeFlatTree = await window.LR_GetObjectTree({CompleteTree: true, Async: true});
    }
    else
    {
      let worksheet = this.getCurrentWorksheet(this.firstUuid);
      if(worksheet){
        let res = await window.LR_GetWorksheetObjects({UUID: worksheet.UUID, Async: true})
        completeFlatTree = res.Objects
      }
    }

    return completeFlatTree;
  }


  onSelectProperty(object, select = this.selectMode)
  {
    let focus = true;

    if (select)
    {
      if (!this.isObjectSelected(object.UUID)) {this.selectedObjects.push(object);}
    }
    else
    {
      let index = this.getSelectedObjectIndex(object.UUID)
      if (index > -1) {this.selectedObjects.splice(index, 1);}
    }

    if (this.selectedObjects.length <= 0)
    {
      
      this.selectedProperty = undefined;
      this.selectedType = undefined;
      
      focus = false;
    }

    if ( focus)
    {
      this.higlightCommandLineInput()
    }
    else
    {
      this.setState({InputFieldContent: ""})
      this.inputRef.current.inputRef.current.blur()
    }
    
    this.setState({selectedObjects: this.selectedObjects});
  }

  isObjectSelected(uuid)
  {
    
    let objectIndex = this.getSelectedObjectIndex(uuid);
    let result = objectIndex > -1;
    return result;
  }

  getSelectedObjectIndex(uuid)
  {
    let result = this.selectedObjects.findIndex(cur => cur.UUID === uuid );
    return result;
  }

  //Search bar
  searchFieldUpdate = async (e, {value}) =>
  {
    let newValue = value;

    let currentWorksheet = this.getCurrentWorksheetState()
    currentWorksheet.SearchFilter = newValue;

    let newWorksheetState = {
      ...this.getCurrentCompleteWorksheetState(),
      [this.state.WorksheetViewType]: {
        ...currentWorksheet
      }
    }

    if (this.firstUuid !== 0)
    {
      let prop = 
      {
        UUID: this.firstUuid,
        WorksheetState: newWorksheetState
      }
      window.LR_SetWorksheet(prop);
    }
    else if (this.state.editMode && this.state.WorksheetViewType === DEFAULT_VIEW)
    {
      window.LR_SetDrawingWorksheetState({EditModeWorksheetState: newWorksheetState[DEFAULT_VIEW]})
      this.searchFilter = value;
    }
    else
    {
      window.LR_SetDrawingWorksheetState({WorksheetState: newWorksheetState})
      this.searchFilter = value;
    }
    this.filterObjects(this.allObjects).then(res => { this.setState({objects: res}); })
    //this.getCallEngine().getAllProperties();
  }

  filterObjects = async (objects) =>
  {
    if (this.state.editMode) { return objects; }

    if(!Array.isArray(objects))
    {
      console.log("Error filterObjects", objects)
      return[]
    }

    let filterResult = await window.LR_WorksheetFilterHelper({
      WorksheetUUID: this.firstUuid,
      UuidToFilter: objects.map(e => e.Resolved?.UUID),
      WorksheetViewType: this.state.WorksheetViewType,
      Async: true
    })

    return objects.filter((element, idx) => filterResult.VisibleBool[idx])
  }

  onInventorySort = (propertyName) =>
  {
    let currentSortedProp = this.state.inventorySortingProperty
    let currentSortedDir  = this.state.inventorySortingDirection

    if (currentSortedProp !== propertyName)
    {
      this.setState({
          inventoryTable: this.sortInventoryTable(propertyName),
          inventorySortingProperty: propertyName,
          inventorySortingDirection: "ascending"
        })
    }
    else
    {
      this.setState({
        inventoryTable: this.state.inventoryTable.reverse(),
        inventorySortingDirection: currentSortedDir === "ascending" ? "descending" : "ascending"
      })
    }
  }

  sortInventoryTable = (propertyName) =>
  {
    return this.state.inventoryTable.sort((a, b) => {
      let propA = a[propertyName]
      let propB = b[propertyName]

      if (propA > propB) { 
        return 1;
      } else if (propA < propB) {
        return -1;
      }
      return 0;
    })
  }

  clearSelectionProperties(updateState = true)
  {
    this.selectedObjects    = [];
    this.isSelecting        = false;     
    this.selectedProperty   = undefined;   //The selected Property (zb "OffsetX")
    this.selectedType       = undefined;   //Whether the selection is for the resolved, preset or default
    this.selectedPreset     = undefined;   //The selected preset index in the array of the objects
    this.selectMode         = true;        //Whether we are selecting the next columns or deselecting
    this.lastSelectedIndex  = 0;           //Kind of cursor on what place the cursor was last frame
    this.selectDirection    = 0;           //The direction the mouse moves. Needs to be saved, to enable the deselection when dragging the cursor back to the origin the drag started

    if (updateState)
    {
      this.setState({selectedObjects: this.selectedObjects});
    }
  }
  
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      globalSettings: state[GLOBAL_SETTINGS].data.GlobalSettings,
    };
}

let componentExport;
if(!process.env.JEST_WORKER_ID) { componentExport = connect(mapStateToProps)(TableView) }
else                                         { componentExport = TableView }

export default componentExport 

