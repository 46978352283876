//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component, Suspense } from 'react';
import LocalizedStrings from "../../localization/AboutDialog";
import LRModal from '../Basics/BasicModal';
import StructuralList from '../TableViews/Structural/StructuralList'
import { globalCallbacks } from "../../util/callback";

const Line = React.lazy(async ()=>{
  const {
    Chart: ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } = await import("chart.js")

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  return import("react-chartjs-2").then(module=>({default:module.Line}))
})

//-----------------------------------------------------------------------------
// The component for the TreeControl
class CalculationOverviewDialog extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      tree: []
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState( { 
        open : true, 
      });

      if (globalCallbacks.refreshStructuralCalculationList) {globalCallbacks.refreshStructuralCalculationList()}

      let tree = await window.LR_GetObjectTree({
        Async: true, 
        IncludeProperties:
        [
          "Name",
          "SupportProperties"
        ]
      })

      this.setState( { 
        tree : tree, 
      });

      console.log(tree)
      
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="large"
                scrolling={false}
                title={LocalizedStrings.CalculationOview}
                noCancel
                onOkClick={this.close}>
          {this.state.open ? 
          <>
            <StructuralList /> 
            <Suspense fallback={<></>}>
            {this.renderLine()}
            </Suspense>
            
          </>: null}
      </LRModal>
    )
  }

  renderLine()
  {
    let entries = []
    for(let e of this.state.tree)
    {
      if(e.SupportProperties )
      {
        for(let s of e.SupportProperties)
        {
          entries.push({
            Name: e.NameIdentifier + " - " + s.Name,
            ChainShortenEffectWorkload: s.ChainShortenEffectWorkload,
            ChainShortenEffectTrussWorkload: s.ChainShortenEffectTrussWorkload,
            ChainShortenEffectTruss: s.ChainShortenEffectTruss,
            ChainShortenEffect: s.ChainShortenEffect,
          })
        }
      }
    }



    return(
      <>
      <h2>{LocalizedStrings.ChainShortenEffectPercent}</h2>
      <Line       
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
          },
        }}
      } 
        data={
        {
          labels: entries.map(e=>e.Name),
          datasets: [
            {
              stepped: "middle",
              label: LocalizedStrings.ChainShortenEffectTruss,
              data: entries.map(e=>e.ChainShortenEffectTruss),
              borderColor: 'green',
              backgroundColor: 'rgba(255, 2, 1, 0.5)',
            },
            {
              stepped: "middle",
              label: LocalizedStrings.ChainShortenEffect,
              data: entries.map(e=>e.ChainShortenEffect),
              borderColor: 'blue',
              backgroundColor: 'rgba(255, 2, 1, 0.5)',
            },
          ],
        }
      } />
      <h2>{LocalizedStrings.ChainShortenEffectByWorkload}</h2>
      <Line       
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
          },
        }}
      } 
        data={
        {
          labels: entries.map(e=>e.Name),
          datasets: [
            {
              stepped: "middle",
              label: LocalizedStrings.ChainShortenEffectTrussWorkload,
              data: entries.map(e=>e.ChainShortenEffectTrussWorkload),
              borderColor: 'green',
              backgroundColor: 'rgba(255, 2, 1, 0.5)',
            },
            {
              stepped: "middle",
              label: LocalizedStrings.ChainShortenEffectWorkload,
              data: entries.map(e=>e.ChainShortenEffectWorkload),
              borderColor: 'blue',
              backgroundColor: 'rgba(255, 2, 1, 0.5)',
            },
          ],
        }
      } />
      </>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowCalculationOverview = () => 
    { 
      this.show();
    }
    
  }
}

export default CalculationOverviewDialog