
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Button, Container, Divider, Form, Grid, Header, Icon, Image, Menu, Message, Segment, Tab, Table } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ResourceManager";
import { globalCallbacks } from '../../util/callback';
import { BASE_UNIT_LENGTH, BASE_UNIT_WEIGHT, EMPTY_UUID, RESOURCE_TYPE_FixtureType, RESOURCE_TYPE_Mesh, RESOURCE_TYPE_PrintLabel, RESOURCE_TYPE_SymbolDef } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';
import DynamicTable, { TABLE_SELECTION_TYPE_Row } from '../Basics/DynamicTableView';
import ChangeListChecks from '../ChangeRequestCheck/ChangeListChecks';
import LabelEntry from '../PrintLabels/LabelEntry';
import PreviewRenderer from '../Renderer/PreviewRenderer';
import TextureRenderer from '../Renderer/TextureRenderer';
import SelectResourceModal from '../ToolSelect/SelectRessourceModal';
import CaseTemplateDisplay from './CaseTemplateDisplay';
import ConnectorTemplates from './ConnectorTemplates';
import CrossSectionTemplateDisplay from './CrossSectionTemplateDisplay';
import DocumentDisplay from './DocumentDisplay';
import MaterialTemplateDisplay from './MaterialsTemplateDisplay';
import PaperTemplateDisplay from './PaperTemplateDisplay';
import RackTemplateDisplay from './RackTemplateDisplay';
import SymbolMapDisplay from './SymbolMapDisplay';
import TruckTemplateDisplay from './TruckTemplateDisplay';
import { UploadExistingResourceToCloud } from './UploadToServerModal';
import VectorworksFieldMapping from './VectorworksFieldMapping';


export const textureRenderer = new TextureRenderer(100, 100)

class ResourceManager extends Component 
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
      meshes        : [],
      symbolDefs    : [],
      fixtureTypes  : [],
      printLabels   : [],

      editableResourceIndex : undefined,
      editableResourceType : undefined,
      resourceName: "",

      activeResourceUuid : "",
      activeResourceIndex: [undefined, undefined, undefined, undefined],
      activeResourceType : RESOURCE_TYPE_Mesh,
      activeTabNumber: 0,
      resourceSelectorOpen: false,

      geometryEditMode: 0,
      printLabelEditMode: 0,

      previewTextures: {},
      textureArray: [],
      importedTextureArray: []
    };
    const style = {
      height: props.iPad ? 'calc(100vh - 15.5em)' : (this.props.VWRouterStyle ? 'calc(100vh - 11em)' : 'calc(100vh - 6em)'),
      overflow: 'auto',
      padding: "1rem",
      width: "100%"
    }

    const meshesColumns = {
      Name: true,
      Preview: false,
      Count: true,
      Duplicate: false,
      Edit: true,
      Delete: true,
      Layer: false,
      Class: false,
      Weight: false,
      UploadToCloud: false
    }

    const symbolDefsColumns = {
      Name: true,
      Preview: true,
      Count: true,
      Duplicate: true,
      Edit: true,
      Delete: true,
      Layer: true,
      Class: true,
      Weight: true,
      UploadToCloud: true
    }

    const fixtureTypesColumns = {
      Name: true,
      Preview: false,
      Count: true,
      Duplicate: false,
      Edit: true,
      Delete: true,
      Layer: true,
      Class: true,
      Weight: true,
      UploadToCloud: true
    }

    const printLabelsColumns = {
      Name: true,
      Preview: false,
      Count: false,
      Duplicate: true,
      Edit: true,
      Delete: true,
      Layer: false,
      Class: false,
      Weight: false,
      UploadToCloud: true
    }

    this.panes = 
    [
        { resourceType: RESOURCE_TYPE_Mesh, menuItem: <Menu.Item key={0}><Icon name='boxes' />{LocalizedStrings.Meshes}</Menu.Item>, render: () => <Tab.Pane style={style} as={Container}>{this.showResources(this.state.meshes, RESOURCE_TYPE_Mesh, LocalizedStrings.NoMehsesMessage, meshesColumns)}</Tab.Pane> },
        { resourceType: RESOURCE_TYPE_SymbolDef, menuItem: <Menu.Item key={1}><Icon name='copy' />{LocalizedStrings.SymbolDefinitions}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}>{this.showResources(this.state.symbolDefs, RESOURCE_TYPE_SymbolDef, LocalizedStrings.NoSymbolDefMessage, symbolDefsColumns)}</Tab.Pane> },
        { resourceType: RESOURCE_TYPE_FixtureType, menuItem: <Menu.Item key={2}><Icon name='lightbulb' />{LocalizedStrings.FixtureTypes}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}>{this.showResources(this.state.fixtureTypes, RESOURCE_TYPE_FixtureType, LocalizedStrings.NoFixtureTypeMessage, fixtureTypesColumns)}</Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={4}><Icon name='map signs' />{LocalizedStrings.SymbolMap}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}><SymbolMapDisplay/></Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={5}><Icon name='map' />{LocalizedStrings.VWFieldMap}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}><VectorworksFieldMapping/></Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={3}><Icon name='print' />{LocalizedStrings.PrintLabels}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}>{this.showResources(this.state.printLabels, RESOURCE_TYPE_PrintLabel, LocalizedStrings.NoPrintLabelsMessage, printLabelsColumns)}</Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={13}><Icon name='file outline' />{LocalizedStrings.PaperTemplates}</Menu.Item>, render: () => <Tab.Pane style={style} as={Container}><PaperTemplateDisplay/></Tab.Pane> } ,
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={6}><Icon name='truck' />{LocalizedStrings.TruckRessources}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}><TruckTemplateDisplay/></Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={7}><Icon name='box' />{LocalizedStrings.CaseRessources}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}><CaseTemplateDisplay/></Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={8}><Icon name='th' />{LocalizedStrings.RackRessources}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}><RackTemplateDisplay/></Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={9}><Icon name='file pdf outline' />{LocalizedStrings.DocumentResources}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}><DocumentDisplay/></Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={14}><Icon name='image' />{LocalizedStrings.Pictures}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}>{this.showTextures()}</Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={10}><Icon name='file code' />{LocalizedStrings.ChangeListChecks}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}><ChangeListChecks/></Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={11}><Icon name='won' />{LocalizedStrings.CrossSectionResources}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}><CrossSectionTemplateDisplay/></Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={13}><Icon name='plug' />{LocalizedStrings.ConnectorTemplates}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}><ConnectorTemplates/></Tab.Pane> },
        { resourceType: RESOURCE_TYPE_PrintLabel, menuItem: <Menu.Item key={12}><Icon name='tag' />{LocalizedStrings.MaterialResources}</Menu.Item>,   render: () => <Tab.Pane style={style} as={Container}><MaterialTemplateDisplay/></Tab.Pane> },
    ]

    
    this.editedResourceType = undefined

    this.labelRef = React.createRef()
  }

  componentDidMount() 
  {
    this.setUpCallbacks();
    window.LR_GetGeometryEditMode().then(res => {
      globalCallbacks.resourceManagerGeometryEditMode(res.EditMode)
    })

    globalCallbacks.getResourceManagerMeshes();
    globalCallbacks.getResourceManagerSymbolDefs()
    globalCallbacks.getResourceManagerFixtureTypes();
    globalCallbacks.getResourceManagerPrintLabels();
    globalCallbacks.refetchPreviewTextures();
    globalCallbacks.getRessourceManagerTextures();
  }

  render() 
  {
    let preview = undefined
    
    switch(this.state.activeTabNumber)
    {
      // intentional fallthrough for 0,1
      case 0  :
      case 1  :
      case 2  : preview = this.renderDefaultPreview(); break;
      case 5  : preview = this.renderPrintPreview(); break;
      case 11 : preview = this.renderPreviewTexture(); break;
      default : // nothing todo here
    }

    return (
        <>
          <UploadExistingResourceToCloud
            open={this.state.uploadModalOpen}
            itemUUID={this.state.uploadModalUUID}
            onClose={()=>this.setState({uploadModalOpen: false})}
          />
          <Grid style={{marginLeft: '5px', height: "100%"}}>
            <Grid.Column width={!preview ? 16 : 12}>
              <Tab panes={this.panes} menu={{ text: true, style: {overflow:"auto", width: 'calc(100% - 10px)'} }} activeIndex={this.state.activeTabNumber} onTabChange={(event, {activeIndex})=>{this.setActiveTab(activeIndex)}}/>
            </Grid.Column>
            {!preview ? null : <Grid.Column width={4} style={{height:"100vh"}}>
            <div style={{paddingTop:"1em", marginRight:"1em", height:"100%"}}>
              {preview}
            </div>
            </Grid.Column>}
            {this.state.resourceSelectorOpen ? <SelectResourceModal 
              open={this.state.resourceSelectorOpen} 
              close={this.closeRessourceSelector} 
              resourceType={this.state.activeResourceType}
              handleChangeType={(t)=>{this.setState({activeResourceType: t})}}
              disableNewButton
              disableTopMenu
              /> : null}
          </Grid>
        </>   
    );
  }

  showResources = (resources, type, noItemsMessage, columns) => {
    
    //----------------------------------------------------
    // define dynamic table view props

    let headerIdent = []
    
    if(columns.Name)   headerIdent.push({sortable: true,  ident: "Name", label: LocalizedStrings.Name, unit: undefined})
    if(columns.Preview)headerIdent.push({sortable: false, ident: "Preview", label: LocalizedStrings.Preview, unit: undefined})
    if(columns.Count)  headerIdent.push({sortable: true,  ident: "DrawingCount", label: LocalizedStrings.DrawingCount, unit: undefined})
    if(columns.Weight)  headerIdent.push({sortable: true,  ident: "Weight", label: LocalizedStrings.Weight, unit: undefined})
    if(columns.Class)  headerIdent.push({sortable: true,  ident: "Class", label: LocalizedStrings.Class, unit: undefined})
    if(columns.Layer)  headerIdent.push({sortable: true,  ident: "Layer", label: LocalizedStrings.Layer, unit: undefined})
    if(columns.Duplicate)   headerIdent.push({sortable: false, ident: "Duplicate", label: LocalizedStrings.Duplicate, unit: undefined})
    if(columns.Edit)   headerIdent.push({sortable: false, ident: "Edit", label: LocalizedStrings.Edit, unit: undefined})
    if(columns.UploadToCloud)   headerIdent.push({sortable: false, ident: "UploadToCloud", label: LocalizedStrings.UploadToCloud, unit: undefined})
    if(columns.Delete) headerIdent.push({sortable: false, ident: "Delete", label: LocalizedStrings.Delete, unit: undefined})

    const cellRender = (entry, rowIndex) => {
      return {
        Name: entry.Name,
        Preview: (this.state.previewTextures[entry.PreviewTexture] !== undefined) ? <img src={"data:image/png;base64," + this.state.previewTextures[entry.PreviewTexture].Buffer} alt={entry.UUID}/> : null,
        DrawingCount: entry.DrawingCount,
        Layer: <>{entry?.LayerObject?.Name}</>,
        Class: <>{entry?.ClassObject?.Name}</>,
        Weight: <UnitInput transparent cellChild readOnly value={entry.Weight} baseUnit={BASE_UNIT_WEIGHT}/>,
        Duplicate: (
          <Icon
            name="copy"
            onClick={() => {
              this.copyResource(type, entry.UUID);
            }}
          />
        ),

        Edit: (
          <Icon
            name="edit"
            onClick={() => this.onEditClicked(entry.UUID)}
          />
        ),

        UploadToCloud: (
          <Icon
            name="cloud upload"
            onClick={() => this.onUploadClicked(entry.UUID)}
          />
        ),

        Delete: entry.DrawingCount === 0 || type === RESOURCE_TYPE_PrintLabel ? (
          <Icon
            name="delete"
            onClick={() => {
              window.LR_DeleteResource({
                ResourceType: type,
                UUID: entry.UUID,
              });
            }}
          />
        ) : <div>{LocalizedStrings.CantDelete}</div>,
      };
    };

    const cellData = resources

    //----------------------------------------------------
    // render
    return (
      <div>
        {cellData.length > 0 ? (
          <div style = {{marginBottom:"6rem"}}> 
            <DynamicTable
              cellRender={cellRender}
              headerIdent={headerIdent}
              cellData={cellData}
              selectionType={TABLE_SELECTION_TYPE_Row}
              singleRowSelectionChange={this.selectionChange}
              autoCellSize
            />
          </div>
        ) : (
          <Message warning style={{ alignItem: "center" }}>
            <Grid>
              <Grid.Column floated="left" width="5">
                <h3 style={{ color: "darkred" }}>{noItemsMessage}</h3>
              </Grid.Column>
            </Grid>
          </Message>
        )}
      </div>
    );
  }

  selectionChange = (newSelection) =>
  {
    switch (this.state.activeResourceType)
    {
      case RESOURCE_TYPE_Mesh:        this.setActiveResource(this.state.meshes[newSelection.RowIndex], RESOURCE_TYPE_Mesh, newSelection.RowIndex); break;
      case RESOURCE_TYPE_SymbolDef:   this.setActiveResource(this.state.symbolDefs[newSelection.RowIndex], RESOURCE_TYPE_SymbolDef, newSelection.RowIndex); break;
      case RESOURCE_TYPE_FixtureType: this.setActiveResource(this.state.fixtureTypes[newSelection.RowIndex], RESOURCE_TYPE_FixtureType, newSelection.RowIndex); break;
      case RESOURCE_TYPE_PrintLabel:  this.setActiveResource(this.state.printLabels[newSelection.RowIndex], RESOURCE_TYPE_PrintLabel, newSelection.RowIndex); break;
      default: console.warn("Cannot select resourcetype", this.state.activeResourceType)
    }
  }

  showTextures = () => {

    return (<div>
      {
            (this.state.textureArray.length + this.state.importedTextureArray.length) > 0 ?
            <Table selectable>
              <Table.Body>
            {this.state.textureArray.map((texture, index) =>
              {
                return(
                  <Table.Row key={texture.UUID} onClick={()=>{this.setState({activeTexture: texture})}}>
                    <Table.Cell collapsing> 
                      {index+1}
                    </Table.Cell>
                    <Table.Cell> 
                          {texture.Name}
                    </Table.Cell>
                    <Table.Cell>
                    <Image src={"data:image/png;base64," + texture.Buffer}size='small' alt={texture.UUID}/> 
                    </Table.Cell>

                  </Table.Row>
                );
              })}
              {this.state.importedTextureArray.map((texture, index) =>
              {
                return(
                  <Table.Row key={texture.UUID} onClick={()=>{this.setState({activeTexture: texture})}}>
                    <Table.Cell collapsing> 
                      {index+1}
                    </Table.Cell>
                    <Table.Cell> 
                          {texture.Name}
                    </Table.Cell>
                    <Table.Cell>
                    <Image src={"data:image/png;base64," + texture.TextureBuffer}size='small' alt={texture.UUID}/> 
                    </Table.Cell>
                    <Table.Cell>
                      <Icon name="delete" onClick={()=>{window.LR_DeleteTexture({UUID: texture.UUID})}}/>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
              </Table.Body>
              </Table>
               :
              <Message warning style={{alignItem:'center'}}>
                <Grid>
                  <Grid.Column floated='left' width='5'>
                    <h3 style={{color:'darkred'}}>
                      {LocalizedStrings.NoTextures}
                    </h3>
                  </Grid.Column>
                </Grid>
              </Message>
            
            }
  </div>);
  }

  renderSymbolDefProps()
  {
    if (this.state.activeResourceType !== RESOURCE_TYPE_SymbolDef) { return null; }

    let currentSymbolDef = this.state.symbolDefs[this.state.activeResourceIndex[this.state.activeTabNumber]]

    if (!currentSymbolDef) { return null; }


    return (
      <React.Fragment>
      <Divider/>
      <Form>
        <Form.Field>
          <label>{LocalizedStrings.Class}</label>
          <Form.Input value = {currentSymbolDef.ClassObject.Name} readOnly />
        </Form.Field>

        <Form.Field>
          <label>{LocalizedStrings.Layer}</label>
          <Form.Input value = {currentSymbolDef.LayerObject.Name} readOnly />
        </Form.Field>

        <Form.Field>
          <label>{LocalizedStrings.PartNumber}</label>
          <Form.Input value = {currentSymbolDef.PartNumber} readOnly />
        </Form.Field>

        <Form.Field>
          <label>{LocalizedStrings.TimeOffset}</label>
          <Form.Input value = {currentSymbolDef.TimeOffset} readOnly />
        </Form.Field>

        <Form.Field>
          <label>{LocalizedStrings.Supplier}</label>
          <Form.Input value = {currentSymbolDef.Supplier} readOnly />
        </Form.Field>

        <UnitInput 
        readOnly
        label={LocalizedStrings.Weight}
        value={currentSymbolDef.Weight}
        baseUnit={BASE_UNIT_WEIGHT}
        />
      </Form>
      </React.Fragment> )
  }

  renderMeshProps()
  {
    if (this.state.activeResourceType !== RESOURCE_TYPE_Mesh) { return null; }

    let currentMesh = this.state.meshes[this.state.activeResourceIndex[this.state.activeTabNumber]];

    if (!currentMesh) { return null; }

    return (
      <React.Fragment>
      <Divider/><Form>
        <Form.Field>
          <label>Bounding X</label>
          <UnitInput baseUnit={BASE_UNIT_LENGTH} value = { currentMesh.BoundingMax.X - currentMesh.BoundingMin.X} readOnly />
        </Form.Field>
        <Form.Field>
          <label>Bounding Y</label>
          <UnitInput baseUnit={BASE_UNIT_LENGTH} value = { currentMesh.BoundingMax.Y - currentMesh.BoundingMin.Y} readOnly />
        </Form.Field>
        <Form.Field>
          <label>Bounding Z</label>
          <UnitInput baseUnit={BASE_UNIT_LENGTH} value = { currentMesh.BoundingMax.Z - currentMesh.BoundingMin.Z} readOnly />
        </Form.Field>
      </Form>
      </React.Fragment>)
  }

  ressourceButtonEnabled = () =>
  {
    switch(this.state.activeTabNumber)
    {
      case 1: return true;
      case 2: return true;
      case 3: return true;
      default:return false;
    }
  }

  renderDefaultPreview()
  {
    return (
    <div style={{position: "sticky", top:10}}>
      <Segment secondary>
        <Header color="black">{LocalizedStrings.Preview}</Header>
      <PreviewRenderer resourceUuid={this.state.activeResourceUuid} resourceType={this.state.activeResourceType} height={360} />
      </Segment>
      <Segment.Group>
      <Segment secondary>
      <Header color="black">{LocalizedStrings.HeaderResourceAction}</Header>
      <Button fluid positive onClick={(event) => {this.onCreateItemClicked()}}>
        <Icon name='add' />
        {LocalizedStrings.CreateItem}
      </Button>
      <Divider/>
      <Button fluid onClick={() => {this.setState({resourceSelectorOpen: true})}} disabled={!this.ressourceButtonEnabled()} >
        <Icon name='cloud download' />
        {LocalizedStrings.Download}
      </Button>
      <Divider />
      <Button fluid onClick={this.rerenderCurrentTabResources}>
        {LocalizedStrings.RerenderPreviews}
      </Button>
      {this.renderSymbolDefProps()}
      {this.renderMeshProps()}
      </Segment>
      </Segment.Group>
    </div>)
  }

  renderPrintPreview()
  {
    let currentPrintLabel = this.state.printLabels[this.state.activeResourceIndex[this.state.activeTabNumber]];
    return (
      <div style={{position: "sticky", top:10}}>
        <Segment secondary>
        <Header>{LocalizedStrings.Preview}</Header>
          <div style={{width: "100%", height: "365px"}}>
            {currentPrintLabel ? <LabelEntry  labelUuid     = {currentPrintLabel.UUID}
                                              editable      = {false} 
                                              scale         = {0.5}
                                              sizeX         = {currentPrintLabel.SizeX} 
                                              sizeY         = {currentPrintLabel.SizeY}
                                              ref           = {this.labelRef}  
                                              field         = {currentPrintLabel.Fields}/> : null}
          </div>
          </Segment>
            <Segment secondary>
              <Header>{LocalizedStrings.HeaderResourceAction}</Header>
            <Button fluid positive onClick={(e) => {this.onCreateItemClicked()}}>
              <Icon name='add' />
              {LocalizedStrings.CreateItem}
            </Button>
            <Divider/>
            <Button fluid positive onClick={(e) => {window.LR_PrintLabels({Path:""})}}>
              <Icon name='file alternate outline' />
              {LocalizedStrings.CreatePrintLabels}
            </Button>
            <Divider/>
            <Button fluid onClick={() => {this.setState({resourceSelectorOpen: true})}}>
              <Icon name='cloud download' />
              {LocalizedStrings.Download}
            </Button>
            </Segment>

      </div>)
  }

  renderPreviewTexture()
  {
    let texture = this.state.activeTexture;
    return (
      <div style={{position: "sticky", top:10}}>
        <Segment secondary>
          <Header>{LocalizedStrings.Preview}</Header>
          {texture ? <Image src={"data:image/png;base64," + (texture.Buffer ? texture.Buffer : texture.TextureBuffer)}size='small' alt={texture.UUID}/>  : null}
          </Segment>
            <Segment secondary>
              <Header>{LocalizedStrings.HeaderResourceAction}</Header>
            <Button fluid positive onClick={()=>{ window.LR_ImportTexture()}}>
              <Icon name='add' />
              {LocalizedStrings.ImportItem}
            </Button>
            </Segment>

      </div>)
  }

  setActiveResource = (resource, resourceType, index) =>
  {
    if (resourceType === RESOURCE_TYPE_PrintLabel && this.labelRef.current)
    {
      this.labelRef.current.fieldsNeedUpdate(this.state.printLabels[index])
    }
    
    if (resource === undefined)
    {
      this.setState({activeResourceUuid: EMPTY_UUID})
      return;
    }
    let newActiveResource = this.state.activeResourceIndex
    newActiveResource[this.state.activeTabNumber] = index
    
    this.setState({activeResourceUuid: resource.UUID, activeResourceIndex: newActiveResource})
  }

  setActiveTab = (tabIndex) =>
  {
    let resourceUuid = undefined;
    let newResourceType = this.panes[tabIndex].resourceType
    switch (newResourceType)
    {
      case RESOURCE_TYPE_Mesh:        resourceUuid = this.state.meshes[this.state.activeResourceIndex[tabIndex]];       break;
      case RESOURCE_TYPE_SymbolDef:   resourceUuid = this.state.symbolDefs[this.state.activeResourceIndex[tabIndex]];   break;
      case RESOURCE_TYPE_FixtureType: resourceUuid = this.state.fixtureTypes[this.state.activeResourceIndex[tabIndex]]; break;
      default: break;
    }
    this.setState({activeTabNumber:tabIndex, editableResourceIndex: undefined, editableResourceType: newResourceType, activeResourceType: newResourceType, activeResourceUuid: resourceUuid ? resourceUuid.UUID : undefined})
  }

  updateNameValue = () => {
    if (this.state.editableResourceType === RESOURCE_TYPE_Mesh)
    {
      let request = {
        UUID: this.state.meshes[this.state.editableResourceIndex].UUID,
        Name: this.state.resourceName
      }
      window.LR_SetMesh(request);
    }
    else if (this.state.editableResourceType === RESOURCE_TYPE_SymbolDef)
    {
      let request = 
      {
        UUID: this.state.symbolDefs[this.state.editableResourceIndex].UUID,
        Name: this.state.resourceName,
      }
      window.LR_SetSymbolDef(request)
    }
    else if (this.state.editableResourceType === RESOURCE_TYPE_PrintLabel)
    {
      let request = 
      {
        UUID: this.state.printLabels[this.state.editableResourceIndex].UUID,
        Name: this.state.resourceName
      }
      window.LR_SetPrintLabel(request);
    }

    this.setState({resourceName: "", editableResourceIndex: undefined, editableResourceType: undefined})
  }

  onNameChangeKeyDown = (e) => {
    if (e.keyCode === 13) { this.updateNameValue(); }
  }

  onNameChanged = (e, {value}) => {
    this.setState({resourceName: value})
  }

  onEditClicked = (uuid) => {
    this.editedResourceType = this.state.activeResourceType
    window.LR_OpenGeometryEditMode({UUID: uuid})
  }

  onUploadClicked = (uuid) => {
    this.setState({uploadModalUUID: uuid, uploadModalOpen: true})
  }

  onEditPrintLabel = async (printLabel) => {
    //This is wrong. It is not working with the isImported
    if (!printLabel.isImported) 
    {
      await window.LR_ImportPrintLabel({UUID: printLabel.UUID, File: printLabel.DrawingPath})
    }

    this.editedResourceType = RESOURCE_TYPE_PrintLabel
    window.LR_OpenGeometryEditMode({UUID: printLabel.UUID})
  }

  onCreateItemClicked = (type) => 
  {
    if (this.state.activeTabNumber === 0) //RESOURCE_TYPE_Mesh
    {
      window.LR_ImportMesh({ });
    } 
    else if (this.state.activeTabNumber === 1)
    {
      window.LR_AddNewSymbolDefinition();
    }
    else if (this.state.activeTabNumber === 2)
    {
      window.LR_ImportGdtf({ });
    }
    else if (this.state.activeTabNumber === 5)
    {
      window.LR_AddNewPrintLabel({});
    }
  }

  copyResource = (resourceType, uuid) =>
  {
    window.LR_DuplicateResource({UUID: uuid, ResourceType: resourceType})
  }

  setUpCallbacks()
  {
    globalCallbacks.getResourceManagerMeshes = async () =>
    {
      const ret    = await window.LR_GetMeshes({});
      let meshes = ret.Meshes;
      if (meshes === undefined) { meshes = []; }
      this.setState({meshes});
    }

    globalCallbacks.getResourceManagerSymbolDefs = async () =>
    {
      const ret        = await window.LR_GetSymbolDefs();
      let symbolDefs = ret.SymbolDefs;
      if (symbolDefs === undefined) { symbolDefs = []; }
      this.setState({symbolDefs});
    }

    globalCallbacks.getResourceManagerFixtureTypes = async () =>
    {
      const ret          = await  window.LR_GetFixtureTypes();
      let fixtureTypes = ret.FixtureTypes;
      if (fixtureTypes === undefined) { fixtureTypes=[]; }
      this.setState({fixtureTypes});
    }

    globalCallbacks.getResourceManagerPrintLabels = async () =>
    {
      const ret         = await window.LR_GetPrintLabels();
      this.setState({printLabels: ret.PrintLabels})
    }


    globalCallbacks.resourceObjectChange = async () => 
    {
      this.forceUpdate();
    }

    globalCallbacks.resourceManagerGeometryEditMode = (resourceUuid) =>
    {
      if (resourceUuid === 0 && this.editedResourceType)
      {
        // We closed the edit mode
        if (this.editedResourceType !== RESOURCE_TYPE_PrintLabel)
        {
          textureRenderer.pushResourceToQueue(this.editedResourceType, this.state.geometryEditMode)
        }

        if (this.editedResourceType === RESOURCE_TYPE_PrintLabel)
        {
          if (globalCallbacks.getResourceManagerPrintLabels)
          {
            globalCallbacks.getResourceManagerPrintLabels();
          }
        }
      }
      this.setState({geometryEditMode: resourceUuid})
    }

    

    globalCallbacks.refetchPreviewTextures = () =>
    {
      window.LR_GetPreviewTextures({WriteBuffer: true}).then(res => {
        let texturesMap = {} 
        res.Textures.forEach(t => {texturesMap[t.UUID] = t})
        this.setState({previewTextures: texturesMap, textureArray: res.Textures})
      })
    }

    globalCallbacks.getRessourceManagerTextures = () =>
    {
      window.LR_GetTextures({}).then(res => {
        this.setState({importedTextureArray: res.Textures})
      })
    }
  }

  closeRessourceSelector = () => 
  {
      this.setState({ resourceSelectorOpen : false });
  }

  openRessourceSelector = () => 
  {
      this.setState({ resourceSelectorOpen : true });
  }

  rerenderCurrentTabResources = () =>
  {
    let resourcesToRender = []
    switch (this.state.activeResourceType)
    {
    case RESOURCE_TYPE_Mesh: this.state.meshes.forEach(mesh => {resourcesToRender.push(mesh.UUID)}); break;
    case RESOURCE_TYPE_SymbolDef: this.state.symbolDefs.forEach(sym => {resourcesToRender.push(sym.UUID)}); break;
    case RESOURCE_TYPE_FixtureType: this.state.fixtureTypes.forEach(ft => {resourcesToRender.push(ft.UUID)}); break;
    default: break;
    }
    resourcesToRender.forEach(res => {
      textureRenderer.pushResourceToQueue(this.state.activeResourceType, res)
    })
  }
}

export default ResourceManager;