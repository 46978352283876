import { IsRunningAsBrowser } from '../util/defines';

import fetch from 'cross-fetch';
import FormData from 'form-data'

//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

export let lrServerConnection = 
{ 
  __TOKEN: null,
  __SERVER: null,
  __USERNAME: null,
  __PROJECT: null,
  __FILE: null,
  __BRANCH: null,
  __CHANGELIST: null,
  __LOGGEDINUSER: null,
  __SOCKET: null,
  __SHARETOKEN: null,
  auth: function(request) {
    return {
      ...request,
      headers: {
        ...request.headers,
        'Authorization': `Bearer ${this.__TOKEN}`,
        'sharetoken': this.__SHARETOKEN
      }
    }
  },
  getBaseUrl: function() {
    return this.__SERVER
  },
  getUserUrl: function() {
    return `${this.getBaseUrl()}/api/users/${this.__USERNAME}`
  },
  getLoggedInUrl: function() {
    return `${this.getBaseUrl()}/api/users/${this.__LOGGEDINUSER}`
  },
  getProjectUrl: function() {
    return `${this.getUserUrl()}/projects/${this.__PROJECT}`;
  },
  setToken: function(token) {
    this.__TOKEN = token;
    return this;
  },
  setShareToken: function(token) {
    this.__SHARETOKEN = token
  },
  setProject: function(projectname) {
    this.__PROJECT = projectname;
    this.__SOCKET.emit('setProject', {
      project: `${this.__USERNAME}/${projectname}`,
      token: this.__TOKEN
    });
    return this;
  },
  setLoggedIn: function(username, callFromGetSelectedAdmin = false) {

    if(callFromGetSelectedAdmin)
    {
      this.__USERNAME = username
      this.__SOCKET.emit('setUser', {
        user: username,
        token: this.__TOKEN
      });
    }else
    {
      this.__LOGGEDINUSER = username
      this.__SOCKET.emit('setUser', {
        user: username,
        token: this.__TOKEN
      });
    }
  },
  setServerURL: function(url, changeCallback) {
    this.__SERVER = url;

    if(IsRunningAsBrowser() && global.io)
    {
      console.info("Starting Web socket to " + url)
      // eslint-disable-next-line no-undef
      this.__SOCKET = io(url, { transports: ["websocket"] });
      this.__SOCKET.on('changed', changeCallback)
    }

    return this;
  },
  // stripe webhook callbacks
  setSubscriptionChangedCallback: function(callback) {
    this.__SOCKET.on("subscription-changed", () => {
      callback();
    });
  },
  setPMChangedCallback: function(callback) {
    this.__SOCKET.on("pm-changed", () => {
      callback();
    });
  },
  setConfirmationCallback: function(callback) {
    this.__SOCKET.once("requires-confirmation", (data) => 
    {
      callback(data);
    });
  },
  setDiscountCallback: function(callback) {
    this.__SOCKET.once("updated-discount", (data) => 
    {
      callback(data);
    });
  },
  setFile: function(file = undefined, branch = undefined, changelist = undefined) 
  {
    this.__FILE       = file;
    this.__BRANCH     = branch;
    this.__CHANGELIST = changelist;
    return this;
  },
  setUser: function(username) {
    this.__USERNAME = username;
    return this;
  },
  login: async function(username, password) {
    let form = new FormData();
    form.append('password', password);
    form.append('username', username);

    let response = await fetch(`${this.__SERVER}/login`, { 
      method: 'POST' ,
      body: form,
    })
      
    if (response.status === 200) 
    {
      let json = await response.json()
      this.__TOKEN = json.token;
      return json
    }   
    return undefined    
  },
  WaitTillLogededIn: function() 
  {
    if(this.__TOKEN !== null)
    {
      return true
    }

    let prom = new Promise((res, req)=>
    {
      let func = function () 
      {
        console.log("Check if logged in")
        if(this.__TOKEN !== null)
        {
          return res(true)
        }
        setTimeout(func, 50);
      }
      setTimeout(func, 50);
    })

    return prom
      
  },
  validateUserName: async function(username) {
    let response = await fetch(`${this.__SERVER}/login/${username}`, { method: 'GET' }) 
    return response.status === 200   
  },
  getLoggedInUser: async function() {
    let message = await this.tryFetch(`${this.getBaseUrl()}/token`, {  method: 'POST', },`Could not get user`)
    
    if(!message.user) return undefined
    
    this.setLoggedIn(message.user.username)
    
    let user = await this.tryFetch(this.getLoggedInUrl(), {  method: 'GET' }, `Could not get user`)    
    if(window.EhAPI && window.EhAPI.push)
    {
      window.EhAPI.push(["setEmail", user.email]);
      console.log("Connect User ", window.EhAPI)
    }
    return user
  },
  getSelectedAdmin: async function() {
    let message = await this.tryFetch(`${this.getBaseUrl()}/token`, {  method: 'POST', },`Could not get user`)
    
    if(!message.user) return undefined

    let user = await this.tryFetch(this.getUserUrl(), {  method: 'GET' }, `Could not get user`)  
    
    this.setLoggedIn(user.username, true)
    
    if(window.EhAPI && window.EhAPI.push)
    {
      window.EhAPI.push(["setEmail", user.email]);
      console.log("Connect User ", window.EhAPI)
    }
    return user
  },
  createNewProject: function (data) {
    let form = new FormData();
    form.append('projectname', data.Name);
    form.append('prettyName', data.PrettyName);
    form.append('users', JSON.stringify(data.Collaborators));
    form.append('checks', JSON.stringify(data.Checks));
    form.append('invites', JSON.stringify(data.Invites));
    form.append('groups', JSON.stringify(data.Groups));
    if(data.BaseProject) { form.append('baseProject', data.BaseProject); }
    form.append('symbolMapTemplate', data.SymbolMapTemplate);
    form.append('review', data.Review);
    form.append('folder', data.selectedFolder)

    
    if(typeof data.ForUser === 'string') {
      return this.tryFetch(this.getBaseUrl()+"/api/users/" + data.ForUser +"/projects", {
        method : "POST",
        body : form
      },`Could not create project ${data.Name}`)
    }
    return this.tryFetch(this.getLoggedInUrl()+"/projects", {
      method : "POST",
      body : form
    },`Could not create project ${data.Name}`)
  },

  getProject: async function() {
    await window.LR_CheckoutProject({
      Project: this.__PROJECT,
      User: this.__USERNAME,
      Branch: this.__BRANCH,
      Async: true
    })  

    return await this.tryFetch(this.getProjectUrl(), {
      method : "GET",
    }, `Could not get project ${this.__PROJECT}`)
  },
  getProjects: function(arg) {
    if(arg && arg.User) {
      return this.tryFetch(this.getBaseUrl()+'/api/users/'+arg.User+'/projects',{
        method: 'GET'
      }, "Could not get Projects", [])
    }
    return this.tryFetch(this.getUserUrl()+'/projects',{
      method: 'GET'
    }, "Could not get Projects", [])
  },
  getMiniProjects: function(arg) {
    if(arg && arg.User) {
      return this.tryFetch(this.getBaseUrl()+'/api/users/'+arg.User+'/miniprojects',{
        method: 'GET'
      }, "Could not get Projects", [])
    }
    return this.tryFetch(this.getUserUrl()+'/miniprojects',{
      method: 'GET'
    }, "Could not get Projects", [])
  },
  addUser: function(user) {
    return this.tryFetch(this.getProjectUrl()+"/user/"+user, {
      method: "PUT"
    },`Could not add user ${user} to project ${this.__PROJECT}`)
  },
  getProjectMembers: function() {
    return this.tryFetch(this.getProjectUrl()+"/members", {
      method: "GET"
    },`Could not fetch members from ${this.__PROJECT}`)
  },
  getDiffsForCommit: function(id) {
    return this.tryFetch(this.getProjectUrl()+"/cachedchange/" +id, {
      method: "GET"
    },`Could not fetch members from ${this.__PROJECT}`)
  },
  removeUser: function(user) {
    return this.tryFetch(this.getProjectUrl()+"/user/"+user, {
      method: "DELETE"
    },`Could not delete user ${user} to project ${this.__PROJECT}`)
  },
  getToken: async function(token) {
    this.setToken(token);
    let res = await fetch(`${this.getBaseUrl()}/token`, this.auth({
      method: 'POST',
    }))

    let json = await res.json();
    this.setLoggedIn(json.user ? json.user.username : undefined);

    return res.status === 200;
  },
  logout: function() {
    this.__USERNAME = null;
    this.__PROJECT = null;
    this.__TOKEN = null;
    this.__FILE = null;
    this.setLoggedIn(null);
    return this;
  },
  sendChanges: function(buffer, approvedChanges, diffs) {
    this.uploadFile( buffer, approvedChanges, diffs)
    .then(() => {
      console.log("SendApprovedChanges DONE")
    })
    .catch(err => console.error(err));
  },

  getChangesPaginated(page){
    return this.tryFetch(
      `${this.getLoggedInUrl()}/changes?page=${page}`
      , {
      method: 'GET'
    }, "Could not get all Files")
  },

  getAllChanges: function () {
    return this.tryFetch(this.getLoggedInUrl()+'/changes', {
      method: 'GET'
    }, "Could not get all Files")
  },
  compare: function (base, target) {
    return this.tryFetch(this.getProjectUrl()+'/compare/' + base + '/' + target, {
      method: 'GET'
    }, "Could not get difference")
  },
  merge: function (base, target) {
    return this.tryFetch(this.getProjectUrl()+'/merge/' + base + '/' + target, {
      method: 'PUT'
    }, "Could not get difference")
  },
  getMergable: function(base, target) {
    return this.tryFetch(this.getProjectUrl()+'/mergable/' + base + '/' + target, {
      method: 'GET'
    }, "Could not compare")
  },
  getMergeCompare: function(base, target) {
    return this.tryFetch(this.getProjectUrl()+'/mergeCompare/' + base + '/' + target, {
      method: 'GET'
    }, "Could not compare")
  },
  getFilesOfProject: function () 
  {
    let appendix = ""
    if(this.__BRANCH)          { appendix = `/${this.__BRANCH}` }
    else if(this.__CHANGELIST) { appendix = `/${this.__CHANGELIST}` }

    return this.tryFetch(this.getProjectUrl()+`/commits${appendix}`, {
      method: 'GET'
    }, "Could not get File")
  },
  coreCall: function (command, requestData) 
  {
    if(!requestData) { requestData = {} }
    
    let target = {
      user:     this.__USERNAME,
      project:  this.__PROJECT,
      file:     this.__FILE,
      branch:   this.__BRANCH,
      sharetoken: this.__SHARETOKEN
    }

    let token = this.__TOKEN

    let result = new Promise((resolve, reject) => 
    {
      this.__SOCKET.emit("command", {command, arg: requestData, target: target, token}, (res) => 
      {
        if(typeof(res)  !== "object" || !res)
        {
          console.error(res)
          res = {}
        }
        resolve(res)
      })
      setTimeout(()=>reject("timeout"),20000)
    })
    return result
  },
  tryFetch: async function(url, options, err='Error!', defaultValue = {}, returnResOnError=false)  
  {  
    await this.WaitTillLogededIn()
    let res = await fetch(url, this.auth(options))
    if( 200 <= res.status && res.status < 300) 
    {
      try 
      {
        return res.json();
      } 
      catch (_) 
      {
        return {}
      }
    } 
    else
    {
      console.error(err, res.status)
      if (returnResOnError) {
        return res;
      }
      return defaultValue;
    }
  },
  resetPassword: function(password, token) {
    let form = new FormData();
    form.append('password', password);

    return this.setToken(token).tryFetch(this.getBaseUrl() + `/reset`,{
      method  : "POST",
      body    : form,
    })
  },
  changePassword: function(oldpw, newpw) {
    let form = new FormData();
    form.append('oldpw',oldpw)
    form.append('newpw',newpw)

    return this.tryFetch(this.getLoggedInUrl()+"/changepw", {
      method : 'PUT',
      body   : form
    })
  },
  changeDisplayName: function(newUserName) {
    let form = new FormData();
    form.append('newUserName',newUserName)

    return this.tryFetch(this.getLoggedInUrl()+"/changeusername", {
      method : 'PUT',
      body   : form
    })
  },
  generateSigningCertificate: function(location, password) {
    let form = new FormData();
    form.append('singing_service_password',password)
    form.append('singing_service_location',location)

    return this.tryFetch(this.getLoggedInUrl()+"/generateP12", {
      method : 'PUT',
      body   : form
    })
  },
  getPricingQuote: function(js) {
    let form = new FormData();
    form.append('CountSupports',js.PricingArgs.CountSupports)
    form.append('CountCrossSections',js.PricingArgs.CountCrossSections)
    form.append('HighWorkloadSupports',js.PricingArgs.HighWorkloadSupports)
    form.append('HighWorkloadTrussCrossSections',js.PricingArgs.HighWorkloadTrussCrossSections)

    return this.tryFetch(this.getBaseUrl()+"/api/getprice", {
      method : 'PUT',
      body   : form
    })
  },
  removeProject: function() {
    return this.tryFetch(this.getProjectUrl(),{
      method: 'DELETE',
    })
  },
  addResourceCategory: function(name, username) {
    let form = new FormData();
    form.append('name', name)
    if(username)
    {
      return this.tryFetch(this.getBaseUrl()+'/api/userresources/' + username ,{
        method: 'POST',
        body: form
      })
    }else
    {
      return this.tryFetch(this.getBaseUrl()+'/api/resources',{
        method: 'POST',
        body: form
      })
    }
  },
  addResourceSubCategory: function(name, parent, username) {
    let form = new FormData();
    form.append('name', name)
    if(username)
    {
      return this.tryFetch(this.getBaseUrl()+'/api/userresources/' + username + '/' + parent + '/category',{
        method: 'POST',
        body: form
      })
    }
    else
    {
      return this.tryFetch(this.getBaseUrl()+'/api/resources/'+parent+'/category',{
        method: 'POST',
        body: form
      })
    }
  },
  getResources: function(username) {
    if(username)
    {
      return this.tryFetch(this.getBaseUrl()+'/api/userresources/' + username + '/full',{
        method: 'GET',
      })
    }else
    {
      return this.tryFetch(this.getBaseUrl()+'/api/resources/full',{
        method: 'GET',
      })
    }
  },
  addResource: function(category, name, manufacturer, desc, file, username) {
    let reader = new FileReader();
    let ret;
    reader.onload = ( loadedFile => {
      let formData = new FormData()
      formData.append('file', loadedFile)
      formData.append('name', name)
      formData.append('manufacturer', manufacturer)
      formData.append('description', desc)
      console.log(formData)

        if(username)
        {
          ret = fetch( this.getBaseUrl() + "/api/userresources/" + username + "/" + category + "/resource", this.auth({
            method: 'POST',
            body: formData
          }))
        }else
        {
          ret = fetch( this.getBaseUrl() + "/api/resources/"+category+"/resource", this.auth({
            method: 'POST',
            body: formData
          }))
        } 
    
    })(file)
    return ret
  },

  getResource: function(name, username ) {
    if(username)
    {
      return fetch( this.getBaseUrl() + '/api/userresource/'+ username + '/' + name, this.auth({method: 'GET'}))
    }else
    {
      return fetch( this.getBaseUrl() + '/api/resource/'+name, this.auth({method: 'GET'}))
    }
  },
  deleteResource: function(ident, username) {
    if(username)
    {
      return this.tryFetch(this.getBaseUrl() + '/api/userresources/' + username + '/' + ident, {method: 'DELETE'})
    }else
    {
      return this.tryFetch(this.getBaseUrl() + '/api/resource/'+ident, {method: 'DELETE'})
    }
  },
  deleteCategory: function(ident, username) {
    if(username)
    {
      return this.tryFetch(this.getBaseUrl() + '/api/userresources/' + username + '/category/' + ident, {method: 'DELETE'} )
    }else{
      return this.tryFetch(this.getBaseUrl() + '/api/resources/category/' + ident, {method: 'DELETE'} )
    }
  },
  moveCategory: function(toMove, newParent, username) {
    let parent = newParent ? newParent : 'all'
    if(username)
    {
      return this.tryFetch(this.getBaseUrl() + '/api/userresources/' + username + '/category/'+toMove+'/in/'+parent, {method: 'PUT'})
    }else{
      return this.tryFetch(this.getBaseUrl() + '/api/resources/category/'+toMove+'/in/'+parent, {method: 'PUT'})
    }
  },
  moveResource: function(resource, newCategory, username) {
    if(username)
    {
      return this.tryFetch(this.getBaseUrl()+ '/api/userresources/'+ username + '/' +resource+'/in/' + newCategory, {method: 'PUT'})
    }else{
      return this.tryFetch(this.getBaseUrl()+ '/api/resource/'+resource+'/in/'+newCategory, {method: 'PUT'})
    }
  },
  editCategory: function(category, newName, username) {
    let form = new FormData()
    form.append("name", newName)
    if(username)
    {
      return this.tryFetch(this.getBaseUrl()+'/api/userresources/' + username + '/categories/' + category, {
        method: 'PUT',
        body: form
      })
    }else
    {
      return this.tryFetch(this.getBaseUrl()+'/api/resources/categories/' + category, {
        method: 'PUT',
        body: form
      })
    }
  },
  editResource: function(resource, newName, newDesc, username) {
    let form = new FormData()
    form.append("name", newName)
    form.append("desc", newDesc)
    if(username)
    {
      return this.tryFetch(this.getBaseUrl()+'/api/userresources/'+ username + '/' + resource, {
        method: 'PUT',
        body: form
      })
    
    }else
    {
      return this.tryFetch(this.getBaseUrl()+'/api/resource/' + resource, {
        method: 'PUT',
        body: form
      })
    }
  },
  addResourceRevision: function(resource, file, text, username) {
    let reader = new FileReader();
    let ret;
    reader.onload = ( loadedFile => {
      let formData = new FormData()
      formData.append('file', loadedFile)
      formData.append('text', text)

      if(username)
      {
        ret = fetch( this.getBaseUrl() + "/api/userresource/"+username+"/"+resource+"/revision", this.auth({
          method: 'POST',
          body: formData
        }))
      }else
      {
        ret = fetch( this.getBaseUrl() + "/api/resource/"+resource+"/revision", this.auth({
          method: 'POST',
          body: formData
        }))
      }
    })(file)
    return ret
  },

  updateProjectResource:function( projectname, type, data, appendResource, overwritingResource){
    let formData = new FormData()
    formData.append('data',  JSON.stringify(data))
    formData.append('appendResource', appendResource)
    formData.append('overwritingResource', overwritingResource)

    return this.tryFetch(this.getUserUrl()+'/projects/' + projectname + '/resourcecontent/' + type ,{
      method: 'POST',
      body: formData,
    })
  },

  updateProjectTemplate:function( projectname, copyResources, updateObjects, base = "master" , target = "master" )
  {
    let formData = new FormData()
    formData.append('copyResources', copyResources)
    formData.append('updateObjects', updateObjects)

    return this.tryFetch(this.getUserUrl()+'/projects/' + projectname + '/updateTemplate/' + base + '/' + target,{
      method: 'PUT',
      body:formData,
      })
  },
  commit: function() {
    if(this.__SOCKET) {
      this.__SOCKET.emit('commit', () => {})
    }
  },
  addCollaborator: function(collaborator) {
    let formData = new FormData()
    formData.append('collaborator', collaborator)
    return this.tryFetch(this.getUserUrl() + '/collaborator', {
      method: 'PUT',
      body: formData
    })
  },
  getStructuralCalculations: function() 
  {
      return this.tryFetch(this.getUserUrl()+'/signingjobs', {
        method: 'GET'
      }, "Could not get Projects", [])
  },
  getStructuralCalculationsAll: function() 
  {
      return this.tryFetch(this.getBaseUrl()+'/api/signingjobs', {
        method: 'GET'
      }, "Could not get Projects", [])
  },
  startStructuralCalculation: function(args) 
  {
      return this.tryFetch(this.getBaseUrl()+`/api/users/${args.Owner}/projects/${args.Project}/signingjobs/${args.ID}/startProgress`, {
        method: 'PUT'
      })
  },
  cancelStructuralCalculation: function(args) 
  {
      return this.tryFetch(this.getBaseUrl()+`/api/users/${args.Owner}/projects/${args.Project}/signingjobs/${args.ID}/cancel`, {
        method: 'PUT'
      })
  },
  requestStructuralCalculation: function(args) 
  {
      return this.tryFetch(this.getBaseUrl()+`/api/users/${args.Owner}/projects/${args.Project}/signingjobs/${args.ID}/requestFeedback`, {
        method: 'PUT'
      })
  },
  getCollaborators: function(arg) {
    if(arg && arg.User) {
      return this.tryFetch(this.getBaseUrl()+'/api/users/'+arg.User+'/collaborators', {
        method: 'GET'
      })
    }

    

    if(this.__USERNAME) {
      return this.tryFetch(this.getUserUrl()+'/collaborators', {
        method: 'GET'
      })
    } else {
      return this.tryFetch(this.getLoggedInUrl()+'/collaborators', {
        method: 'GET'
      })
    }
  },
  getSharedLicenceUsers: function(arg) {
    if(arg && arg.User) {
      return this.tryFetch(this.getBaseUrl()+'/api/users/'+arg.User+'/sharedLicenceUsers', {
        method: 'GET'
      })
    }
    if(this.__USERNAME) {
      return this.tryFetch(this.getUserUrl()+'/sharedLicenceUsers', {
        method: 'GET'
      })
    } else {
      return this.tryFetch(this.getLoggedInUrl()+'/sharedLicenceUsers', {
        method: 'GET'
      })
    }
  },
  getAdmins: function() {
    return this.tryFetch(this.getUserUrl()+'/admins', {
      method: 'GET'
    })
  },
  getMyAdminsAccounts: function() {
    return this.tryFetch(this.getLoggedInUrl()+'/myadminsaccounts', {
      method: 'GET'
    })
  },
  setAdmins: function(admins) {
    return this.tryFetch(this.getUserUrl()+'/admins', {
      method: 'PUT',
      body: JSON.stringify({admins}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  getContentAdmins: function() {
    return this.tryFetch(this.getUserUrl()+'/contentadmins', {
      method: 'GET'
    })
  },
  setContentAdmins: function(contentAdmins) {
    return this.tryFetch(this.getUserUrl()+'/contentadmins', {
      method: 'PUT',
      body: JSON.stringify({contentAdmins}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  setSharedLicenceUsers: function(collaborator) {
    let formData = new FormData()
    formData.append('collaborator', collaborator)
    return this.tryFetch(this.getUserUrl() + '/sharedLicenceUser', {
      method: 'PUT',
      body: formData
    })
  },
  getBranchById: function(arg) {
    return this.tryFetch(this.getProjectUrl() + '/branches/' + arg.branch, {
      method: 'GET'
    })
  },
  getBranches: function() {
    return this.tryFetch(this.getProjectUrl()+'/branches', {
      method: 'GET'
    })
  },
  createBranch: function(arg) {
    let formData = new FormData()
    formData.append('name', arg.Name)
    return this.tryFetch(this.getProjectUrl()+'/branches', {
      method: 'POST',
      body: formData
    })
  },
  commentOnBranch: async function(arg) {
    let formData = new FormData()
    formData.append('comment', arg.comment)
    return await this.tryFetch(this.getProjectUrl()+'/branches/' + arg.branch + "/comments", {
      method: 'PUT',
      body: formData
    })
  },
  editCommentOnBranch: async function(arg) {
    let formData = new FormData()
    formData.append('comment', arg.comment)
    return await this.tryFetch(this.getProjectUrl()+'/branches/' + arg.branch + "/comments/" + arg.commentId, {
      method: 'PUT',
      body: formData
    })
  },
  deleteCommentOnBranch: async function(arg) {
    let formData = new FormData()
    return await this.tryFetch(this.getProjectUrl()+'/branches/' + arg.branch + "/comments/" + arg.commentId, {
      method: 'DELETE',
      body: formData
    })
  },
  setAssignedUsers: function (arg) {
    let formData = new FormData()
    formData.append('assigned', JSON.stringify(arg.assigned))
    return this.tryFetch(this.getProjectUrl()+'/branches/'+arg.branch+"/assigned", {
      method: 'PUT',
      body: formData
    })
  },
  editFullDescriptionOnBranch: async function(arg) {
    let formData = new FormData()
    formData.append('fullDescription', arg.fullDescription)
    return await this.tryFetch(this.getProjectUrl()+'/branches/' + arg.branch + "/fullDescription" , {
      method: 'PUT',
      body: formData
    })
  },
  toggleChangeListClose: async function(arg) {
    return await this.tryFetch(this.getProjectUrl() + '/branches/' + arg.branch + '/togglecl', {
      method: 'PUT'
    })
  },
  editPullRequestName: async function(arg) {
    let formData = new FormData()
    formData.append('description', arg.description)
    return await this.tryFetch(this.getProjectUrl()+'/branches/' + arg.branch + "/description" , {
      method: 'PUT',
      body: formData
    })
  },
  removeCollaborator: function(collaborator) {
    return this.tryFetch(this.getUserUrl()+'/collaborator/'+collaborator, {
      method: 'DELETE'
    })
  },
  removeSharedLicenceUser: function(collaborator) {
    return this.tryFetch(this.getUserUrl()+'/sharedLicenceUser/'+collaborator, {
      method: 'DELETE'
    })
  },
  setProjectSettings: function(data) {
    let formData = new FormData()
    formData.append('settings', JSON.stringify(data))

    return this.tryFetch(this.getProjectUrl() + '/projectSettings', {
      method: 'PUT',
      body: formData
    })
  },
  setAvatar: function(file) {
    let reader = new FileReader();
    let ret;
    reader.onload = ( loadedFile => {
      let formData = new FormData()
      formData.append('file', loadedFile)
      ret = fetch( this.getBaseUrl() + "/api/users/"+this.__LOGGEDINUSER+"/avatar", this.auth({
        method: 'PUT',
        body: formData
      }))
    })(file)
    return ret
  },
  uploadBlogImg: function (blog_name, file) {
    let reader = new FileReader();
    let ret;
    reader.onload = ( loadedFile => {
      let formData = new FormData()
      formData.append('file', loadedFile)
      ret = fetch( this.getBaseUrl() + "/api/blog/"+blog_name+"/image", this.auth({
        method: 'POST',
        body: formData
      }))
    })(file)
    return ret
  },
  updateBlog: function(name, content, publish) {
    let fd = new FormData()
    fd.append("content", content)
    fd.append("publish", publish)
    return this.tryFetch(this.getBaseUrl()+'/api/blog/'+ name, {
      method: 'PUT',
      body: fd
    })
  },
  createBlog: function(name) {
    let fd = new FormData()
    fd.append("title", name)
    return this.tryFetch(this.getBaseUrl()+'/api/blog', {
      method: 'POST',
      body: fd
    })
  },
  getBlog: function() {
    return this.tryFetch(this.getBaseUrl()+'/api/blog', {
      method: 'GET'
    })
  },
  getBlogAdmin: function() {
    return this.tryFetch(this.getBaseUrl()+'/api/blog_admin', {
      method: 'GET'
    })
  },
  deleteBlog: function(name) {
    return this.tryFetch(this.getBaseUrl()+'/api/blog/'+name, {
      method: 'DELETE'
    })
  },
  getAvatar: function(username) {
    let avatarOfUser = this.__LOGGEDINUSER
    if(username) avatarOfUser = username
    return fetch(this.getBaseUrl() + '/api/users/' + avatarOfUser + '/avatar', this.auth({
      method: 'GET'
    }))
    .then(async res => 
    {
      if(res.status >= 400) 
      {
        return {}
      } 
      else 
      {
        const arrayBufferToBase64 = async ( blob ) => {
          return await new Promise(res => {
            let reader = new FileReader();
            reader.onload = function(evt){
                let dataurl = evt.target.result;
                res(dataurl.substr(dataurl.indexOf(',')+1));
            };
            reader.readAsDataURL(blob);
          })
        }

        return  {
          Src: await arrayBufferToBase64(await res.blob())
        }

      }
    })
    .catch(console.error.bind(console))
  },
  getSlackChannel: function() {
    return this.tryFetch(this.getBaseUrl() + "/api/slack/channels/", {
      method: "GET"
    })
  },
  setSlackChannel: function(slackChannel) {
    let formData = new FormData()
    formData.append('slackchannel', slackChannel)
    return this.tryFetch(this.getProjectUrl()+'/slackchannel', {
      method: 'PUT',
      body: formData
    })
  },

  getProjectTasks: function() {
    return this.tryFetch(this.getProjectUrl() + '/tasks/', {
      method: "GET"
    }, {})
  },

  getWorksheetTask: function(obj) {
    return this.tryFetch(this.getProjectUrl() + '/tasks/' + obj.UUID + '/', {
      method: "GET"
    })
  },

  switchTaskState: function(obj) {
    let formData = new FormData()
    formData.append('state', obj.State)
    return this.tryFetch(this.getProjectUrl() + '/tasks/' + obj.UUID + '/', {
      method: "PUT",
      body: formData
    })
  },

  addCheckToProject: function(obj) {
    let formData = new FormData()
    formData.append('name', obj.Name)
    return this.tryFetch(this.getProjectUrl() + '/checks/', {
      method: "POST",
      body: formData
    })
  },

  addCheckToUser: function(obj) {
    let formData = new FormData()
    formData.append('name', obj.Name)
    return this.tryFetch(this.getLoggedInUrl() + '/checks/', {
      method: "POST",
      body: formData
    })
  },

  toggleProjectOwnerChecks: function(obj) {
    let formData = new FormData()
    formData.append('checked', obj.checked)
    return this.tryFetch(this.getProjectUrl() + '/checks/' + obj.checkId, {
      method: "PUT",
      body: formData
    })
  },

  editScriptInCheck: function(obj) {
    let formData = new FormData()
    formData.append('script', obj.Script)
    return this.tryFetch(this.getBaseUrl() + '/api/checks/' + obj.checkId + "/script", {
      method: "PUT",
      body: formData
    })
  },

  editCheckName: function(obj) {
    let formData = new FormData()
    formData.append('name', obj.Name)
    return this.tryFetch(this.getBaseUrl() + '/api/checks/' + obj.checkId + "/name", {
      method: "PUT",
      body: formData
    })
  },

  removeCheckFromProject: function(obj) {
    return this.tryFetch(this.getProjectUrl() + '/checks/' + obj.checkId, {
      method: "DELETE",
    })
  },

  removeCheckFromUser: function(obj) {
    return this.tryFetch(this.getLoggedInUrl() + '/checks/' + obj.checkId, {
      method: "DELETE",
    })
  },

  removeCheck: function(obj) {
    return this.tryFetch(this.getBaseUrl() + '/api/checks/' + obj.checkId, {
      method: "DELETE",
    })
  },

  getChecksFromProject: function(obj) {
    return this.tryFetch(this.getProjectUrl() + '/checks/', {
      method: "GET",
    })
  },

  getChecksFromUser: function(obj) {
    if(obj && obj.User) {
      return this.tryFetch(this.getBaseUrl()+'/api/users/'+obj.User+'/checks/', {
        method: "GET",
      })  
    }
    return this.tryFetch(this.getLoggedInUrl() + '/checks/', {
      method: "GET",
    })
  },

  getCheckByID: function(obj) {
    return this.tryFetch(this.getBaseUrl() + '/api/checks/' + obj.checkId, {
      method: "GET",
    })
  },

  getCheckRunByID: function(id) {
    return this.tryFetch(this.getBaseUrl() + '/api/runchecks/' + id, {
      method: "GET",
    })
  },

  getProjectOwnerChecks: function(obj) {
    return this.tryFetch(this.getProjectUrl() + '/owner/checks/', {
      method: "GET",
    })
  },

  getUserReviewTemplates: function(arg) {
    if(arg && arg.User) {
      return this.tryFetch(this.getBaseUrl()+'/api/users/'+arg.User + '/reviews', {
        method: "GET",
      })
    }
    return this.tryFetch(this.getUserUrl() + '/reviews', {
      method: "GET",
    })
  },

  editReviewTemplate: function(obj) {
    let formData = new FormData()
    formData.append("review", JSON.stringify(obj.review))
    return this.tryFetch(this.getBaseUrl() + '/api/reviews/' + obj.reviewId, {
      method: "PUT",
      body: formData
    })
  },

  createReviewTemplate: function(obj) {
    let formData = new FormData()
    formData.append("review", JSON.stringify(obj))
    return this.tryFetch(this.getUserUrl() + '/reviews/', {
      method: "POST",
      body: formData
    })
  },

  deleteReviewTemplate: function(obj) {
    return this.tryFetch(this.getUserUrl() + '/reviews/' + obj.reviewId, {
      method: "DELETE",
    })  
  },

  setProjectReviewTemplate: function(obj) {
    return this.tryFetch(this.getProjectUrl() + '/review/' + obj.reviewId, {
      method: "PUT"
    })
  },

  getProjectReviewTemplate: function() {
    return this.tryFetch(this.getProjectUrl() + '/review', {
      method: "GET"
    })
  },

  setReview: function(reviewID) {
    this.REVIEW_ID = reviewID
  },

  // setReview needs to be called before
  getReviewByID: function() {
    return this.tryFetch(this.getBaseUrl() + '/api/reviews/' + this.REVIEW_ID, {
      method: "GET"
    })
  },

  toggleReviewInstance: function(obj) {
    let formData = new FormData()
    formData.append("approved", obj.approved)
    return this.tryFetch(this.getLoggedInUrl() + "/reviewInstances/" + obj.reviewInstanceId, {
      method: "PUT",
      body: formData
    })
  },

  getGroups: function(arg) {
    if(arg && arg.User) {
      return this.tryFetch(this.getBaseUrl()+'/api/users/'+arg.User + "/groups", {
        method: "GET"
      })
    } else if (this.__USERNAME) {
      return this.tryFetch(this.getUserUrl() + "/groups", {
        method: "GET"
      })
    }
    return this.tryFetch(this.getLoggedInUrl() + "/groups", {
      method: "GET"
    })
  },

  setGroups: function(groups) {
    /* let formData = new FormData()
    formData.append("groups", groups) */
    return this.tryFetch(this.getUserUrl() + "/groups", {
      method: 'POST',
      body: JSON.stringify({groups}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },

  updateMembers: function(members, groups) {
    return this.tryFetch(this.getProjectUrl() + "/members", {
      method: 'PUT',
      body: JSON.stringify({members, groups}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },

  updateSubscription: function(promoCode, priceId, customerId, countryCode, vat) {
    let formData = new FormData()
    formData.append("priceId", priceId)
    formData.append("countryCode", countryCode)
    formData.append("vat", vat)
    formData.append("promoCode", promoCode,)
    return this.tryFetch(this.getLoggedInUrl() + "/subscriptions/" + customerId + "/plan", {
      method: 'POST',
      body: formData
    },'Error handled by ui component', undefined, true)
  },

  updateProSeats: function(promoCode, add, customerId, priceId) {
    let formData = new FormData()
    formData.append("add", add)
    formData.append("priceId", priceId)
    formData.append("promoCode", promoCode,)
    return this.tryFetch(this.getLoggedInUrl() + "/subscriptions/" + customerId + "/seats", {
      method: 'POST',
      body: formData
    },'Error handled by ui component', undefined, true)
  },

  addPaymentMethod: function(paymentMethodId, customerId, billingInformation, oldVat) {
    let formData = new FormData()
    formData.append("paymentMethodId", paymentMethodId)
    formData.append("billingInformation", JSON.stringify(billingInformation))
    formData.append("oldVat", oldVat)
    return this.tryFetch(this.getLoggedInUrl() + "/subscriptions/" + customerId + "/paymentMethod", {
      method: 'POST',
      body: formData
    },'Error handled by ui component', undefined, true)
  },

  scaSuccess: function(paymentMethodId, oldPMId, customerId) {
    let formData = new FormData()
    formData.append("paymentMethodId", paymentMethodId)
    formData.append("oldPMId", oldPMId)

    return this.tryFetch(this.getLoggedInUrl() + "/subscriptions/" + customerId + "/sca-success", {
      method: 'POST',
      body: formData
    },'Error handled by ui component', undefined, true)
  },

  getCustomerInformation: function() {
    return this.tryFetch(this.getLoggedInUrl() + "/customerInformation", {
      method: 'GET',
    })
  },

  getPaymentHistory: function() {
    return this.tryFetch(this.getUserUrl() + "/paymentHistory", {
      method: 'GET',
    })
  },

  setUserType: function(type) {
    return this.tryFetch(this.getUserUrl()+ "/usertype", {
      method: 'PUT',
      body: JSON.stringify({usertype: type}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },

  getUserOrgs: function(arg) {
    if(arg && arg.User) {
      return this.tryFetch(this.getBaseUrl()+'/api/users/'+arg.User+'/organizations', {
        method: 'GET'
      })
    }
    return this.tryFetch(this.getLoggedInUrl()+'/organizations', {
      method: 'GET'
    })
  },
  getUserInfo: function(arg) {
    if(arg && arg.User) {
      return this.tryFetch(this.getBaseUrl()+'/api/users/'+arg.User+'/', {
        method: 'GET'
      })
    }
    return this.tryFetch(this.getLoggedInUrl()+'/', {
      method: 'GET'
    })
  },

  createApiToken: function(tokenname) {
    return this.tryFetch(this.getUserUrl()+ "/apiToken", {
      method: 'PUT',
      body: JSON.stringify({tokenname}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },

  deleteApiToken: function(tokenname) {
    return this.tryFetch(this.getUserUrl()+ "/apiToken", {
      method: 'DELETE',
      body: JSON.stringify({tokenname}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },

  getApiTokens: function() {
    return this.tryFetch(this.getUserUrl()+"/apiTokens", {
      method: 'GET'
    })
  },
  updateGroupsForSymbolMap: function(template, groups) {
    let formData = new FormData()
    formData.append('data', JSON.stringify(groups))
    return this.tryFetch(this.getUserUrl() + "/resourcecontent/symbolMap/" + template + "/groups", {
      method: 'PUT',
      body: formData
    })
  },
  getSymbolMapTemplates: function(arg) {
    if(arg && arg.User) {
      return this.tryFetch(this.getBaseUrl()+'/api/users/'+arg.User+"/resourcecontent/symbolMapTemplates", {
        method:'GET'
      })
    }
    return this.tryFetch(this.getUserUrl()+"/resourcecontent/symbolMapTemplates", {
      method:'GET'
    })
  },
  createSymbolMapTemplate: function(arg) {
    let formData = new FormData()
    formData.append('data', JSON.stringify({symbolMap: []}))
    return this.tryFetch(this.getUserUrl()+"/resourcecontent/symbolMap/" + arg.Name, {
      method:'POST',
      body: formData
    })
  },
  updateSymbolMapTemplate: function(arg) {
    if(!arg.templateName && !arg.templateData){
      return Promise.resolve()
    }

    console.log(arg, arg.templateName)

    let formData = new FormData()
    formData.append('data', JSON.stringify({symbolMap: arg.templateData}))
    return this.tryFetch(this.getUserUrl()+"/resourcecontent/symbolMap/" + arg.templateName, {
      method:'POST',
      body: formData
    })
  },
  updateDefaultTemplates: function(defaultTemplates) {
    return this.tryFetch(this.getUserUrl() + "/defaultTemplates", {
      method: 'PUT',
      body: JSON.stringify(defaultTemplates),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  setLdapConfig: function(ldapUris,ldapDomain) {
    let data = {
      ldapUris: ldapUris,
      ldapDomain: ldapDomain,
    };
    return this.tryFetch(this.getUserUrl()+"/ldap/", {
      method:'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  createNewNote: function(newnote) {
    const body = JSON.stringify({newnote})
    return this.tryFetch(this.getProjectUrl()+"/note", {
      method:'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  deleteLastCommitFromBranch: function(branch) {
    return this.tryFetch(this.getProjectUrl()+"/files/" +branch, {
      method:'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    },
    "deleteLastCommitFromBranch failed",
    {},
    true
   )
  },
  getNotesForObjects: function(params) {
    return this.tryFetch(this.getProjectUrl() + "/worksheets/" + params.Worksheet + "/notes", {
      method: "GET"
    })
  },
  findUserSubset: function(searchQuery) { 
    // where searchQuery should be a substring for usernames
    return this.tryFetch(this.getBaseUrl() + "/api/users-subset/" + searchQuery, {
      method: 'GET'
    })
  },
  createShareLink: function(name) {
    return this.tryFetch(this.getProjectUrl() + "/shareLink", {
      method: 'POST',
      body: JSON.stringify({tokenname: name}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  deleteShareLink: function(name) {
    return this.tryFetch(this.getProjectUrl() + "/shareLink", {
      method: 'DELETE',
      body: JSON.stringify({tokenname: name}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  getShareLinks: function() {
    return this.tryFetch(this.getProjectUrl() + "/shareLinks", {
      method: 'GET',
    })
  },
  testAccess: function(username, projectname) {
    return this.tryFetch(this.getBaseUrl() + "/api/users/" + username + "/projects/" + projectname + "/testAccess", {
      method: 'GET'
    })
  },
  getCustomDataOnProject: function() {
    return this.tryFetch(this.getProjectUrl() + "/customData", {
      method: 'GET'
    })
  },
  setCustomDataOnProject: function(newCustomData) {
    return this.tryFetch(this.getProjectUrl() + "/customData", {
      method: 'PUT',
      body: JSON.stringify({customData: newCustomData}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  createPromoCodeForUser: function(username) {
    let formData = new FormData()
    formData.append("recipient", username)
    return this.tryFetch(this.getUserUrl() + "/promo-code/create", {
      method: 'PUT',
      body: formData,
    })
  },
  checkPromoCodeForUser: function(promoCode) {
    return this.tryFetch(this.getUserUrl() + "/promo-code/" + promoCode + "/check", {
      method: 'GET'
    })
  },
  applyPromoCodeForUser: function(promoCode) {
    let formData = new FormData()
    formData.append("promoCode", promoCode)
    return this.tryFetch(this.getUserUrl() + "/promo-code/apply", {
      method: 'POST',
      body: formData,
    })
  },
  checkCurrentDiscount: function() {
    return this.tryFetch(this.getUserUrl() + "/discount", {
      method: "GET"
    })
  },
  setMasterLock: function(locked) {
    return this.tryFetch(this.getProjectUrl() + "/masterlock", {
      method: 'PUT',
      body: JSON.stringify({masterLocked: locked}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  inviteUser: function(mail) {
    return this.tryFetch(this.getProjectUrl() + "/invite", {
      method: 'PUT',
      body: JSON.stringify({mail}),
      headers: {
        'Content-Type': 'application/json'
      }
    }, '', undefined, true)
  },
  getSubscriptionPrices: function() {
    return this.tryFetch(this.getBaseUrl() + "/api/subscription-prices", {
      method: "GET"
    })
  },
  setUserInfo: function(userInfo) {
    return this.tryFetch(this.getUserUrl() + "/userInfo", {
      method: 'PUT',
      body: JSON.stringify(userInfo),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  createStructuralVoucher: function(arg) {
    return this.tryFetch(this.getBaseUrl()+'/api/signingjobs/voucher', {
      method: 'POST',
      body: JSON.stringify(arg),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },

  updateFolders: function(folders) {
    return this.tryFetch(this.getUserUrl() + "/folders",{
      method: 'PUT',
      body: JSON.stringify(folders),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },

  getDefaultTemplatesOfSelectedUser: function(username){
    return this.tryFetch(`${this.getBaseUrl()}/api/users/${username}/defaultTemplates`, {
      method: "GET"
    })
  }

}
window.lrCon = lrServerConnection
