import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form } from "semantic-ui-react";
import LocalizedStrings from "../../localization/TableViewComponent";
import LRModal from "../Basics/BasicModal";
import PrintSettings, { IPdfFormat } from "../GlobalSettings/PrintSettings";
import { globalWindowInterface } from "../../util/callbackTypes";
import { IMarginInterface } from "./TableToPdfModal";

declare const window: globalWindowInterface;

export default function CustomPDFFormatPicker({
    PDFFormat,
    margins,
    usePaperSettingsFromGlobal,
    onChangeUsePaperSettingsFromGlobal,
    onChangePdfFormat,
    onChangeMarginSettings
}: {
    PDFFormat: IPdfFormat,
    margins: IMarginInterface,
    usePaperSettingsFromGlobal: boolean,
    onChangeUsePaperSettingsFromGlobal: (val: boolean) => void,
    onChangePdfFormat: (format: IPdfFormat) => void,
    onChangeMarginSettings: (margins: IMarginInterface) => void
}) {
    const [paperFormatOpen, setPaperFormatOpen] = useState(false)

    const updateFromProjectSettings = () => {
        window.LR_GetProjectSettings().then((res) => {
            if (res) {
                let i = res.DrawingSettings
                onChangeMarginSettings({
                    MarginTop: i.MarginTop,
                    MarginBottom: i.MarginBottom,
                    MarginLeft: i.MarginLeft,
                    MarginRight: i.MarginRight,
                    Height: i.Height,
                    Width: i.Width,
                })
                onChangePdfFormat(res.PdfFormat)
            }
        })
    }

    useEffect(()=>{
        if(PDFFormat === undefined)
        {
            updateFromProjectSettings()
        }
    }, [])

    return <>
        <Form.Group style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 0 }} >
            <Checkbox
                style={{ marginRight: 15 }}
                toggle
                label={LocalizedStrings.MarginsFromFormat}
                name={"MarginsFromFormat"}
                data-tooltip={LocalizedStrings.MarginsFromFormatPopup}
                checked={usePaperSettingsFromGlobal}
                onChange={async (name, { checked }) => onChangeUsePaperSettingsFromGlobal(checked)}
            />
            <Button disabled={usePaperSettingsFromGlobal} onClick={() => setPaperFormatOpen(true)}>{LocalizedStrings.OpenFormatSettings}</Button>
        </Form.Group>
        <LRModal
            open={paperFormatOpen}
            title={LocalizedStrings.PDFFormatSettings}
            onOkClick={() => setPaperFormatOpen(false)}
            noCancel
            additionalActions={<Button onClick={() => updateFromProjectSettings()}>{LocalizedStrings.ResetToSettings}</Button>}
        >
            {
                PDFFormat ?
                    <PrintSettings
                        format={PDFFormat}
                        onFormatChange={(format, name, val) => onChangePdfFormat({ ...PDFFormat, [format]: { ...PDFFormat[format], [name]: val } })}
                        marginSettings={margins}
                        onMarginChange={(name, val) => onChangeMarginSettings({ ...margins, [name]: val })}
                    />
                    :
                    null
            }

        </LRModal>
    </>
}