import React, { Component } from 'react';
import { Button, Form, Icon, Message, Table } from 'semantic-ui-react';
import { BASE_UNIT_POWER, IsRunningAsBrowser } from '../../util/defines';
import LocalizedStrings from "../../localization/ResourceManager";
import DynamicTable from '../Basics/DynamicTableView';
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';
import { globalCallbacks } from '../../util/callback';
import { cie2hex } from '../ColorPicker/utilities';
import ColorInputField from '../ColorPicker/ColorInputField';

const DEFAULT_COLOR = {X:0.3209, Y: 0.1542, Z: 28.48};
class ConnectorTemplates extends Component {
    constructor(props){
        super(props);

        this.state = {
            connectorTemplates: [],
            createModalOpen: false,
            modalValues: {Color: DEFAULT_COLOR, SocketInfo: []},
            socketNode: {Name: "", Color: DEFAULT_COLOR},
            createSocketModalOpen: false
        };
    }

    componentDidMount = async () => {
        this.setUpCallback();
        let connectorData = await window.LR_GetConnectors_Data();
        let electricConnectors = await window.LR_GetConnectors_Electric();

        console.log(connectorData)

        this.setState({connectorTemplates: [...connectorData.Connectors, ...electricConnectors.Connectors]});
    };

    openCreateModal = () => this.setState({ createModalOpen: true});

    setModalValues = (key, value) => this.setState({ modalValues: {...this.state.modalValues, [key]: value}});

    setSocketValues = (key, value) => this.setState({ socketNode: {...this.state.socketNode, [key]: value}});

    onDeleteConnectorTemplate = (entry) => 
    {
        window.LR_DeleteConnectorsTemplate({Name: entry.ConnectorName}).then(() => {
            if(globalCallbacks.updateConnectorTemplates && IsRunningAsBrowser){
                globalCallbacks.updateConnectorTemplates();
            }
        })
    };

    render() {
        //----------------------------------------------------
        // define dynamic table view props

        const headerIdent = [
            {editable: false, unit: undefined, sortable: true, ident: "ConnectorName", label: LocalizedStrings.ConnectorName},
            {editable: false, unit: undefined, sortable: true, ident: "LocalizedName", label: LocalizedStrings.LocalizedName},
            {editable: false, unit: undefined, sortable: true, ident: "Color",         label: LocalizedStrings.Color},
            {editable: false, unit: undefined, sortable: true, ident: "Default",       label: LocalizedStrings.Default},
            {editable: false, sortable: true, ident: "MaxPayload",    label: LocalizedStrings.MaxPayload, unit: BASE_UNIT_POWER},
            {editable: false, unit: undefined, sortable: true, ident: "IsData",        label: LocalizedStrings.IsData},
        ];

        const cellRender = (entry, rowIndex) =>
        {
            return {
                ConnectorName: (
                <>
                    {!entry.Default && (
                        <Icon
                            name="delete"
                            color="red"
                            link
                            textAlign="right"
                            onClick={() => this.onDeleteConnectorTemplate(entry)}
                        />
                    )}
                    {entry.ConnectorName}
                </>
                ),
                Color: ( <div style={{ width: "4rem", height: "1rem", backgroundColor: cie2hex(entry.Color)}} />),
                Default:    entry.Default ? "yes" : "no",
                MaxPayload: entry.MaxPayload / 1000,
                IsData:     entry.IsData ? "yes" : "no",
                LocalizedName:     entry.LocalizedName 
            };
        };
        
    const cellData = this.state.connectorTemplates.map(el => ({...el, editable: !el.Default}));

    const footer = (
        <Table.HeaderCell colSpan="29">
          <Button
            floated="left"
            icon
            labelPosition="left"
            primary
            onClick={this.openCreateModal}
            size="small"
          >
            <Icon name="" />
            {LocalizedStrings.CreateConnector}
          </Button>
        </Table.HeaderCell>
    );

    //----------------------------------------------------
    // render

    return (
        <>
         {cellData.length > 0 ? (
          <DynamicTable
            cellRender={cellRender}
            headerIdent={headerIdent}
            cellData={cellData}
            footerRow={footer}
          />
        ) : (
          <>
            <Message style={{ width: "100%" }} warning>
              <Message.Header>
                {LocalizedStrings.NoConnectorTemplatesHeader}
              </Message.Header>
              <p>{LocalizedStrings.NoConnectorTemplatesText}</p>
            </Message>
            <Button
              floated="left"
              icon
              labelPosition="left"
              primary
              onClick={this.openCreateModal}
              size="small">
              <Icon name="" />
              {LocalizedStrings.CreateConnector}
            </Button>
          </>
        )}  
        {this.renderEditModal()}
        {this.renderCreateSocketModal()}
        </>
    )
    }

    //----------------------------------------
    // EDIT MODAL
    //----------------------------------------

    renderEditModal = () =>
    {
        return (
            <LRModal
                title={LocalizedStrings.CreateConnectorHeader}
                open={this.state.createModalOpen}
                onCancelClick={this.onDiscardModal}
                onOkClick={this.onOkClick} >
                    <Form>
                        <Form.Input
                            value    = {this.state.modalValues.ConnectorName}
                            label    = {LocalizedStrings.Name}
                            onChange = {(e, { value }) => {
                                this.setModalValues("ConnectorName", value)}}/>
                        
                        <Form.Group widths="equal">
                            <ColorInputField
                                label           = {LocalizedStrings.Color} 
                                labelPosition   = 'right' 
                                colorX          = {this.state.modalValues.Color.X} 
                                colorY          = {this.state.modalValues.Color.Y} 
                                colorL          = {this.state.modalValues.Color.Z}
                                onColorChanged  = {(cie) => this.setModalValues("Color", {X: cie.fx, Y: cie.fy, Z: cie.f_Y})}/>

                            <UnitInput  
                                name     = "MaxPayload" 
                                value    = {this.state.modalValues.MaxPayload}
                                label    = {LocalizedStrings.MaxPayload}
                                baseUnit = {BASE_UNIT_POWER}
                                min      = {0}
                                onStateUpdate = {(name, value, e) => {
                                    this.setModalValues(name, value) }}/>
                            
                            <Form.Checkbox
                                style    = {{marginLeft: '1rem'}}
                                value    = {this.state.modalValues.IsData}
                                label    = {LocalizedStrings.IsData}
                                onChange = {(_, {checked}) => {
                                    this.setModalValues("IsData", checked) }}/>
                        </Form.Group>

                    </Form>

                    <Table celled>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3'>
                                {LocalizedStrings.SocketInformation}
                                <Button primary floated='right' onClick={this.openCreateSocketModal}>{LocalizedStrings.AddSocket}</Button>
                            </Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{LocalizedStrings.Name}</Table.Cell>
                                <Table.Cell>{LocalizedStrings.Color}</Table.Cell>
                            </Table.Row>
                            {this.state.modalValues?.SocketInfo?.map((item, index) => (<SocketNode onDeleteSocket={this.onDeleteSocket} key={index} socketNode={item}/>))}
                        </Table.Body>
                    </Table>

            </LRModal>
        )
    }

    renderCreateSocketModal = () =>
    { 
        return(
            <LRModal
                title={LocalizedStrings.AddSocket}
                open={this.state.createSocketModalOpen}
                onCancelClick={this.onDiscardSocketModal}
                onOkClick={this.onSocketModalOkClick}
                okDisabled={this.state.modalValues.SocketInfo.some((socket) => socket.Name === this.state.socketNode.Name)}>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Input
                            value    = {this.state.socketNode.Name}
                            label    = {LocalizedStrings.Name}
                            onChange = {(e, { value }) => {this.setSocketValues("Name", value)}}/>

                        <ColorInputField
                            label           = {LocalizedStrings.Color} 
                            labelPosition   = 'right' 
                            colorX          = {this.state.socketNode.Color.X} 
                            colorY          = {this.state.socketNode.Color.Y}  
                            colorL          = {this.state.socketNode.Color.Z} 
                            onColorChanged  = {(cie) => this.setSocketValues("Color", {X: cie.fx, Y: cie.fy, Z: cie.f_Y})}/>
                    </Form.Group>
                </Form>
            </LRModal>
        )
    }

    onDiscardModal = () =>
    {
        this.setState({ createModalOpen: false, modalValues: {Color: DEFAULT_COLOR, SocketInfo: []}})
    }

    onOkClick = async () =>
    {
        if(this.state.createModalOpen){
        window.LR_AddConnectorsTemplate(this.state.modalValues).then(() => {
            if (globalCallbacks.updateConnectorTemplates && IsRunningAsBrowser) { globalCallbacks.updateConnectorTemplates() }
        })
        } else if(this.state.modalValues) {
            window.LR_SetConnectorsTemplate(this.state.modalValues).then(() => {
                if (globalCallbacks.updateConnectorTemplates && IsRunningAsBrowser) { globalCallbacks.updateConnectorTemplates() }
            })
        }

        this.setState({
            createModalOpen: false, modalValues: {Color: DEFAULT_COLOR, SocketInfo: []}});
    }

    openCreateSocketModal = () => this.setState({createSocketModalOpen: true});
    

    onDiscardSocketModal = () => this.setState({createSocketModalOpen: false, socketNode: {Name: "", Color: DEFAULT_COLOR}});
    

    onSocketModalOkClick = () => 
    {
        const { socketNode, modalValues } = this.state;

        const updatedSocketInfo = modalValues.SocketInfo.concat(socketNode);

        this.setState({
            createSocketModalOpen: false,
            socketNode: { Name: "", Color: { X: 0.3209, Y: 0.1542, Z: 28.48 } },
            modalValues: { ...modalValues, SocketInfo: updatedSocketInfo },
        });
    };

    onDeleteSocket = (socketToDelete) => {
        const updatedSocketInfo = this.state.modalValues.SocketInfo.filter(
            (socket) => socket !== socketToDelete
        );

        this.setState({
            modalValues: { ...this.state.modalValues, SocketInfo: updatedSocketInfo },
        });
    };

    setUpCallback = () =>
    {
        globalCallbacks.updateConnectorTemplates = async () =>
        {
            this.setState({ isLoading: true});
            let connectorData = await window.LR_GetConnectors_Data();
            let electricConnectors = await window.LR_GetConnectors_Electric();
            this.setState({ connectorTemplates: [...connectorData.Connectors, ...electricConnectors.Connectors], isLoading: false})
        }
    }

}

class SocketNode extends Component {
    constructor(props){
        super(props);
    }

    render()
    {
        if(!this.props.socketNode) {return;}

        return(
            <Table.Row>
                <Table.Cell>{this.props.socketNode.Name}</Table.Cell>
                <Table.Cell>
                    <div style={{width: "4rem", height: "1rem", backgroundColor: cie2hex(this.props.socketNode.Color)}} />
                </Table.Cell>
                <Table.Cell>
                    <Icon
                        name="delete"
                        color="red"
                        link
                        onClick={() => this.props.onDeleteSocket(this.props.socketNode)}
                    />
                </Table.Cell>

            </Table.Row>
        )
    }
}

export default ConnectorTemplates