//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form, Label, Icon } from 'semantic-ui-react'
import EditColorModalControl from './SelectColor'

import {cie2hex} from "./utilities"

class ColorInputField extends Component
{

  constructor(props) 
  {
    super(props)

    this.state = {
      open: false,
    }
  }

  render()
  {
    let hex = cie2hex({ fx : this.props.colorX, fy : this.props.colorY, f_Y : this.props.colorL });
    let textColor = this.props.colorL > 50 ? "black" : "white";

    let disabled = false;
    if(this.props.disabled) { disabled = true }

    return (
    <Form.Input 
        size={this.props.size} 
        disabled={disabled} 
        readOnly 
        placeholder={this.props.placeholder} 
        name={this.props.name} 
        value={this.props.textValue ?? (this.props.colorX.toFixed(2) + ", " + this.props.colorY.toFixed(2) + ", " + this.props.colorL.toFixed(0))} 
        label={this.props.label}  
        labelPosition={this.props.labelPosition}
        fluid>
      <input style={{backgroundColor:`${hex}`, color:`${textColor}`}}/>
      { this.props.readOnly ? null :
      <Label>
        <Icon onClick={this.onClick} name="edit" link fitted />
        <EditColorModalControl onClose={this.onClose} open={this.state.open} title={this.props.title} colorX={this.props.colorX} colorY={this.props.colorY} colorL={this.props.colorL} onColorChanged={this.props.onColorChanged}/>
        {this.props.icons}
      </Label>}
    </Form.Input>
    )
  }

  onClose = (e) => {
    this.setState({open: false})
    e.stopPropagation()
  }

  onClick = () => {
    this.setState({open: true})
  }
}

export default ColorInputField