
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Checkbox, Divider, Dropdown, Form, Header, Loader } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/SceneTreeComponent";
import { EMPTY_UUID } from '../../util/defines';
import LRFilterInput from '../Basics/FilterField';
import TreeNode from './TreeNode';
import { globalCallbacks } from "../../util/callback";
import { Mutex } from 'async-mutex';

const filterOptions_NoSelected = {
  onlyConsumer: false,
  onlyDistributer: false,
  onlyPlugBox: false,
  onlyAssemblyGroup: false,
  onlyGenerator: false,
  onlyFixtures: false,
  onlyStructures: false,
  onlySupport: false,
  onlyAudio: false,
  onlyMeshes: false,
  onlyVisible: false
}

const ELEMENT_HEIGHT = 30
const ELEMENT_WIDTH = 200

const HEIGHT_CALC = "calc(100% - 87px - 1rem)"

class SceneTreeHierarchy extends Component 
{
  constructor(props)
  {
    super(props);
    this.tree    = [];
    this.treeMap = new Map();
    this.filteredTreeMap = new Map();

    this.selectionList = [];

    this.state = 
    {
      StructuralCalculationObjects: {},
      loading:      false,
      tree          : [],
      dragged       : undefined,
      change        : false,
      searchText    : "",
      filterOpen: false,
      toRender:[],
      countDisplay:0,

      filterOptions : {
        onlyConsumer: true,
        onlyDistributer: true,
        onlyPlugBox: true,
        onlyAssemblyGroup: true,
        onlyGenerator: true,
        onlyFixtures: true,
        onlyStructures: true,
        onlySupport: true,
        onlyAudio: true,
        onlyMeshes: true,
        onlyVisible: true
      },

      DuplicatedFixtureIds: [],
      DuplicatedObjectIds: [],


      activeDrawing : "path",
      drawings      : [
        {
          key   : 0,
          value : "path",
          text  : "A Sheep",
        },
      ],

      scrollOffset: 0,
    }

    this.scrollRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState)
  {
    if(prevState.filterOptions !== this.state.filterOptions ||
      prevState.change !== this.state.change)
    {
      this.recalculateObjectsToDisplay()
    }
    
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
    globalCallbacks.getSceneTree();
    globalCallbacks.displayDrawingMenu();
    
    if(this.props.node) { this.props.node.setEventListener("close", (p) => {  this.props.onClose() }) }
  }

  // Get the total count of children considering the Expanted state, when the expanded state is true, than it return the count of all childrens
  getNodeCount = (ignoreExpanded = false) => (node) =>
  {
    let count = 1;

    if (node.Expanded || ignoreExpanded) {
      count += node.children
        .map(this.getNodeCount(ignoreExpanded))
        .reduce(function(total, count) {
          return total + count;
        }, 0);
    }

    return count;
  }

// Returns the count of children that of a non expanded node not regarding the Expanded state of the children. Return 0 when the the object itself is expanted.
  getHiddentNodeCount = (node) =>
  {
    let result = 0;

    if (!node.Expanded) {
      result += node.children
        .map(this.getNodeCount(true))
        .reduce(function(total, count) {
          return total + count;
        }, 0);
    }

    return result;
  }

    //--------------------------------------------------------------------------------------------------------
    // Calculate the cound of total display objects matching the filter
  async recalculateObjectsToDisplay()
  {
    let countDisplay = 0;
    let toRender     = []
    for (let i = 0; i < this.tree.length; i++)
    {
      let item = this.tree[i]
      if (!this.shouldItemDisplay(item))
      {
        i = i + this.getNodeCount(true)(item) - 1
        continue;
      }

      if (!item.Expanded)
      {
        i = i + this.getNodeCount(true)(item) - 1
        
      }

      countDisplay++
      toRender.push(item)

    }

    this.setState({toRender, countDisplay})
  }

  render() 
  {
    //--------------------------------------------------------------------------------------------------------
    // Calculates the first index of display objects based on the current scrolled pixes.
    let startEntryIndex = Math.floor(this.state.scrollOffset / ELEMENT_HEIGHT)
        
  

    //--------------------------------------------------------------------------------------------------------
    // Render the 50 items following the calculated start entry
    let renderedItems = []
    for (let i = 0; i < 50; i++)
    {
      // Get current rendered node index
      let currentIndex = i + startEntryIndex
      //Get tree node object from tree array
      let item = this.state.toRender[currentIndex]

      if (item)
      {

      
        // Get current parent of treeNode, if parent is children of higher parent, get higher parent
        let parentCount = 0;
        let currentParent = this.treeMap.get(item.ParentUUID)
        while (currentParent)
        {
          parentCount++
          currentParent = this.treeMap.get(currentParent.ParentUUID)
        }
        //push treeNode to renderedItems array
        renderedItems.push( <div key         = {item.UUID} style={{position:"absolute", paddingLeft: 20 * (parentCount + 1), marginTop: (-this.state.scrollOffset % ELEMENT_HEIGHT) + (i * ELEMENT_HEIGHT), height: ELEMENT_HEIGHT, width: ELEMENT_WIDTH}}>
                              <TreeNode key         = {item.UUID}
                                        change      = {this.state.change}
                                        UUID        = {item.UUID}
                                        onSelectObject= {this.onSelectObject}
                                        selected    = {item.Selected}
                                        LoadConnectedMeasured    = {item.LoadConnectedMeasured}
                                        LoadConnectedCalculated    = {item.LoadConnectedCalculated}
                                        HasCableSelected    = {item.HasCableSelected}
                                        existing    = {item.Existing}
                                        expanded    = {item.Expanded}
                                        calculationEntry = {this.state.StructuralCalculationObjects[item.UUID]}
                                        useCalculationEntry = {this.state.UseStructuralCalculationObjects}
                                        name        = {item.Name}
                                        duplicatedFid = {this.state.DuplicatedFixtureIds}
                                        duplicatedOid = {this.state.DuplicatedObjectIds}
                                        hasFid      = {!!item.FixtureId}
                                        fixtureId   = {item.FixtureId ? item.FixtureId : item.ObjectId}
                                        searchText  = {this.state.searchText}
                                        filterOptions={this.state.filterOptions}
                                        resourceType = {item.ResourceType}
                                        isFullyConnected = {item.IsFullyConnected}
                                        isElectrical = {item.IsElectrical}
                                        dragged     = {this.state.dragged} 
                                        onDragStart = {this.onDragStart}
                                        onDragEnd   = {this.onDragEnd}>
                                        {item.children}
                              </TreeNode>
                            </div>)
      }
    }

    return (
        <div  style       = {{padding: "10px 10px 0px 10px", overflow: "hidden", height:"calc(100% - 10px)"}}
              onDragOver  = {(e) => e.preventDefault()} //necessary to fire onDrop
              onDrop      = {this.onDrop}> 
          <Loader active={this.state.loading} />
          <Form style={{marginBottom: 0}}>
            <Header as="h5">
              {LocalizedStrings.Header}
              {" "}
              <Dropdown
                inline
                closeOnChange
                disabled  ={window.IsVectorworksContext} // On Vectorworks we don't was to switch this
                options   = {this.state.drawings}
                value     = {this.state.activeDrawing}
                onChange  = {this.openDrawing}
                />
              </Header>
            
            <Form.Group widths="equal" style={{marginBottom: 0}}>
              <Form.Dropdown multiple text={LocalizedStrings.Filter} onOpen={(e) => {this.setState({filterOpen: true})}} onClose={(e) => {this.setState({filterOpen: false})}}>
                <Dropdown.Menu style={{height:"30rem", overflowY:"auto", marginTop:"1rem"}} open={this.state.filterOpen}>
                  <div style={{display: "flex", flexDirection: "column", justifyContent: "center", padding: "15px"}}>
                    <h5 style={{margin: "0px"}}>{LocalizedStrings.FilterHeader}</h5>
                    <Checkbox label={LocalizedStrings.onlyConsumer} checked={this.state.filterOptions.onlyConsumer} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyConsumer: checked}, e.altKey)}/>
                    <Checkbox label={LocalizedStrings.onlyDistributer} checked={this.state.filterOptions.onlyDistributer} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyDistributer: checked}, e.altKey)}/>
                    <Checkbox label={LocalizedStrings.onlyPlugBox} checked={this.state.filterOptions.onlyPlugBox} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyPlugBox: checked}, e.altKey)}/>
                    <Checkbox label={LocalizedStrings.onlyGenerator} checked={this.state.filterOptions.onlyGenerator} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyGenerator: checked}, e.altKey)}/>
                    <Checkbox label={LocalizedStrings.onlyFixtures} checked={this.state.filterOptions.onlyFixtures} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyFixtures: checked}, e.altKey)}/>
                    <Checkbox label={LocalizedStrings.onlyStructures} checked={this.state.filterOptions.onlyStructures} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyStructures: checked}, e.altKey)}/>
                    <Checkbox label={LocalizedStrings.onlySupport} checked={this.state.filterOptions.onlySupport} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlySupport: checked}, e.altKey)}/>
                    <Checkbox label={LocalizedStrings.onlyAudio} checked={this.state.filterOptions.onlyAudio} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyAudio: checked}, e.altKey)}/>
                    <Checkbox label={LocalizedStrings.onlyMeshes} checked={this.state.filterOptions.onlyMeshes} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyMeshes: checked}, e.altKey)}/>
                    <Checkbox label={LocalizedStrings.onlyAssemblyGroup} checked={this.state.filterOptions.onlyAssemblyGroup} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyAssemblyGroup: checked}, e.altKey)}/>
                    <Divider/>
                    <Checkbox label={LocalizedStrings.onlyVisible} checked={this.state.filterOptions.onlyVisible} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyVisible: checked}, e.altKey)}/>
                  </div>
                </Dropdown.Menu>
              </Form.Dropdown> 
              <LRFilterInput 
              value = {this.state.searchText}
              noLabel
              onChange={this.setSearchFilter.bind(this)}/>
            </Form.Group>
          </Form>
            
            <div ref={this.scrollRef} onScroll={(e) => {this.setState({scrollOffset: e.target.scrollTop})}} style={{marginLeft: "-20px", paddingTop:"1rem", height: HEIGHT_CALC, overflow: "auto"}} onClick={() => {window.LR_SelectAll({Value: false})}}>
            
                  <div style={{height: "50px", position: "sticky", top: 0}}>
                  {renderedItems}
                  </div>
                <div data-lrhandle={EMPTY_UUID} style = {{position:"relative",zIndex:2,pointerEvents: "none", minHeight: HEIGHT_CALC, height: this.state.countDisplay*ELEMENT_HEIGHT, width: "calc(100%)"}}>
                </div>
            </div>         
        </div>    
    )
  }

  debounceTimer = undefined
  setSearchFilter(value){
    if(this.debounceTimer){
      clearInterval(this.debounceTimer)
      this.debounceTimer = undefined
    }
    this.setState({searchText: value})

    this.debounceTimer = setTimeout(()=>{
      window.LR_SetSceneTreeFilterState(
        {
          SceneTreeFilterState : {
            SearchFilter: value
          }
        }
      )
    }, 150)

  }

  //option eg: {onlyVisible: true}
  setFilterOption(option, clear){
    this.setState({filterOptions: {...(clear ? filterOptions_NoSelected : this.state.filterOptions), ...option }}, ()=> {
      let options = this.state.filterOptions
      window.LR_SetSceneTreeFilterState(
      {
        SceneTreeFilterState : {
          ShowFilterAssemblyGroup: options.onlyAssemblyGroup,
          ShowFilterConsumer     : options.onlyConsumer,
          ShowFilterDistributer  : options.onlyDistributer,
          ShowFilterPlugBox      : options.onlyPlugBox,
          ShowFilterGenerator    : options.onlyGenerator,
          ShowFilterFixture      : options.onlyFixtures,
          ShowFilterStructures   : options.onlyStructures,
          ShowFilterSupport      : options.onlySupport,
          ShowFilterAudio        : options.onlyAudio,
          ShowFilterMeshes       : options.onlyMeshes,
          ShowFilterVisible      : options.onlyVisible
        }
      })
    })
  }

  shouldItemDisplay(item)
  {
    return item.VisibleInSceneTree
  }

  getTree = async () =>
  {
    let tree = await window.LR_GetObjectTree({CompleteTree: false, Async: true, IncludeProperties:[ "Name", "Selected", "Expanded", "ResourceType", "ObjectId", "FixtureId", "Existing" , "LoadConnectedMeasured" , "LoadConnectedCalculated", "Visible", "VisibleInSceneTree"]});
    let removeFromTopLevel = [];
    tree.forEach((element, i) =>
    {

      this.treeMap.set(element.UUID, element);
      element.children = [];

      if(element.ParentUUID !== undefined)
      {
        let obj = this.treeMap.get(element.ParentUUID)
        if(obj)
        {
          obj.children.push(element);
          removeFromTopLevel.push(i);
        }
        else
        {
          console.error("let obj = this.treeMap.get(element.ParentUUID) FAILED for ", element.ParentUUID)
        }
      }
    });

    return tree;
  }

  updateTreeSelection(object) 
  {
    let obj = this.treeMap.get(object.UUID);

    if(obj === undefined || obj.UUID === EMPTY_UUID)  { return; }

    if(object.Selected !== undefined)          
    { 
      if (object.Selected)
      {
        this.selectionList.push(object.UUID);
      }
      else 
      {
        this.selectionList = this.selectionList.filter(item => item !== object.UUID);
      }
    }
    Object.assign(obj, object)
  }

  updateDuplicatedFixtureIds = () => 
  {
    window.LR_GetDuplicatedFixtureIDs().then(res => {
      this.setState({DuplicatedFixtureIds: res})
    })

    window.LR_GetDuplicatedObjectIds().then(res => {

      this.setState({DuplicatedObjectIds: res})
    })
  }

  onSelectObject =(uuid) =>
  {
      this.LastSelectedUuid = uuid
  }

  setUpCallbacks()
  {
    globalCallbacks.updateDuplicatedFixtureIds = () =>
    {
      this.updateDuplicatedFixtureIds()
    }

    let mutex = new Mutex()

    globalCallbacks.getSceneTree = async () => 
    {
      await mutex.acquire()
      this.treeMap.clear();
      this.setState({loading : true});
      this.tree = await this.getTree();
      this.updateDuplicatedFixtureIds()

      let state = (await window.LR_GetSceneTreeFilterState()).SceneTreeFilterState
      let filterOptions = {
        onlyAssemblyGroup: state.ShowFilterAssemblyGroup,
        onlyConsumer     : state.ShowFilterConsumer     ,
        onlyDistributer  : state.ShowFilterDistributer  ,
        onlyPlugBox      : state.ShowFilterPlugBox      ,
        onlyGenerator    : state.ShowFilterGenerator    ,
        onlyFixtures     : state.ShowFilterFixture      ,
        onlyStructures   : state.ShowFilterStructures   ,
        onlySupport      : state.ShowFilterSupport      ,
        onlyAudio        : state.ShowFilterAudio        ,
        onlyMeshes       : state.ShowFilterMeshes       ,
        onlyVisible      : state.ShowFilterVisible      
      }
  
      mutex.release()
      
      this.setState({
        filterOptions, 
        tree : this.tree, 
        loading: false,
        searchText: state.SearchFilter
      });

      await this.recalculateObjectsToDisplay()
    }

    globalCallbacks.refreshSceneTree = async (modifiedObjects, properties) => 
    {
      if(properties.Name || 
        properties.Selected || 
        properties.Expanded || 
        properties.FixtureId || 
        properties.ElectricalConnections || 
        properties.LoadConnectedCalculated || 
        properties.LoadConnectedCalculated || 
        properties.Visible || 
        properties.VisibleInSceneTree || 
        properties.HasCableSelected || 
        properties.FullyConnected || 
        properties.LoadConnectedMeasured || 
        properties.LoadConnectedCalculated || 
        properties.Existing || 
        properties.ObjectId)
      {
        await mutex.acquire()

        for(let completeObject of modifiedObjects){
          this.updateTreeSelection(completeObject);
        }

        mutex.release()

        let currentScroll = this.state.scrollOffset

        if (this.selectionList.length === 1 && this.selectionList[0] !== this.LastSelectedUuid)
        {
          // Move tree to selection
          let treeOffset = this.tree.findIndex(item => item.UUID === this.selectionList[0])
          let finalOffset = treeOffset
          for (let i = 0; i < treeOffset; i++)
          {
            let hiddenCount = this.getHiddentNodeCount(this.tree[i])
            i += hiddenCount;
            finalOffset -= (hiddenCount);
          }
          finalOffset -= 10
          currentScroll = finalOffset * ELEMENT_HEIGHT
          if (this.scrollRef?.current && !properties.ObjectId)
          {
            this.scrollRef.current.scrollTo(0, currentScroll)
          }
        }
        this.LastSelectedUuid  =undefined
    
        this.setState({tree : this.tree, change : !this.state.change});
      }
    }

    globalCallbacks.displayDrawingMenu = async () =>
    {
      let drawingList = await window.LR_GetDrawings();

      let activeDrawing = "";
      let drawings = drawingList.Drawings.map(drawing =>
      {
        if(drawing.UUID === drawingList.ActiveDrawing) { activeDrawing = drawing.Path; }

        return(
        {
          key   : drawing.UUID,
          value : drawing.Path,
          text  : drawing.Name,
        });
      });

      this.setState({drawings, activeDrawing});


      let drawingSettings = await window.LR_GetDrawingSettings()
      let obj = {}
      drawingSettings.StructuralCalculationObjects.forEach(e=>{obj[e] = true})

      this.setState({StructuralCalculationObjects: obj, UseStructuralCalculationObjects: drawingSettings.StructuralCalculationObjects.length > 0})
    }
  }

  // ---------------------------------------------------------------------------------
  // Drag and Drop functions

  onDragStart = (UUID) =>
  {
    this.setState({dragged : UUID});
  }

  onDragEnd = () =>
  {
    this.setState({dragged : undefined});
  }

  onDrop = (e) =>
  {
    let request = 
    {
      ParentUUID      : 0,
      ChildUUID       : this.state.dragged,
      GlobalCoordinates : !e.altKey,
    }
    window.LR_SetChild(request);
  }

  // ---------------------------------------------------------------------------------
  // Open drawing

  openDrawing = async (e, {value}) =>
  {
    this.setState({activeDrawing: value});
    await window.LR_OpenLRWFile({Path: value});
  }

}

export default SceneTreeHierarchy;
