//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React from "react";
import { Modal, Button, Icon, Loader, Dimmer, Label } from "semantic-ui-react";

import LocalizedStrings from "../../localization/BasicComponents";

let okStack = [];

const listener = (ev) => {
  if (ev.code === "Enter") {
    if (okStack.length > 0 && okStack[okStack.length - 1]) {
      okStack[okStack.length - 1]();
      ev.stopPropagation()
      ev.preventDefault();
    }
  }
};

document.addEventListener("keypress", listener)


class LRModal extends React.Component {

  constructor(props)
  {
      super(props)

      this.state={
      }
  }
  static defaultProps = {
    open: false,
    title: "MODAL",
    onCancelClick: undefined,
    onOkClick: undefined,
    additionalHeader: null,
    noCancel: false,
    okDisabled: false,
    closeOnDimmerClick: true,
    closeOnEscape: true,
    closeOnEnter: true,
    size: undefined,
    contentStyle: undefined,
    noActions: false,
    closeIcon: false,
    changed: false,
    dimmer: "blurring",
    scrolling: true
  }

  componentDidUpdate(prevProps)
  {
      if (prevProps.open !== this.props.open)
      {
        if (this.props.onOpen)
        {
          this.props.onOpen();
      
        }
        
        if(this.props.closeOnEnter)
        {
          if (this.props.open) // opening
          {
              okStack.push(this.props.onOkClick);
          }
          else // closing
          {
              okStack.pop();
          }
        }
          
      }
  }

  render() {
    return (
        <Modal
          open={this.props.open}
          onClose={this.props.onCancelClick}
          closeOnDimmerClick={this.props.closeOnDimmerClick}
          closeOnEscape={this.props.closeOnEscape}
          size={this.props.size}
          style={this.props.style}
          dimmer = {!!this.props.dimmer}
          closeIcon={this.props.closeIcon}>
            {!this.props.noCancel ? <Label corner="right" icon="close" color="red" onClick={this.props.onCancelClick}/> : null}
          {this.props.okLoading ? <Dimmer active><Loader size="big" active>{LocalizedStrings.Processing}</Loader></Dimmer>  : null}
          {this.props.title ? <Modal.Header>{this.props.title}{this.props.additionalHeader}</Modal.Header> : null}
          {this.props.children ? <Modal.Content scrolling={this.props.scrolling} style={this.props.contentStyle}>{this.props.children}</Modal.Content> : null}
          {!this.props.noActions ? this.renderAction() : null}
        </Modal>
    );
  }

  renderAction()
  {
    return (
      <Modal.Actions style={{position: "relative"}}>
        {!this.props.noCancel ? (this.props.preventDataLoss && this.props.changed)? 

        this.state.clickedCancel ?
        <>
          <Button onClick={()=> {this.setState({clickedCancel : false}); this.props.onCancelClick()}} positive>{LocalizedStrings.Save}</Button> 
          <Button negative onClick={()=>{this.setState({clickedCancel : false});this.props.onDiscardClick()}}>{LocalizedStrings.Discard}</Button>
        </>
        :
        <Button onClick={()=>{this.setState({clickedCancel : true})}}  negative><Icon name='remove' /> {LocalizedStrings.Cancel}</Button>
      : 
      <Button
          negative
          onClick={this.props.onCancelClick}>
            <Icon name='remove' /> 
            {LocalizedStrings.Cancel}
          </Button> : null}
        {this.state.clickedCancel ? null :
        <Button
          positive
          onClick={this.props.onOkClick}
          disabled={this.props.okDisabled}>
            {this.props.okLoading ? <Icon loading name="spinner"/>:<Icon name='checkmark' />}
            {this.props.customOKText ? this.props.customOKText : LocalizedStrings.OK}
          </Button>}
          
          {this.props.additionalActions ? <div style={{float:"left"}}>{this.props.additionalActions}</div> : null}

      </Modal.Actions>
    )
  }
}

export default LRModal;
