
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { useState } from 'react';
import { Button, Divider, Form, Input, Label, Select } from 'semantic-ui-react';
import LocalizedStrings from "../../../localization/ObjectPropertyComponent";
import { globalCallbacks } from '../../../util/callback';
import {
    BASE_UNIT_AMPERE,
    BASE_UNIT_AREA,
    BASE_UNIT_BOOLEAN,
    BASE_UNIT_LENGTH,
    BASE_UNIT_NUMBER,
    BASE_UNIT_POWER,
    BASE_UNIT_SELECTABLE,
    BASE_UNIT_VOLTAGE,
    EMPTY_UUID,
    GetConnectorInfoByName_Data,
    GetConnectorInfoByName_Electrical,
    GetPayLoadColor_2,
    SELECT_Single,
    kWireType_Consumer,
    kWireType_Generator,
    kWireType_Input,
    kWireType_NetworkInOut, kWireType_NetworkInput, kWireType_NetworkOutput,
    kWireType_NetworkProvider,
    kWireType_Output
} from "../../../util/defines";
import UnitInput from '../../Basics/BasicUnitInput';
import CollapsableDevider from '../../Basics/CollapsableDevider';
import FilteredFormGroup from './../FilteredFormGroup';
import "../ObjectPropertiesComponent.css";

function selectElectricalObject(uuid, e) {
    let request =
    {
        Selected: true,
        SelectionMode: SELECT_Single,
        SelectionGroupMode: globalCallbacks.selectionGroupMode,
        ShiftKey: false,
        MetaKey: false,
        AltKey: false,
        SelectedList: [uuid]
    }

    window.LR_Select(request).then(window.LR_ZoomToSelection())
}

export default function GetElectricalInputField({ name, value, options, arrayName, indexInArray, searchFilter, SymbolDefs, children, updateObjectConnections, getInputElectricalProperty, presetColor }) {

    let [OpenCables, setOpenCables] = useState(false)
    let [PhaseLoading, setPhaseLoading] = useState(false)
    let [OpenElectricalData, setOpenElectricalData] = useState(false)



    const filterBySearch = (component, tag) => {
        let UniversalName = tag;
        let LocalizedName = LocalizedStrings[tag];
        if (!LocalizedName) {
            //console.warn("filterBySearch tag not found", tag)
            LocalizedName = UniversalName;
        }

        if ((searchFilter !== "" && !LocalizedName.toLowerCase().includes(searchFilter.toLowerCase()))) {
            return null
        }
        return component
    }

    const getDisplayValueString = (name, value, labelName, onClickUnit) => {
        return filterBySearch(<Form.Input name={name}
            labelPosition="right"
            size="mini"
            fluid
            readOnly
            value={value}
            label={LocalizedStrings[name]}>
            <input style={{ backgroundColor: presetColor ?? "" }} />
            <Label onClick={onClickUnit}>{labelName}</Label>
        </Form.Input>, name)
    }

    const getDisplayValueUnit = (name, value, baseUnit, onClickUnit, backgroundColor = "") => {
        return filterBySearch(<UnitInput name={name}
            baseUnit={baseUnit}
            size="mini"
            label={LocalizedStrings[name]}
            value={value}
            labelPosition="right"
            fluid
            readOnly
            labelClicked={onClickUnit}
            presetColor={backgroundColor} />, name)
    }


    const getDisplayValue = (name, value, unit, onClickUnit, backgroundColor = "") => {
        return filterBySearch(<UnitInput name={name}
            baseUnit={BASE_UNIT_NUMBER}
            unitStr={unit}
            size="mini"
            label={LocalizedStrings[name]}
            value={value}
            labelPosition="right"
            fluid
            readOnly
            labelClicked={onClickUnit && unit ? onClickUnit : undefined}
            presetColor={{ backgroundColor: backgroundColor }} />, name)
    }

    const searchFilerFuncElectric = (inArray, searchText) => {
        let search = searchText.toLowerCase();
        return inArray.filter((e) => {
            if (e.SearchFilter.includes(search)) { return true; }
            return false;
        })
    }

    let connection = value[value.UUID];

    if (value.IsSame === false) {
        connection.Connections = []
    }

    let val = { Object: EMPTY_UUID, Wire: EMPTY_UUID }
    if (connection.Connections && connection.Connections[0]) {
        val = { Object: connection.Connections[0].Object, Wire: connection.Connections[0].Wire }
    }
    let connectorTypeName = "Not found"

    let isPower = false;
    let isData = false;
    let allowConsumptionEdit = false

    let connectorTypeInfo = {}

    switch (connection.WireType) {
        case kWireType_Consumer:
            allowConsumptionEdit = true;
        case kWireType_Input:
        case kWireType_Generator:
        case kWireType_Output:
            isPower = true;
            connectorTypeInfo = GetConnectorInfoByName_Electrical(connection.ConnectorType)
            break;
        case kWireType_NetworkProvider:
        case kWireType_NetworkInOut:
        case kWireType_NetworkInput:
        case kWireType_NetworkOutput:
            isData = true;
            connectorTypeInfo = GetConnectorInfoByName_Data(connection.ConnectorType)
            break;
        default: break;
    }

    connectorTypeName = connectorTypeInfo.LocalizedName

    let showGenerator = false;
    let showLength = false;
    let showCablePath = false;
    switch (connection.WireType) {

        //eslint-disable-next-line
        case kWireType_Input:
        case kWireType_Consumer:
            showGenerator = true;
        case kWireType_NetworkInOut:
        case kWireType_NetworkInput:
            showLength = true;
            showCablePath = true
        default: break;
    }
    let showVoltage = false;
    let showGeneratorResistance = false;
    switch (connection.WireType) {
        case kWireType_Generator:
            showVoltage = true;
            showGeneratorResistance = true
            break;
        default: break;
    }


    return (
        <React.Fragment>
            <FilteredFormGroup>
                <Form.Field fluid>
                    <label>
                    <div className='container'>
                            <span className='left' > {name} </span>
                    <div className='collapseableDev' style={{width:"60%"}}>
                        <CollapsableDevider name="ExtraElectricalInfo" omit={searchFilter !== ""} header={LocalizedStrings.OpenElectricalData} icon="plug" open={OpenElectricalData} update={() => setOpenElectricalData(!OpenElectricalData)} onClick={() => {
                            setOpenElectricalData(!OpenElectricalData)
                        }}>
                        </CollapsableDevider>
                        </div>

                        <span className='right' > {connectorTypeName}</span>
                        </div>
                    </label>
                    <Input labelPosition="right" fluid>
                        <Select
                            className='electricalSelection' 
                            labeled
                            multiple
                            selection
                            fluid
                            placeholder={value.IsSame === false ? LocalizedStrings.Multiple : ""}
                            search={searchFilerFuncElectric}
                            selectOnBlur={false} startElectron
                            value={connection.Connections.map(con => con.Object + con.Wire)}
                            options={options}
                            onChange={(e, data) => { updateObjectConnections(e, data, value.UUID, connection.Connections) }}>
                        </Select>
                        <Label style={{ marginLeft: 14 }} disabled={val.Object === EMPTY_UUID} onClick={(e) => { selectElectricalObject(val.Object, e) }}>{LocalizedStrings.Select}</Label>
                    </Input>
                        {
                            OpenElectricalData ? 
                            <div className='moreInfoContent' style={{ width: '100%', marginTop: '5px' }}>
                                <FilteredFormGroup>
                                    {showGenerator ? getDisplayValue("LinkedGenerator", connection.LinkedGenerator, "", undefined, undefined) : null}
                                    {showGenerator ? <Form.Field>
                                        <label style={{ height: '1.4285em', width: '1em' }}></label>
                                        <Input><Button fluid size="mini" disabled={connection.LinkedGeneratorUUID === EMPTY_UUID} onClick={(e) => { selectElectricalObject(connection.LinkedGeneratorUUID, e) }}>{LocalizedStrings.Select}</Button></Input>
                                    </Form.Field> : null}
                                </FilteredFormGroup>
                                <FilteredFormGroup>
                                    {showVoltage ? getInputElectricalProperty(BASE_UNIT_POWER, "MaxConsumption", arrayName, indexInArray) : null}
                                    {showVoltage ? getInputElectricalProperty(BASE_UNIT_VOLTAGE, "Voltage", arrayName, indexInArray) : null}
                                </FilteredFormGroup>
                                <FilteredFormGroup>
                                    {isPower && !allowConsumptionEdit ? getDisplayValueUnit("LoadResult", connection.CurrentConsumption, BASE_UNIT_POWER, undefined, GetPayLoadColor_2(connection.CurrentConsumption, connection.MaxConsumption)) : null}
                                    {isPower && !allowConsumptionEdit && !showVoltage ? getDisplayValueUnit("MaxConsumption", connection.MaxConsumption, BASE_UNIT_POWER, undefined, GetPayLoadColor_2(connection.CurrentConsumption, connection.MaxConsumption)) : null}
                                    {isPower && allowConsumptionEdit ? getInputElectricalProperty(BASE_UNIT_POWER, "Consumption", arrayName, indexInArray) : null}
                                    {isData ? getDisplayValue("Universe", connection.Universe, "") : null}
                                </FilteredFormGroup>
                                <CollapsableDevider name="PhaseLoading" omit={searchFilter !== ""} header={LocalizedStrings.PhaseLoading} icon="plug" open={PhaseLoading} update={() => setPhaseLoading(!PhaseLoading)}>
                                    <FilteredFormGroup>
                                        {isPower && allowConsumptionEdit ? getInputElectricalProperty(BASE_UNIT_POWER, "BasePayLoad", arrayName, indexInArray) : null}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {isPower && allowConsumptionEdit ? getInputElectricalProperty(BASE_UNIT_POWER, "AveragePayLoad", arrayName, indexInArray) : null}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {isPower && connectorTypeInfo.SocketInfo?.length > 2 ? getDisplayValueUnit("CurrentL1", connection.CurrentL1, BASE_UNIT_AMPERE, undefined, GetPayLoadColor_2(connection.CurrentL1, connection.MaxL1)) : null}
                                        {isPower && connectorTypeInfo.SocketInfo?.length > 3 ? getDisplayValueUnit("CurrentL2", connection.CurrentL2, BASE_UNIT_AMPERE, undefined, GetPayLoadColor_2(connection.CurrentL2, connection.MaxL2)) : null}
                                        {isPower && connectorTypeInfo.SocketInfo?.length > 4 ? getDisplayValueUnit("CurrentL3", connection.CurrentL3, BASE_UNIT_AMPERE, undefined, GetPayLoadColor_2(connection.CurrentL3, connection.MaxL3)) : null}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {isPower && connectorTypeInfo.SocketInfo?.length > 2 ? getDisplayValueUnit("MaxL1", connection.MaxL1, BASE_UNIT_AMPERE, undefined, GetPayLoadColor_2(connection.CurrentL1, connection.MaxL1)) : null}
                                        {isPower && connectorTypeInfo.SocketInfo?.length > 3 ? getDisplayValueUnit("MaxL2", connection.MaxL2, BASE_UNIT_AMPERE, undefined, GetPayLoadColor_2(connection.CurrentL2, connection.MaxL2)) : null}
                                        {isPower && connectorTypeInfo.SocketInfo?.length > 4 ? getDisplayValueUnit("MaxL3", connection.MaxL3, BASE_UNIT_AMPERE, undefined, GetPayLoadColor_2(connection.CurrentL3, connection.MaxL3)) : null}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {isPower ? getDisplayValueUnit("CurrentN", connection.CurrentN, BASE_UNIT_AMPERE, undefined, GetPayLoadColor_2(connection.CurrentN, connection.MaxN)) : null}
                                        {isPower ? getDisplayValueUnit("CurrentPE", connection.CurrentPE, BASE_UNIT_AMPERE, undefined, GetPayLoadColor_2(connection.CurrentPE, connection.MaxPE)) : null}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {connectorTypeInfo?.SocketInfo?.length === 3 ? getDisplayValueString("Dimmable", connection.Dimmable ? "✅" : "□") : null}
                                        {isPower ? getDisplayValueUnit("ElectricalPhase", connection.ElectricalPhase, BASE_UNIT_NUMBER) : null}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {(!showVoltage && isPower) ? getDisplayValue("LimitFaultCurrent", connection.LimitFaultCurrent, "A") : null}
                                        {(!showVoltage && isPower) ? getDisplayValue("LimitEarthFaultCurrent", connection.LimitEarthFaultCurrent, "A") : null}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {showGeneratorResistance ? getInputElectricalProperty(BASE_UNIT_NUMBER, "ResistanceGeneratorZe", arrayName, indexInArray) : null}
                                        {showGeneratorResistance ? getInputElectricalProperty(BASE_UNIT_NUMBER, "ResistanceGeneratorZesc", arrayName, indexInArray) : null}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {showLength ? getDisplayValue("DeltaU", connection.DeltaU, "V") : null}
                                        {showLength ? getDisplayValue("DeltaUPercent", connection.DeltaUPercent, "%") : null}
                                    </FilteredFormGroup>
                                </CollapsableDevider>
                                <CollapsableDevider name="Cables" omit={searchFilter !== ""} header={LocalizedStrings.Cables} icon="plug" open={OpenCables} update={() => setOpenCables(!OpenCables)}>
                                    <FilteredFormGroup>
                                        {getDisplayValue("ConnectorType", connectorTypeName)}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {getInputElectricalProperty(BASE_UNIT_SELECTABLE, "CableSet", arrayName, indexInArray, SymbolDefs)}
                                    </FilteredFormGroup>

                                    <FilteredFormGroup>
                                        {showLength ? getInputElectricalProperty(BASE_UNIT_LENGTH, "AttachedCableLength", arrayName, indexInArray) : null}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {showLength ? getInputElectricalProperty(BASE_UNIT_LENGTH, "NeededCableLength", arrayName, indexInArray) : null}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {getInputElectricalProperty(BASE_UNIT_LENGTH, "DefaultCableLength", arrayName, indexInArray)}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {getInputElectricalProperty(BASE_UNIT_AREA, "DefaultCrossSection", arrayName, indexInArray)}
                                    </FilteredFormGroup>
                                    <FilteredFormGroup>
                                        {showLength ? getInputElectricalProperty(BASE_UNIT_BOOLEAN, "ManuallyBuildCable", arrayName, indexInArray) : null}
                                    </FilteredFormGroup>
                                    {showCablePath ? children : null}
                                </CollapsableDevider>

                            </div>
                            :
                            null
                        }
                    
                </Form.Field>
            </FilteredFormGroup>
            <div className='devDivider'>
                <Divider/>
            </div>
        </React.Fragment>);
}