import React, {Component} from 'react'

import { Header, Segment, List, Icon, Input, Table, Button, Divider, Form, Pagination, Message, Progress } from 'semantic-ui-react'
import { FetchCollaborators, FetchActiveUser, hasFetched, FetchGroups, FetchAdmins, FetchApiTokens, FetchActiveAdmin, FetchSharedLicenceUsers, FetchContentAdmins} from '../../redux/actions/fetch_actions'
import { COLLABORATORS, ACTIVE_USER, GROUPS, ADMINS, API_TOKENS, ACTIVE_ADMIN, SHAREDLICENCE, CONTENT_ADMINS } from "../../redux/redux_defines"
import { connect } from 'react-redux'
import { globalCallbacks } from '../../util/callback'
import LRModal from '../Basics/BasicModal'
import { lrServerConnection } from '../../redux/light_right_server_connection'
import { Link } from "react-router-dom";
import UnitInput from '../Basics/BasicUnitInput';
import { BASE_UNIT_DATE } from '../../util/defines'
import { GetProductNames, GetProductId, GetProductFromPrice, IsMonthly } from '../../webApp/stripe';
import LocalizedStrings from '../../localization/AccountView'
import UserAvatar from '../WebComponents/UserAvatar';

const accountTypeOptions = [
  {
    text: LocalizedStrings.NormalUser,
    value: 0,
    key: 0
  },
  {
    text: LocalizedStrings.Organization,
    value: 1,
    key: 1
  }
]

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      search: '',
      edited: undefined,
      changed: false,
      editedUserType: undefined,
      addAdminOpen: false,
      addContentAdminOpen: false,
      shareLicenceOpen:false,
      addTokenOpen: false,
      newTokenName: "",
      tokenResult: null,
      usersFromSearch: [], // search for collaborators
      activeUsersPage: 1,  // which slice of 'usersFromSearch' to show
      searchExecuted: false,
      promoCodeRecipient: undefined, // username of a customer we want to create a promo code for
      promoCode: undefined, // a promo code this user wants to apply
      couponNote: null,
      coupon_structural_percent: 50,
      coupon_structural_string: "",
    }
  }

  componentDidMount = () => {
    lrServerConnection.checkCurrentDiscount().then(res => {
      if (res.amountOff)
        res.amountOff /= 100
      this.setState({couponNote: this.createCouponNote(res)})
    })
  }

  componentDidUpdate = (prevProps, prevState) =>
  {
    if(prevProps.activeAdmin.data !== this.props.activeAdmin.data)
    {
      this.setState({edited: this.props.groups.data})
    }
  }

  render() {
    FetchCollaborators()
    FetchGroups()
    FetchActiveUser()
    FetchAdmins()
    FetchApiTokens()
    FetchActiveAdmin()
    FetchSharedLicenceUsers()
    FetchContentAdmins()

    let collaborators = hasFetched(this.props.collaborators) ? this.props.collaborators.data : []
    let groups = hasFetched(this.props.groups) ? this.props.groups.data : []
    let activeUser =hasFetched(this.props.activeAdmin) ? this.props.activeAdmin.data : hasFetched(this.props.activeUser) ? this.props.activeUser.data : {type: 0} 
    let admins = hasFetched(this.props.admins) ? this.props.admins.data : []
    let apiTokens = hasFetched(this.props.apiTokens) ? this.props.apiTokens.data : []
    let sharedLicenceUsers = hasFetched(this.props.sharedLicence_users) ? this.props.sharedLicence_users.data : [];
    let contentAdmins = hasFetched(this.props.contentAdmins) ? this.props.contentAdmins.data : [];


    if(hasFetched(this.props.groups) && this.state.edited === undefined) {
      this.setState({edited: groups})
    }

    
    // show 'please wait' for fields where we know we should have a value and that are string representable
    const currentSubPriceId = activeUser?.currentSubscriptionPlan
    const currentSubPlanId = currentSubPriceId ? GetProductFromPrice(currentSubPriceId) : undefined
    const currentSubPlanName = currentSubPlanId ? GetProductNames()[currentSubPlanId] : "Please Wait"
    let currentProTeamSeats = activeUser && activeUser.currentProTeamSeats ? activeUser.currentProTeamSeats : 0
    let nextPaymentDue = activeUser.nextPaymentDue
    let currentMonthlyBill = activeUser && !isNaN(activeUser.currentBill) ? activeUser.currentBill/100 + "€" : "Please Wait"
    let currentYearlyBill = activeUser && !isNaN(activeUser.currentBill) ? activeUser.currentBill/100 + "€" : "Please Wait"
    let currentTrialEnd = activeUser && new Date(activeUser.trialEnd) > Date.now() ? activeUser.trialEnd : undefined
    let username = this.props.activeAdmin.data?.username ??  this.props.activeUser.data?.username 
    let isCommunityOrBasicPlan = (currentSubPlanName === "Community") || (currentSubPlanName === "Basic")

    // prepare downgrade string for header
    const nextSub = activeUser?.nextSubscription
    let nextSubStr = ""
    if (nextSub) {
      nextSubStr = LocalizedStrings.DowngradeTo
      nextSubStr = nextSubStr.replace("$1", GetProductNames()[GetProductFromPrice(nextSub.planPriceId)])
      nextSubStr = nextSubStr.replace("$2", nextSub.seatCount)
    }
    
    groups = this.state.edited ? this.state.edited : groups

    return <> 
      <Segment.Group horizontal>
        <Segment>
          <i>{IsMonthly(currentSubPriceId) ? LocalizedStrings.CurrentMonthlyBill : LocalizedStrings.CurrentYearlyBill}</i>
          <h1>{IsMonthly(currentSubPriceId) ? currentMonthlyBill : currentYearlyBill}</h1>
          {currentTrialEnd ? (
              <>
                <br/>
                <p>{this.state.couponNote}</p>
                {LocalizedStrings.CouponExpires}
                <UnitInput
                  baseUnit={BASE_UNIT_DATE}
                  value={currentTrialEnd}
                  readOnly
                  dateOnly
                />
              </>
            ) : null}
          {nextSubStr}
        </Segment>
        <Segment>
        <i>{LocalizedStrings.NextPayment}</i>
          <h1>{nextPaymentDue ? <UnitInput  value={nextPaymentDue} 
                                            baseUnit={BASE_UNIT_DATE}
                                            readOnly/> : LocalizedStrings.Never}</h1>
          <Link as={Link} to={`/settings/account/paymenthistory`}>{LocalizedStrings.PaymentHistory}</Link>
        </Segment>
        <Segment>
          <i>{LocalizedStrings.PaymentInformation}</i><br/>
          <Link as={Link} to={`/settings/account/billing`}>{LocalizedStrings.UpdatePaymentInformation}</Link>
        </Segment>
      </Segment.Group>
      <Button  as={Link} to={`/${username}/settings/account/subscription`}  floated="right" positive>{LocalizedStrings.Upgrade}</Button>
      <Button  as={Link} to={`/${username}/settings/account/subscription`}  floated="right">{LocalizedStrings.ComparePlans}</Button>
      <Header>{LocalizedStrings.CurrentPlan + " " + currentSubPlanName}</Header>
      <Divider/>
      <Segment>
          {(currentSubPlanId === GetProductId()["PRO_TEAM"] || currentSubPlanId === GetProductId()["ARENA"]) ? 
            this.renderSeats(sharedLicenceUsers.length, currentProTeamSeats) 
            : 
            <Segment placeholder><Header icon>{LocalizedStrings.UpgradeToPro}</Header></Segment>}
      </Segment>
      <Header>{LocalizedStrings.Coupons}</Header>
      <Divider/>
      <Segment>
        {this.renderCoupons()}
      </Segment>
      <Header>{LocalizedStrings.Collaborators}</Header>
      <Divider/>
      <Message color='yellow'>
        <Message.Header>
            {LocalizedStrings.SharedLicenceUserInfoHeader}
        </Message.Header>
        <Message.Content>
            {LocalizedStrings.SharedLicenceUserInfoText1}
            {LocalizedStrings.SharedLicenceUserInfoText2}
        </Message.Content>
      </Message>
      <Table>
        <Table.Header><Table.Row>
          <Table.HeaderCell>{LocalizedStrings.Collaborators}</Table.HeaderCell>
          {groups.map((group, i) => <Table.HeaderCell style={{minWidth: '9em', position: 'relative'}} key={i}><Input style={{width: "100%"}} onChange={this.onGroupNameChange(i)} transparent value={group.name}/><Icon style={{position: 'absolute',right: '1em'}} color='red' name='times' onClick={this.deleteGroup(i)}/></Table.HeaderCell>)}
          </Table.Row></Table.Header>
        <Table.Body>
          {collaborators.map(collab => <Table.Row key={collab._id}>
            <Table.Cell style={{position: 'relative'}}>{collab.name}<Icon name='times' color='red' style={{position: 'absolute', right: '1em'}} onClick={this.onRemoveClicked(collab.username)}/></Table.Cell>
            {groups.map((group, i) => <Table.Cell key={i} style={{textAlign: 'center'}} onClick={this.onToggle(i, collab._id, !group.members.includes(collab._id))} positive={group.members.includes(collab._id)} negative={!group.members.includes(collab._id)}>
              {<Icon name={group.members.includes(collab._id) ? "check" : "times"}></Icon>}
            </Table.Cell>)}
          </Table.Row>)}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={groups.length + 1}>
              <Button onClick={this.openAddUser} primary icon="add" content={LocalizedStrings.AddUser}></Button>
              <Button onClick={this.onAddGroup} primary icon="add" content={LocalizedStrings.AddGroup}></Button>
              <Button onClick={this.onApplyGroups} positive={this.state.changed} disabled={!this.state.changed} content={LocalizedStrings.ApplyGroups} floated="right"/>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{LocalizedStrings.SharedLicenceUsers}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sharedLicenceUsers.map((user,i) => <Table.Row key={i}>
            <Table.Cell style={{position: 'relative'}}>{user.name}<Icon name='times' color='red' style={{position: 'absolute', right: '1em'}} onClick={this.onRemoveSharedLicenceUser(user.username)}/></Table.Cell>
          </Table.Row>)}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell style={{textAlign:"center"}}>
              <Button primary icon="add" floated='left' onClick={this.openSharedLicence} content={LocalizedStrings.ShareProTeamLicence} disabled={isCommunityOrBasicPlan}></Button>
              {isCommunityOrBasicPlan ? <span style={{fontWeight:"bolder", lineHeight:"2.5rem", fontSize:"1.15rem"}}>{LocalizedStrings.UpgradeToProTeam}</span> : ""}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{LocalizedStrings.AccountAdmins}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {admins.map((adm,i) => <Table.Row key={i}>
            <Table.Cell style={{position: 'relative'}}>{adm.name}<Icon name='times' color='red' style={{position: 'absolute', right: '1em'}} onClick={this.onRemoveAdmin(i)}/></Table.Cell>
          </Table.Row>)}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <Button primary icon="add" onClick={this.openAddAdmin} content={LocalizedStrings.AddAdmin}></Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{LocalizedStrings.ContentAdmins}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {contentAdmins.map((contentAdm,i) => <Table.Row key={i}>
            <Table.Cell style={{position: 'relative'}}>{contentAdm.username}<Icon name='times' color='red' style={{position: 'absolute', right: '1em'}} onClick={this.onRemoveContentAdmin(i)}/></Table.Cell>
          </Table.Row>)}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell style={{textAlign:"center"}}>
                <Button primary icon="add" floated='left' onClick={this.openAddContentAdmin} content={LocalizedStrings.AddContentAdmin} disabled={isCommunityOrBasicPlan}></Button>
                {isCommunityOrBasicPlan ? <span style={{fontWeight:"bolder", lineHeight:"2.5rem", fontSize:"1.15rem"}}>{LocalizedStrings.UpgradeToProTeam}</span> : ""}
           
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      

      <Table>
        <Table.Header><Table.Row><Table.HeaderCell>{LocalizedStrings.APITokens}</Table.HeaderCell></Table.Row></Table.Header>
        <Table.Body>{
          apiTokens.map((token, i) => <Table.Row key={i} style={{position: 'relative'}}><Table.Cell>{token}<Icon name='times' color='red' style={{position: 'absolute', right: '1em'}} onClick={() => this.onRemoveToken(token)}/></Table.Cell></Table.Row>)  
        }</Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <Button primary icon="add" onClick={this.openAddToken} content={LocalizedStrings.AddToken}></Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      {this.addUserModal(collaborators)}
      {this.addAdminModal()}
      {this.addContentAdminModal()}
      {this.addSharedLicenceUserModal()}
      {this.addTokenModal()}
      {this.tokenResultModal()}
      <Header>{LocalizedStrings.AccountType}</Header>
      <Divider/>
      <Form>
        <Form.Group>
          <Form.Select width={12} label={LocalizedStrings.UserType} options={accountTypeOptions} value={this.state.editedUserType !== undefined ? this.state.editedUserType : activeUser.type} onChange={this.userTypeChanege}></Form.Select>
          <Form.Field width={4}>
            <label style={{userSelect: 'none', color: 'rgba(0,0,0,0)'}}>{"Text"}</label>
            <Button positive disabled={this.state.editedUserType === undefined || this.state.editedUserType === activeUser.type} onClick={this.userTypeApply} fluid>{LocalizedStrings.Apply}</Button>
          </Form.Field>
        </Form.Group>
      </Form>
      {this.renderLDAPconf()}
    </>
  }

  //---------------------------------------
  // COUPONS

  renderCoupons = () => {
    
    let renderCouponCreation = () => {
      return (
        <Form.Group>
          <Form.Input
            onChange={this.promoCodeRecipientChanged}
            value={this.state.promoCodeRecipient}
            label={LocalizedStrings.CreateCoupon}
            placeholder={"Username"}
          />
          <Form.Field>
            <label style={{ userSelect: "none", color: "rgba(0,0,0,0)" }}>
              {"This is a hacky way to align button and input"}
            </label>
            <Form.Button positive onClick={this.createCoupon}>
              {LocalizedStrings.Create}
            </Form.Button>
          </Form.Field>
          <Form.Input
            value={this.state.createdPromoCode}
            readOnly
            label={LocalizedStrings.CreatedCoupon}
            placeholder={"..."}
          />
        </Form.Group>
      )
    }

    let renderCouponApply = () => {
      return (
        <Segment>
          <Form.Group>
            <Form.Input
              onChange={this.promoCodeChanged}
              value={this.state.promoCode}
              label={LocalizedStrings.ApplyCoupon}
              placeholder={"Code"}
            />
            <Form.Field>
              <label style={{ userSelect: "none", color: "rgba(0,0,0,0)" }}>
                {"This is a hacky way to align button and input"}
              </label>
              <Form.Button positive onClick={() => {this.checkCoupon()}}>
                {LocalizedStrings.Check}
              </Form.Button>
            </Form.Field>
            {this.renderCouponMessage()}
          </Form.Group>

          <Button positive onClick={() => {this.applyCoupon()}}>
              {LocalizedStrings.Redeem}
          </Button>
        </Segment>
      )
    }

    let renderCouponStructuralService = () => {
      return (
        <Segment>
            <Form.Input
              onChange={(e, {value})=>this.setState({coupon_structural_string:value})}
              value={this.state.coupon_structural_string}
              label={LocalizedStrings.StructuralVoucherCode}
              placeholder={"ABC"}
            />
            <Form.Input
              onChange={(e, {value})=>this.setState({coupon_structural_percent:value})}
              value={this.state.coupon_structural_percent}
              label={LocalizedStrings.PercentOff}
              placeholder={"50%"}
            />

          <Button positive onClick={async () => {
            
            let res = await lrServerConnection.createStructuralVoucher({
              Voucher: this.state.coupon_structural_string,
              Type: 0,/* SigningJobVoucherSchemaType::Percent */
              Value: this.state.coupon_structural_percent / 100,
            })

            if(res?._id)
            {
              window.alert(`Created Voucher`)
            }
            else
            {
              window.alert(`Failed Voucher`)
            }

          }}>
              {LocalizedStrings.Generate}
          </Button>
        </Segment>
      )
    }

    return (
      <Form>
        {this.props.activeUser.data && this.props.activeUser.data.isDSMember ? renderCouponStructuralService() : null}
        {this.props.activeUser.data && this.props.activeUser.data.isDSMember ? renderCouponCreation() : null}
        {renderCouponApply()}
      </Form>
    );
  }

  promoCodeRecipientChanged = (e, {value}) => {
    this.setState({ promoCodeRecipient: value })
  }

  promoCodeChanged = (e, {value}) => {
    this.setState({ promoCode: value, evaluatedCode: undefined  })
  }

  createCoupon = async() => {
    lrServerConnection.createPromoCodeForUser(this.state.promoCodeRecipient).then((res) => {
      this.setState({createdPromoCode: res.promotionCode ? res.promotionCode.code : LocalizedStrings.CouponNotCreated})
    })
  }

  applyCoupon = async() => {
    lrServerConnection.setDiscountCallback(() => {
      // refetch user data when wbhook has finished
      FetchActiveUser(true)

      lrServerConnection.checkCurrentDiscount().then(res => {
        this.setState({couponNote: this.createCouponNote(res)})
      })
    })
    lrServerConnection.applyPromoCodeForUser(this.state.promoCode)  
  }

  createCouponNote = (discount) => {
    const percent = discount.percentOff
    const amount = discount.amountOff
    const duration = discount.durationInMonths

    const offStr = (percent ?? amount) + (percent ? "%" : "€")
    let productNames = ""
    if (discount.forProducts) {
      for (const p of discount.forProducts) {
        productNames += GetProductNames()[p] + " "
      }
    }
    return LocalizedStrings.CouponNoteTemplate.replace("$1", offStr).replace("$2", productNames).replace("$3", duration)
  }

  checkCoupon = () => {
    lrServerConnection.checkPromoCodeForUser(this.state.promoCode).then(evaluatedCode => {
      if (evaluatedCode.amountOff)
        evaluatedCode.amountOff /= 100 // cents to euro
      this.setState({evaluatedCode})
    })
  }

  renderCouponMessage = () => { 
    let message = null

    if (this.state.evaluatedCode){
       if (!this.state.evaluatedCode.isValid)
          message = <Message>{LocalizedStrings.InvalidMessage}</Message>
        else {
          message = <Message>{this.createCouponNote(this.state.evaluatedCode)}</Message>
        }
    }
    return message
  }
  //---------------------------------------

  renderLDAPconf = () =>
  {

    
    const uris     = (this.state.ldapUris                   ?? (this.props.activeAdmin.data?.ldapUris   ?? this.props.activeUser.data?.ldapUris))   ?? []
    const domVal   = this.state.ldapDomain                  ?? (this.props.activeAdmin.data?.ldapDomain ?? this.props.activeUser.data?.ldapDomain)  ?? ""
    const username = this.props.activeAdmin.data?.username  ?? this.props.activeUser.data?.username

    // checks if arrays are exactly equal
    let equal = (a1, a2) => {
      if (!a1 || !a2 || a1.length !== a2.length) return false;
      for (let i = 0; i < a1.length; ++i) {
        if (a1[i] !== a2[i]) return false;
      }
      return true
    }

    // check if any ldap data has changed 
    let urisChanged = this.state.ldapUris && !equal(this.props.activeAdmin.data.ldapUris, this.state.ldapUris)
    let domainChanged = this.state.ldapDomain && (this.props.activeAdmin.data.ldapDomain !== this.state.ldapDomain)
    let dataChanged = urisChanged || domainChanged;
    return (
      <>
        <Header>{LocalizedStrings.ConfigureLDAP}</Header>
        <Divider />
        <Segment>
          <Table basic="very" padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={2}>
                  <Header as="h5">{LocalizedStrings.LDAPURIS}</Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {uris &&
                uris.map((uriVal, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Input
                          fluid
                          placeholder={"ldap://127.0.0.1:389"}
                          value={uriVal}
                          onChange={(e, { value }) => {
                            let urisCpy = [...uris];
                            urisCpy[index] = value; // change element
                            this.setState({ ldapUris: urisCpy });
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell width={1}>
                        <Icon
                          name="times"
                          color="red"
                          onClick={() => {
                            uris.splice(index, 1); // remove element
                            lrServerConnection.setLdapConfig(
                              uris,
                              domVal
                            ).then(()=> {
                              FetchActiveUser(true)
                              FetchActiveAdmin(true)
                            });
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan={2}>
                  <Button
                    onClick={() => {
                      this.setState({ ldapUris: [...uris, ""] }); // add element
                    }}
                    positive
                  >
                    Add uri
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Divider />
          <Input
            label={username + "@"}
            placeholder={"Domain"}
            value={domVal}
            onChange={(e, { value }) => {
              this.setState({ ldapDomain: value });
            }}
            size={"tiny"}
          />
          <Button
            disabled={!dataChanged}
            floated="right"
            positive
            onClick={() => {
              lrServerConnection.setLdapConfig(
                this.state.ldapUris,
                this.state.ldapDomain
              ).then(()=> {
                FetchActiveUser(true)
                FetchActiveAdmin(true)
                this.setState({ldapUris: undefined, ldapDomain: undefined})
              });
            }}
          >
            {LocalizedStrings.SaveConfiguration}
          </Button>
        </Segment>
      </>
    );
  }

  onRemoveToken = (token) => {
    lrServerConnection.deleteApiToken(token)
    .then(() => FetchApiTokens(true))
  }

  openAddToken = () => this.setState({
    addTokenOpen: true,
    newTokenName: "new API Token"
  })

  addTokenModal = () => 
    <LRModal 
      open={this.state.addTokenOpen}
      size="mini"
      title="Add API Token"
      onCancelClick={this.onTokenCancel}
      onOkClick={this.onAddToken}>
      <Input value={this.state.newTokenName} onChange={this.changeTokenName}/>
    </LRModal>

  tokenResultModal = () =>
    <LRModal
      open={this.state.tokenResult !== null}
      size="mini"
      title="API Token"
      noCancel
      onOkClick={() => this.setState({tokenResult: null})}
      >
      <p style={{wordBreak: "break-all"}}>{this.state.tokenResult}</p>
    </LRModal>

  changeTokenName = (_, {value}) => this.setState({
    newTokenName: value
  })

  onTokenCancel = () => this.setState({addTokenOpen: false})

  onAddToken = () => {
    lrServerConnection.createApiToken(this.state.newTokenName).then(token => {
      if(typeof token !== "string") {
        return this.setState({tokenResult: "create Token failed"})
      }
      this.setState({tokenResult: token})
      FetchApiTokens(true)
    })
    this.setState({addTokenOpen: false})
  }

  renderSeats = (sharedLicenceUsers, currentProTeamSeats) => {
     let username =this.props.activeAdmin.data?.username ??  this.props.activeUser.data?.username 
    let link = `/${username}/Plans/` + GetProductId().PRO_SEAT

    return (
      <div style={{display: "flex", flexDirection: "row"}}>
        <Button as={Link} to={link} positive style={{marginRight: 10}}>
          {LocalizedStrings.ChangeSeats}
        </Button>
        <Progress style={{flex: "1", marginBottom: 0}} size='large' progress='ratio' value={sharedLicenceUsers} total={currentProTeamSeats}/>
      </div>
    );
  }
  
  userTypeChanege = (_, {value}) => this.setState({editedUserType: value})

  userTypeApply = () => {
    if(this.state.editedUserType !== undefined) {
      lrServerConnection.setUserType(this.state.editedUserType)
      .then(() => {
        FetchActiveUser(true);
        FetchActiveAdmin(true);
        this.setState({editedUserType: undefined})
      })
    }
  }

  addAdminModal = () => {
    let collaborators = hasFetched(this.props.collaborators) ? this.props.collaborators.data : []
    let admins = hasFetched(this.props.admins) ? this.props.admins.data : []

    let nonAdmins = collaborators.filter(collab => !admins.find(adm => adm.username === collab.username))
    return <LRModal title="Add Admin" 
                    open={this.state.addAdminOpen} 
                    onCancelClick={this.closeAddAdmin}
                    onOkClick={this.closeAddAdmin}
                    size={"mini"}
                    noCancel>
        <List celled>
          {nonAdmins.map((nonAdm, i) => <List.Item key={i} style={{lineHeight:"2.5rem"}}>
            {nonAdm.name}
            <Button floated="right"onClick={this.onAdminClicked(nonAdm._id)}>{LocalizedStrings.Add}</Button>
          </List.Item>)}
        </List>
    </LRModal>
  }

  addContentAdminModal = () => {
    let collaborators = hasFetched(this.props.collaborators) ? this.props.collaborators.data : []
    let contentAdmins = hasFetched(this.props.contentAdmins) ? this.props.contentAdmins.data : []

    let nonContentAdmins = collaborators.filter(collab => !contentAdmins.find(ContentAdm => ContentAdm.username === collab.username))
    return <LRModal title="Add Content Admin" 
                    open={this.state.addContentAdminOpen} 
                    onCancelClick={this.closeAddContentAdmin}
                    onOkClick={this.closeAddContentAdmin}
                    size={"mini"}
                    noCancel>
        <List celled>
          {nonContentAdmins.map((nonContentAdm, i) => <List.Item key={i} style={{lineHeight:"2.5rem"}}>
            {nonContentAdm.name}
            <Button floated="right"onClick={this.onContentAdminClicked(nonContentAdm._id)}>{LocalizedStrings.Add}</Button>
          </List.Item>)}
        </List>
    </LRModal>
  }

  openAddAdmin = () => this.setState({addAdminOpen: true})
  closeAddAdmin = () => this.setState({addAdminOpen: false})

  openAddContentAdmin = () => this.setState({addContentAdminOpen: true})
  closeAddContentAdmin = () => this.setState({addContentAdminOpen: false})

  addSharedLicenceUserModal = () => {
    let collaborators      = hasFetched(this.props.collaborators) ? this.props.collaborators.data : []
    let sharedLicenceUsers = hasFetched(this.props.activeUser) ? this.props.activeUser.data.sharedLicences : []

    let nonSharedLicenceUsers = collaborators.filter(collab => !sharedLicenceUsers.find(user => user.username === collab.username))
    return <LRModal title="Share ProTeam Licence to users" 
                    open={this.state.shareLicenceOpen} 
                    onCancelClick={this.closeSharedLicence}
                    onOkClick={this.closeSharedLicence}
                    size={"mini"}
                    noCancel>
        <List celled>
        {nonSharedLicenceUsers.map((user) => <List.Item key={user._id} onClick={this.onSharedLicenceUserClicked(user.username)}>
            {user.name}
            <Button floated="right" onClick={this.onSharedLicenceUserClicked(user.username)}>{LocalizedStrings.Add}</Button>
          </List.Item>)}
        </List>
    </LRModal>
  }

  openSharedLicence = () => this.setState({shareLicenceOpen: true})
  closeSharedLicence = () => this.setState({shareLicenceOpen:false}) 

  openAddUser = () => {
    this.setState({
      open: true
    })
  }

  onInputChange = (_, {value}) => {
    this.setState({
      search: value,
      searchExecuted: false
    })    
  }

  onAddGroup = () => {
    this.setState({
      edited: [...this.state.edited,{name: "New Group", members: []}],
      changed: true,
    })
  }

  onApplyGroups = () => {
    lrServerConnection.setGroups(this.state.edited).then(() => {this.setState({changed: false}); FetchGroups(true)})
  }

  onToggle = (i, id, checked) => () => {
    let newGroups = [...this.state.edited]
    if(checked) {
      newGroups[i] = {
        ...newGroups[i],
        members: [...newGroups[i].members, id]
      }
    } else {
      newGroups[i] = {
        ...newGroups[i],
        members: newGroups[i].members.filter(mId => mId !== id)
      }
    }
    this.setState({
      edited: newGroups,
      changed: true

    })
  }
  
  onGroupNameChange = (i) => (_, {value}) => {
    let newGroups = [...this.state.edited]
    newGroups[i] = {
      ...newGroups[i],
      name: value
    }
    this.setState({edited: newGroups, changed: true})
  }

  deleteGroup = (i) => () => {
    this.setState({changed: true,edited: this.state.edited.filter((_, j) => i !== j)})
  }

  addUserModal = (collaborators) => {
    let {open, search, usersFromSearch} = this.state

    // remove users that are already collaborators

    usersFromSearch = usersFromSearch.length ? usersFromSearch : []

    const filteredUsersFromSearch = usersFromSearch.filter(
      (u) => !collaborators.some((c) => c.username === u.username)
    );

    // control pagination
    const usersPerPage = 10
    const firstUserToShow = (this.state.activeUsersPage - 1) * 10
    const lastUserToShow = firstUserToShow + usersPerPage
    const showUsers = this.state.searchExecuted ? filteredUsersFromSearch.slice(firstUserToShow, lastUserToShow) : [] // define users to show
    const totalPages = Math.ceil(filteredUsersFromSearch.length / usersPerPage)

    // number of characters before search is available 
    const minChars = 3
    const searchPossible = search.length >= minChars
    
    // placeholder conditions
    const noUsersFound = showUsers.length === 0 && this.state.searchExecuted
    const noSearchPossible = search.length < minChars
    const waitingForSearch = search.length >= minChars && !this.state.searchExecuted && showUsers.length === 0

    return (
      <LRModal
        open={open}
        size="mini"
        title={LocalizedStrings.AddCollabTitle}
        noCancel
        onCancelClick={this.closeAddCollab}
        onOkClick={this.closeAddCollab}
      >
        <Form onKeyDown = {(e)=> {if(e.key==="Enter"){ e.stopPropagation(); e.preventDefault(); this.executeUserSearch()}}}>
          <Form.Group>
            <Form.Input
              fluid
              width={10}
              value={search}
              onChange={this.onInputChange}
              onBlur={this.executeUserSearch}
            />
            <Form.Button
              width={6}
              positive
              fluid
              disabled={!searchPossible || this.state.searchExecuted}
              onClick={this.executeUserSearch}
            >
              {LocalizedStrings.Search}
            </Form.Button>
          </Form.Group>
        </Form>
        <List celled style={{ maxHeight: "300px", overflowY: "auto" }}>
          {noSearchPossible && this.renderPlaceHolder(LocalizedStrings.SearchPlaceholder)}
          {waitingForSearch && this.renderPlaceHolder(LocalizedStrings.WaitingForSearch)}
          {noUsersFound && this.renderPlaceHolder(LocalizedStrings.NoUsersFound)}
          {search.length >= minChars &&
            <>
              {showUsers.map((user, i) => (
                <List.Item
                  key={user._id}
                >
                  <List.Content>
                    <UserAvatar user={user.username}/>
                    {user.name}
                    <Button floated="right" onClick={this.onUserClicked(user.username)}>{LocalizedStrings.Add}</Button>
                  </List.Content>
                </List.Item>
              ))}
              {totalPages >= 2 && <Pagination
                onPageChange={(_, { activePage }) => {
                  this.setState({ activeUsersPage: activePage });
                }}
                activePage={this.state.activeUsersPage}
                totalPages={totalPages}
              />}
            </>
          }
        </List>
      </LRModal>
    );
  }

  renderPlaceHolder = (text) => {
    return <Segment fluid placeholder>
              <Header icon>
                {text}
              </Header>
            </Segment>
  }

  executeUserSearch = async() => {
    const usersFromSearch = await lrServerConnection.findUserSubset(this.state.search.toLowerCase())
    this.setState({usersFromSearch, searchExecuted: true})
  }

  closeAddCollab = () => this.setState({open: false, activeUsersPage: 1, searchExecuted: false})

  onAdminClicked = (adminId) => () => {
    lrServerConnection.setAdmins([...this.props.admins.data, adminId])
    .then(() => {
      FetchAdmins(true)
    })
    this.closeAddAdmin()
  }
  
  onSharedLicenceUserClicked = (username) => () => {
    lrServerConnection.setSharedLicenceUsers(username)
    .then(() => {
      FetchSharedLicenceUsers(true)
    })
    this.closeSharedLicence()
  }

  onRemoveAdmin = index => () => {
    lrServerConnection.setAdmins(this.props.admins.data.filter((_, i) => i !== index))
    .then(() => {
      FetchAdmins(true)
    })
  }

  onContentAdminClicked = (contentAdminId) => () => {
    lrServerConnection.setContentAdmins([...this.props.contentAdmins.data, contentAdminId])
    .then(() => {
      FetchContentAdmins(true)
    })
    this.closeAddContentAdmin()
  }

  onRemoveContentAdmin = index => () => {
    lrServerConnection.setContentAdmins(this.props.contentAdmins.data.filter((_, i) => i !== index))
    .then(() => {
      FetchContentAdmins(true)
    })
  }

  onRemoveSharedLicenceUser = (user) => () => {
    lrServerConnection.removeSharedLicenceUser(user)
    .then(() => {
      FetchSharedLicenceUsers(true)
    })
  }

  onUserClicked = (user) => () => {
    globalCallbacks.addCollaborator(user)
    .then(() => {
      FetchCollaborators(true)
    })
    this.setState({
      open: false,
    })
  }

  onRemoveClicked = (user) => () => {
    globalCallbacks.removeCollaborator(user)
    .then(() => {
      FetchCollaborators(true)
    })
  }
}

const mapStateToProps = (state) => ({
  collaborators : state[COLLABORATORS],
  activeUser    : state[ACTIVE_USER],
  groups        : state[GROUPS],
  admins        : state[ADMINS],
  apiTokens     : state[API_TOKENS],
  activeAdmin   : state[ACTIVE_ADMIN],
  sharedLicence_users: state[SHAREDLICENCE],
  contentAdmins : state[CONTENT_ADMINS]

})

export default connect(mapStateToProps)(Account)