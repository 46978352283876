import React, { useEffect, useState } from "react";
import { Checkbox, DropdownItemProps, Form, Grid } from "semantic-ui-react";
import LocalizedStrings from "../../localization/TableViewComponent";
import { globalCallbacks } from "../../util/callback";
import { globalWindowInterface, uuidStr } from "../../util/callbackTypes";
import { AvailablePDFPaperFormats, BASE_UNIT, EMPTY_UUID, WORKSHEET_VIEW_TYPE } from "../../util/defines";
import LRModal from "../Basics/BasicModal";
import UnitInput from '../Basics/BasicUnitInput';
import { IPdfFormat } from "../GlobalSettings/PrintSettings";
import { SingleNotification } from "../NotificationDisplay/NotificationDisplay";
import CustomPDFFormatPicker from "./CustomPDFFormatPicker";

declare const window: globalWindowInterface

const PREVIEW_SIZE_WIDTH = 1700;
const PREVIEW_SIZE_HEIGHT = 600;
const PREVIEW_SIZE_ZOOM = 3.5;
export interface IMarginInterface {
  MarginTop: number,
  MarginLeft: number,
  MarginRight: number,
  MarginBottom: number,
  Height: number,
  Width: number,
}

export default function (this: unknown, props: React.PropsWithChildren<
  {
    open: boolean,
    propertyOptions: DropdownItemProps[]
    onClose: () => void,
    worksheetScale: number,
    onWorksheetScaleChange: (val: number) => void,
    getCurrentWorksheetState: () => any,
    worksheet: uuidStr,
    currentView: WORKSHEET_VIEW_TYPE,
    onChangePdfFormat: (format: IPdfFormat) => void,
    onChangeMargins: (margins: IMarginInterface) => void,
    onChangeUsePageBreak: (usePageBreak: boolean) => void,
    selectedPaperFormat,
    setSelectedPaperFormat
  }
>) {

  const selectedPaperFormat = AvailablePDFPaperFormats[props.selectedPaperFormat].value

  const [usePrintPreview, setUsePrintPreview] = useState(false)
  const [usePrintHeaderSection, setUsePrintHeaderSection] = useState(true)
  const [usePageBreak, setUsePageBreak] = useState(false)
  const [selectedPrintLabel, setSelectedPrintLabel] = useState(undefined)
  const [printLabels, setPrintLabels] = useState([])
  const [shareLinks, setShareLinks] = useState<DropdownItemProps[]>([])
  const [chosenShareLink, setChosenShareLink] = useState<string>(undefined)
  const [margins, setMargins] = useState<IMarginInterface>({
    MarginTop: 0,
    MarginLeft: 0,
    MarginRight: 0,
    MarginBottom: 0,
    Height: 0,
    Width: 0
  })
  const [PDFFormats, setPDFFormats] = useState<IPdfFormat>(undefined)
  const [usePaperSettingsFromGlobal, setUsePaperSettingsFromGlobal] = useState(true)

  useEffect(() => {
    if (props.open) {
      props.onChangeMargins(margins)
      props.onChangePdfFormat(PDFFormats)
      props.onChangeUsePageBreak(usePageBreak)
    }
  }, [props.open, margins, PDFFormats, usePageBreak])

  const updateFromProjectSettings = () => {
    window.LR_GetProjectSettings().then((res) => {
      let i = res?.DrawingSettings
      if (i) {
        setMargins({
          MarginTop: i.MarginTop,
          MarginBottom: i.MarginBottom,
          MarginLeft: i.MarginLeft,
          MarginRight: i.MarginRight,
          Height: i.Height,
          Width: i.Width,
        })
      }
      setPDFFormats(res?.PdfFormat)
    })
  }
  useEffect(() => {
    globalCallbacks.TableViewGetPrintLabels = async () => {
      setPrintLabels((await window.LR_GetPrintLabels()).PrintLabels)
    }
    globalCallbacks.TableViewGetPrintLabels()

    window.LR_GetShareLinksForProject().then(shareLinks => {
      let options = []

      if (Array.isArray(shareLinks)) {
        options = shareLinks.map((e) => {

          return {
            key: e.token,
            text: e.name,
            value: e.token
          }
        })
      }

      options.push(
        {
          text: "<None>",
          value: "",
          key: "<None>"
        }
      )
      setShareLinks(options)
    })

    updateFromProjectSettings()

  }, [])

  let addShareLink = async (name: string) => {
    let ret = await window.LR_CreateShareLinkForProject({ Name: name })
    if (typeof ret !== "string") {
      SingleNotification.newNotification((LocalizedStrings as any).ErrorLinkCreationHeader, (LocalizedStrings as any).ErrorLinkCreationText, "error")
      return;
    }
    setShareLinks(last => [...last, { text: name, value: ret, key: ret }])
    setChosenShareLink(ret)
  }

  let exportToPdf = () => {
    props.onClose()

    let currentWorksheetState = props.getCurrentWorksheetState()

    let s = new XMLSerializer();
    let source = document.getElementById("table-data-entry");
    if (source) {
      let r = s.serializeToString(source);
      let out: any = {
        HTML: r,
        Type: "PDF",
        ActivePreset: currentWorksheetState.ActivePropertyPreset,
        PrintLabelBackground: selectedPrintLabel,
        ShareLink: chosenShareLink,
        Worksheet: props.worksheet,
        currentView: props.currentView,
        Format: props.selectedPaperFormat,
        PrintScale: props.worksheetScale,
        UsePageBreak: usePageBreak,
        AddHeaderSection: usePrintHeaderSection,
        ImageShort: undefined,
        ImageHeight: undefined,
        ImageWidth: undefined
      }

      if (!usePaperSettingsFromGlobal) {
        out = {
          ...out,
          ...margins,
          PDFFormats
        }
      }

      if (usePrintPreview && globalCallbacks.getCurrentRendererCanvas) {
        let tmp_canvas = globalCallbacks.getCurrentRendererCanvas(PREVIEW_SIZE_WIDTH, PREVIEW_SIZE_HEIGHT, PREVIEW_SIZE_ZOOM)
        out = {
          ...out,
          ImageShort: tmp_canvas.url,
          ImageHeight: tmp_canvas.height,
          ImageWidth: tmp_canvas.width
        }
      }
      window.LR_PrintTable(out)
    }
  }

  return (
    <>
      <LRModal
        open={props.open}
        title={LocalizedStrings.ExportWorksheetAsPdf}
        size="fullscreen"
        scrolling={false}
        onOkClick={exportToPdf}
        onCancelClick={props.onClose}

      >
        <Grid>
          <Grid.Row>
            <Grid.Column width="13" style={{ backgroundColor: "grey" }} >
              <div id="table-data-entry" style={{ position: "absolute", maxHeight: "100%", overflow: "auto" }}>
                {props.children}
              </div>
            </Grid.Column>
            <Grid.Column width="3">
              <Form>
                <Form.Select label={LocalizedStrings.PaperMargin} value={selectedPaperFormat} fluid compact options={AvailablePDFPaperFormats} onChange={(e, v) => {
                  props.setSelectedPaperFormat(AvailablePDFPaperFormats.findIndex(i => i.value === v.value))
                }} />
                <Form.Select
                  label={LocalizedStrings.PrintLabel}
                  options={[
                    { key: EMPTY_UUID, value: EMPTY_UUID, text: LocalizedStrings.NoBackground },
                    ...printLabels.map((lb) => ({
                      key: lb.UUID,
                      value: lb.UUID,
                      text: lb.Name,
                    })),
                  ]}
                  value={selectedPrintLabel}
                  onChange={(_, { value }) => {
                    setSelectedPrintLabel(value);
                  }}
                />
                <CustomPDFFormatPicker
                  PDFFormat={PDFFormats}
                  margins={margins}
                  usePaperSettingsFromGlobal={usePaperSettingsFromGlobal}
                  onChangeMarginSettings={m => setMargins(m)}
                  onChangePdfFormat={i => setPDFFormats(i)}
                  onChangeUsePaperSettingsFromGlobal={b => setUsePaperSettingsFromGlobal(b)}
                />
                <UnitInput
                  unitStr={""}
                  baseUnit={BASE_UNIT.ZERO_ONE}
                  label={LocalizedStrings.WorksheetScale}
                  name={"WorksheetScale"}
                  value={props.worksheetScale}
                  onStateUpdate={(name, value: number) => props.onWorksheetScaleChange(value)}
                />
                <Form.Select onAddItem={(e, { value }) => addShareLink(value as string)} search allowAdditions={true} label={LocalizedStrings.PublicShareLink} value={chosenShareLink} fluid compact options={shareLinks} onChange={(_, { value }) => setChosenShareLink(value as string)} />
                <Checkbox toggle label={LocalizedStrings.PrintQRCodeAndHeader} data-tooltip={LocalizedStrings.PrintQRCodeAndHeaderDescription} name={"PrintPreview"} checked={usePrintHeaderSection} onChange={(name, { checked }) => setUsePrintHeaderSection(checked)} />
                <Checkbox style={{ marginTop: "10px" }} toggle label={LocalizedStrings.PrintPreviewOfCanvas} data-tooltip={LocalizedStrings.PrintPreviewOfCanvasDescription} name={"PrintPreview"} checked={usePrintPreview} onChange={(name, { checked }) => setUsePrintPreview(checked)} />
                <Checkbox style={{ marginTop: "10px" }} toggle label={LocalizedStrings.NewPageForEveryProp} data-tooltip={LocalizedStrings.NewPageForEveryPropDescription} name={"UsePageBreak"} checked={usePageBreak} onChange={(name, { checked }) => setUsePageBreak(checked)} />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </LRModal>
    </>
  )
}