import React, { useState } from 'react';
import { Accordion, Button, Divider, Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import LocalizedStrings from "../../localization/GlobalSettingsModal";
import { BASE_UNIT_NUMBER, BASE_UNIT_PAGE_LENGTH } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';
import ColorInputField from '../ColorPicker/ColorInputField';

const headerStyle = {
    cursor: 'pointer',
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};

interface IPdfFormatDef {
    FontSize: number
    Bold: boolean
    Italic: boolean
    Underlined: boolean
    SpaceBefore: number
    SpaceAfter: number
    Color: { X: number, Y: number, Z: number }
}

export interface IPdfFormat {
    H1: IPdfFormatDef
    H2: IPdfFormatDef
    H3: IPdfFormatDef
    H4: IPdfFormatDef
    Text: IPdfFormatDef
    CellHeader: IPdfFormatDef
    FooterText: IPdfFormatDef
    Toc1: IPdfFormatDef
    Toc2: IPdfFormatDef
    Toc3: IPdfFormatDef
    Toc4: IPdfFormatDef
}

function OneEntry(this: unknown, {
    text,
    data,
    onFormatChange
}: {
    text: string
    data: IPdfFormatDef,
    onFormatChange: (name: string, val: any) => void
}) {
    let {
        Bold: bold,
        Italic: italic,
        Underlined: underlined
    } = data
    return (
        <>
            <Divider />
            <h3>{LocalizedStrings[text]}</h3>
            <Grid columns='two' divided>
                <Grid.Row>
                    <Grid.Column>
                        <UnitInput label={LocalizedStrings.FontSize} baseUnit={BASE_UNIT_NUMBER}
                            onStateUpdate={(name, val) => onFormatChange("FontSize", val)}
                            value={data.FontSize}
                            customLabel={
                                <Button.Group compact>
                                    <Button onClick={() => onFormatChange("Underlined", !underlined)} color={underlined ? "grey" : undefined} type="button"> <Icon name="underline" color={underlined ? undefined : "black"} /> </Button>
                                    <Button onClick={() => onFormatChange("Bold", !bold)} color={bold ? "grey" : undefined} type="button"> <Icon name="bold" color={bold ? undefined : "black"} /> </Button>
                                    <Button onClick={() => onFormatChange("Italic", !italic)} color={italic ? "grey" : undefined} type="button"> <Icon name="italic" color={italic ? undefined : "black"} /> </Button>
                                </Button.Group>

                            }

                        />
                        <ColorInputField
                            label={LocalizedStrings.TextColor}
                            labelPosition='right'
                            colorX={data.Color.X}
                            colorY={data.Color.Y}
                            colorL={data.Color.Z}
                            onColorChanged={cie => onFormatChange("Color", { X: cie.fx, Y: cie.fy, Z: cie.f_Y })}
                        />

                    </Grid.Column>
                    <Grid.Column>
                        <UnitInput label={LocalizedStrings.SpaceBefore}
                            onStateUpdate={(name, val) => onFormatChange("SpaceBefore", val)}
                            baseUnit={BASE_UNIT_PAGE_LENGTH} value={data.SpaceBefore} />
                        <UnitInput label={LocalizedStrings.SpaceAfter}
                            onStateUpdate={(name, val) => onFormatChange("SpaceAfter", val)}
                            baseUnit={BASE_UNIT_PAGE_LENGTH} value={data.SpaceAfter} />
                    </Grid.Column>
                    <Grid.Column>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default function PrintSettings(
    this: unknown,
    {
        format,
        marginSettings,
        onFormatChange,
        onMarginChange
    }: {
        format: IPdfFormat,
        marginSettings?: {
            MarginTop: number,
            MarginBottom: number,
            MarginLeft: number,
            MarginRight: number,
            Height: number,
            Width: number
        }
        onFormatChange: (format: string, name: string, val: any) => void
        onMarginChange: (name: string, val: any) => void
    }) {
    let [activeIndex, setActiveIndex] = useState(0)
    if (!marginSettings) {
        return (
            <Segment placeholder>
                <Header icon>{LocalizedStrings.NoProjectFound}</Header>
            </Segment>
        );
    }
    else {
        return (
            <>
                <Accordion fluid styled>
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={() => setActiveIndex(0)}
                        style={headerStyle}
                    >
                        <Icon name='dropdown' />
                        <Header style={{ margin: 0 }} as="h2">{LocalizedStrings.MarginSettings}</Header>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <Form>
                            {
                                ["MarginLeft", "MarginRight", "MarginTop", "MarginBottom", "Height", "Width"].map(i => <UnitInput
                                    label={LocalizedStrings[i]}
                                    name={i}
                                    key={i}
                                    value={marginSettings[i]}
                                    baseUnit={BASE_UNIT_PAGE_LENGTH}
                                    onStateUpdate={(name, value) => onMarginChange(name, value)}
                                />)
                            }
                        </Form>
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={() => setActiveIndex(1)}
                        style={headerStyle}
                    >
                        <Icon name='dropdown' />
                        <Header style={{ margin: 0 }} as="h2">{LocalizedStrings.FormatDefinition}</Header>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        <Form>
                            {
                                ["H1", "H2", "H3", "H4", "Text", "CellHeader", "Toc1", "Toc2", "Toc3", "Toc4"].map(i =>
                                    <OneEntry
                                        data={format[i]}
                                        text={i}
                                        key={i}
                                        onFormatChange={(n, v) => onFormatChange(i, n, v)}
                                    />)
                            }
                        </Form>
                    </Accordion.Content>
                </Accordion>
            </>
        )
    }
}