//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import LocalizedStrings from "../../localization/RequestStructuralCheck";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from "../../util/callback";
import { Grid, Message, Table, Button, Icon, Form, Segment, Header } from 'semantic-ui-react';
import { BASE_UNIT_DATE, BASE_UNIT_STRING } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput'


//-----------------------------------------------------------------------------
// The component for the TreeControl
class RequestStructuralCheck extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      IsInOnlineProject: false,
      Mail : "",
      Phone: "",
      Voucher: "",
      Pricing: {
        Price: 3023.334,
        Currency: "Euro",
        TimeNeeded: Date.now(),
        Username: "",
        ActiveId: "",
        Items: [],
        VoucherOK: true,
        VoucherAmount: 0.0
      }
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    let res = await window.LR_GetCalculationPricing({Voucher: this.state.Voucher})

    if(res.Voucher?.VoucherOK === false)
    {
      window.alert(LocalizedStrings.FailedVoucher + "\n\n" + res.Voucher.VoucherText)
    }

    this.setState( { 
        open : true, 
        IsInOnlineProject:  res?.Project !== "",
        Pricing: {
          VoucherOK: res.Voucher?.VoucherOK,
          VoucherAmount: res.Voucher?.VoucherAmount,
          Price: res.Price,
          Username: res.Username,
          Currency: res.Currency,
          TimeNeeded: res.TimeNeeded,
          Items: res.Items
        },
      });
  }

  buyStructuralCalculation = async () => 
  {
    let res = await window.LR_RequestCalculationCheck({
      Phone: this.state.Phone,
      Mail: this.state.Mail,
      Voucher: this.state.Voucher,
      Price: this.state.Pricing.Price,
      Currency: this.state.Pricing.Currency,
    })

    if(res.ErrorMessage)
    {
      window.alert(res.ErrorMessage)
    }
    else if(res._id === undefined)
    {
      window.alert(LocalizedStrings.FailedToTableHeader + "\n\n" + LocalizedStrings.FailedToTableText)
      window.LR_CommitToServer()
    }
    else
    {
      window.alert(LocalizedStrings.TabledReportHeader + "\n\n" + LocalizedStrings.TabledReportText)
    }

    this.close()    
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                noCancel={true}
                scrolling={false}
                title={LocalizedStrings.Header}
                customOKText={LocalizedStrings.Cancel}
                onOkClick={this.close}>
                {this.state.IsInOnlineProject ??
                  <Message negative>
                    <Message.Content>
                      {LocalizedStrings.NoOnlineProjectHeader}
                      {LocalizedStrings.NoOnlineProjectText}
                    </Message.Content>
                  </Message>}
                <Grid divided='vertically'>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                    <Header>{LocalizedStrings.ShoppingCart}</Header>
                      <Table>
                      <Table.Header>
                      <Table.Row>
                      <Table.HeaderCell>{LocalizedStrings.Entry}</Table.HeaderCell>
                      <Table.HeaderCell>{LocalizedStrings.Amount}</Table.HeaderCell>
                      </Table.Row>
                      </Table.Header>
                      <Table.Body>
                      {Array.isArray(this.state.Pricing.Items)  ? this.state.Pricing.Items.map((e, i)=>
                        <Table.Row key={i}>
                        <Table.Cell>{e.Entry}</Table.Cell>
                        <Table.Cell>{this.renderValue(e.Value)}</Table.Cell>
                        </Table.Row>)
                      : null 
                      }

                      </Table.Body>
                      </Table>

                    </Grid.Column>
                    <Grid.Column>
                      <Button fluid primary onClick={()=>window.LR_OpenLink({UseBaseUrl: true, URL:`/${this.state.Pricing.Username}/signingjobs/${this.state.Pricing.ActiveId ? this.state.Pricing.ActiveId : ""}` })}>{LocalizedStrings.ViewActiveReports}</Button>                   
                      <Header>{LocalizedStrings.Summery}</Header>
                      <Segment placeholder>
                        <Header icon>
                          <Icon name="shopping cart" />
                          {this.getPricing()}
                          {this.getPricingVoucher()}
                        </Header>
                      </Segment>
                      <Segment>

                      <Button fluid onClick={this.show}>{LocalizedStrings.RedeemVoucher}</Button>
                      <Form>

                      <UnitInput  
                                  value={this.state.Voucher}
                                  onUpdateData  = {(name, value) => {this.setState({Voucher: value})}} 
                                  label={LocalizedStrings.Voucher}
                                  baseUnit={BASE_UNIT_STRING}/> 
                      </Form>
                      </Segment>
                      <Form>
                      
                      <UnitInput  
                                  value={this.state.Mail}
                                  onUpdateData  = {(name, value) => {this.setState({Mail: value})}} 
                                  label={LocalizedStrings.Mail}
                                  baseUnit={BASE_UNIT_STRING}/> 

                      <UnitInput  
                                  value={this.state.Phone}
                                  onUpdateData  = {(name, value) => {this.setState({Phone: value})}} 
                                  label={LocalizedStrings.Phone}
                                  baseUnit={BASE_UNIT_STRING}/> 
                      
                     </Form>
                      <UnitInput  readOnly
                                  value={this.state.Pricing.TimeNeeded}
                                  baseUnit={BASE_UNIT_DATE}/> 

                      <Button disabled={!this.state.IsInOnlineProject} fluid positive onClick={this.buyStructuralCalculation}><Icon name="shopping cart"/>{LocalizedStrings.BuyStructuralCalculation}</Button>                   
                    
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Message positive>
                        <Message.Content>
                          {LocalizedStrings.Text1}
                          {LocalizedStrings.Text2}
                        </Message.Content>
                      </Message>

          
      </LRModal>
    )
  }

  renderValue(value)
  {
    if(typeof value == "boolean")
    {
      return value ? <Icon  name="checkmark" /> : <Icon  name="cancel"/>
    }
    return value;
  }

  getPricing()
  {
    let cur = ""
    switch(this.state.Pricing.Currency)
    {
      case "usd": cur = "$"; break;
      case "eur": cur = "€"; break;
    }

    if(this.state.Pricing.VoucherAmount > 0.0)
    {
      return <s>{this.state.Pricing.Price / 100} {cur}</s>
    }
    
    return <>{this.state.Pricing.Price / 100} {cur}</>
  }

  getPricingVoucher()
  {
    if(this.state.Pricing.VoucherAmount === 0.0 ||
      this.state.Pricing.VoucherAmount === undefined)
    {
      return null
    }
    let cur = ""
    switch(this.state.Pricing.Currency)
    {
      case "usd": cur = "$"; break;
      case "eur": cur = "€"; break;
    }
    
    return <> {(this.state.Pricing.Price - this.state.Pricing.VoucherAmount) / 100} {cur}</>
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowRequestStructuralCheck = () => 
    { 
      this.show();
    }
    
  }
}

export default RequestStructuralCheck